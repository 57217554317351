export const LOAD_FORM_START: string = "LOAD_FORM_START";
export const LOAD_FORM_SUCCESS: string = "LOAD_FORM_SUCCESS";
export const LOAD_FORM_FAIL: string = "LOAD_FORM_FAIL";

export const SUBMIT_FORM_START: string = "SUBMIT_FORM_START";
export const SUBMIT_FORM_SUCCESS: string = "SUBMIT_FORM_SUCCESS";
export const SUBMIT_FORM_FAIL: string = "SUBMIT_FORM_FAIL";

export const SET_GLOBAL_STATE: string = "SET_GLOBAL_STATE";

export const GTAG_EVENT: string = "GTAG_EVENT";
