import { Action } from "redux";
import { actions as adminActions } from "../modules/Admin/redux";
import { actions as authActions } from "../modules/Auth/redux";
import { actions as homeActions } from "../modules/Home/redux";
import { actions as hubActions } from "../modules/Hub/redux";
import { actions as partnershipActions } from "../modules/Partnerships/redux";
import { actions as profileActions } from "../modules/Profile/redux";
import { actions as generalActions } from "../modules/reduxGlobal";

export interface IActions extends Action {
  type: any;
  payload: any;
}

export const createAction = (type: string, payload: any): IActions => {
  return { type, payload };
};

export default {
  ...authActions,
  ...partnershipActions,
  ...profileActions,
  ...adminActions,
  ...generalActions,
  ...homeActions,
  ...hubActions,
};
