import { IonButton, IonCheckbox, IonSpinner, IonText } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import React, { FC } from "react";
import { Controller, FormContextValues } from "react-hook-form";
import { LineDivider } from "../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";

const disclaimerInformation: IInformation[] = [
  {
    title: "Disclaimer",
    paragraphs: [
      "I understand that ourBrain is not a replacement for any kind of professional help.",
    ],
    bullets: [0],
    icon: checkmark,
  },
  {
    paragraphs: [
      "I understand that ourBrain cannot gurantee an improvement on my situation and that I use this platform at my own risk.",
    ],
    bullets: [0],
    icon: checkmark,
  },
  {
    paragraphs: [
      "I understand that ourBrain uses trained and selected moderators to review check-ins and keep the platform safe.",
    ],
    bullets: [0],
    icon: checkmark,
  },
];

const Disclaimer: FC<any> = ({
  profileLoading,
  form,
  styles,
  hidden,
  validationError,
}) => {
  const { errors } = form as FormContextValues;
  return (
    <div hidden={hidden} className={styles.disclaimerContainer}>
      {disclaimerInformation.map((information, i) => (
        <OurBrainInformation key={i} information={information} />
      ))}
      <LineDivider />
      <h5>
        Please take this opportunity to review our{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              "https://static.ourbrain.io/legal/documents/communityrules.pdf"
            );
          }}
        >
          Community rules{" "}
        </a>
        . You must do this in order to proceed.
      </h5>
      <LineDivider />
      <Controller
        name="acceptRules"
        control={form.control}
        defaultValue={false}
        rules={{ validate: (value: boolean) => value }}
        as={({ onChange, checked }: any) => (
          <div
            className={styles.disclaimerCheckbox}
            style={{
              border: checked
                ? "2px solid var(--ion-color-primary)"
                : "2px solid var(--ion-color-light)",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <h5>
              By checking this box, I confirm that I have read and understood
              the ourBrain disclaimer and community rules.
            </h5>
            <IonCheckbox slot="end" checked={checked} onIonChange={onChange} />
          </div>
        )}
      />
      <LineDivider />
      <IonButton
        type="submit"
        expand="block"
        disabled={profileLoading}
        color={
          errors.acceptRules || typeof validationError !== "undefined"
            ? "danger"
            : "primary"
        }
      >
        {profileLoading ? <IonSpinner /> : "Begin"}
      </IonButton>
      <ol>
        {typeof errors.acceptRules !== "undefined" && (
          <IonText color="danger">
            <small>
              <li>
                You must accept the ourBrain Disclaimer and Community rules.
              </li>
            </small>
          </IonText>
        )}
        {typeof errors.demographic !== "undefined" && (
          <IonText color="danger">
            <small>
              <li>{errors.demographic?.city?.message}</li>
            </small>
            <small>
              <li>{errors.demographic?.gender?.message}</li>
            </small>
            <small>
              <li>{errors.demographic?.dateOfBirth?.message}</li>
            </small>
          </IonText>
        )}
        {typeof errors.username !== "undefined" && (
          <IonText color="danger">
            <small>
              <li>{errors.username.message}</li>
            </small>
          </IonText>
        )}
        {typeof errors.avatarUrl !== "undefined" && (
          <IonText color="danger">
            <li>
              <small>{errors.avatarUrl.message}</small>
            </li>
          </IonText>
        )}
      </ol>
    </div>
  );
};

export default Disclaimer;
