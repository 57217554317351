import { IonPage } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainButton, OurBrainContent } from "../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import { IPreferenceModel } from "../../../../../interfaces/interfaces";
import partnershipActions, {
  IPartnershipActions,
} from "../../../redux/actions";
import { IPartnershipDefaultState } from "../../../redux/reducer";
import styles from "./index.module.scss";

const partnershipBeginInformation: IInformation[] = [
  {
    title: "Partnerships",
    subtitle: "Welcome to the beginning of your journey.",
  },
  {
    subtitle: "What is a partnership?",
    paragraphs: [
      "A partnership is the connection of two ourBrain users to discuss their mental health and open up, by using check-ins. Partnerships are created based on each user's unique requests.",
    ],
  },
  {
    paragraphs: [
      "Simply put, we want to make sure you always have the right person to speak with about your mental health.",
      "The goal of ourBrain is to connect you with someone who understands, someone who is experiencing the same troubles.",
      "By creating a partnership of mutual respect, we swap negative stigma and embarrassment for empathy and understanding.",
      "We will now ask you a set of questions to help find you the most suitable partner, so you can began to talk and open up.",
    ],
    bullets: [0, 1, 2, 3, 4],
    icon: chevronDown,
  },
];

type PartnershipBeginProps = {
  actions: IPartnershipActions;
  partnership: IPartnershipDefaultState;
};
const PartnershipInformation: FC<PartnershipBeginProps> = (props) => {
  const { actions, partnership } = props;
  const { preferences } = partnership;
  useEffect(() => {
    actions.setPartnershipState({
      beganPartnershipRequest: true,
      orQuestionSelected: "",
      showError: undefined,
    });
    actions.findAllPreferencesStart();
  }, [actions]);

  useEffect(() => {
    function loadPreferenceQuestions(preference: IPreferenceModel) {
      actions.findAllPreferenceQuestionsStart({
        questionIds: preference.questionIds,
      });
    }
    const preference: IPreferenceModel | undefined = preferences.find(
      ({ type }) => type === "experiences"
    );
    if (typeof preference === "undefined") {
      return;
    }
    loadPreferenceQuestions(preference);
  }, [actions, preferences]);

  return (
    <IonPage>
      <OurBrainContent className={styles.partnershipBeginContainer}>
        {partnershipBeginInformation.map((information, index) => (
          <div
            key={index}
            style={{ marginBottom: index === 0 ? "40px" : "inherit" }}
          >
            <OurBrainInformation information={information} />
          </div>
        ))}
        <OurBrainButton
          color="primary"
          fill="solid"
          expand="block"
          link={`/app/partnership/preference?type=experiences&preferenceId=${partnership.preferences[0]?._id}&slide=0`}
        >
          Begin
        </OurBrainButton>
        <OurBrainButton
          color="secondary"
          fill="outline"
          expand="block"
          link="/app/partnership"
          onClick={() =>
            actions.setPartnershipState({ beganPartnershipRequest: false })
          }
        >
          Later
        </OurBrainButton>
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions }, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipInformation);
