export const PROCESS_FIND_ALL_PREFERENCES_START: string =
  "PROCESS_FIND_ALL_PREFERENCES_START";
export const PROCESS_FIND_ALL_PREFERENCES_SUCCESS: string =
  "PROCESS_FIND_ALL_PREFERENCES_SUCCESS";
export const PROCESS_FIND_ALL_PREFERENCES_FAIL: string =
  "PROCESS_FIND_ALL_PREFERENCES_FAIL";

export const PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START: string =
  "PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START";
export const PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_SUCCESS: string =
  "PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_SUCCESS";
export const PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_FAIL: string =
  "PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_FAIL";

export const PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START: string =
  "PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START";
export const PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS: string =
  "PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS";
export const PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_FAIL: string =
  "PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_FAIL";

export const PROCESS_SUBMIT_CHECK_IN_START: string =
  "PROCESS_SUBMIT_CHECK_IN_START";
export const PROCESS_SUBMIT_CHECK_IN_SUCCESS: string =
  "PROCESS_SUBMIT_CHECK_IN_SUCCESS";
export const PROCESS_SUBMIT_CHECK_IN_FAIL: string =
  "PROCESS_SUBMIT_CHECK_IN_FAIL";

export const PROCESS_LOAD_TALKING_POINT_START: string =
  "PROCESS_LOAD_TALKING_POINT_START";
export const PROCESS_LOAD_TALKING_POINT_SUCCESS: string =
  "PROCESS_LOAD_TALKING_POINT_SUCCESS";
export const PROCESS_LOAD_TALKING_POINT_FAIL: string =
  "PROCESS_LOAD_TALKING_POINT_FAIL";

export const PROCESS_REQUEST_TALKING_POINT_START: string =
  "PROCESS_REQUEST_TALKING_POINT_START";
export const PROCESS_REQUEST_TALKING_POINT_SUCCESS: string =
  "PROCESS_REQUEST_TALKING_POINT_SUCCESS";
export const PROCESS_REQUEST_TALKING_POINT_FAIL: string =
  "PROCESS_REQUEST_TALKING_POINT_FAIL";

export const LOAD_PARTNERSHIP_MESSAGES_START: string =
  "LOAD_PARTNERSHIP_MESSAGES_START";
export const LOAD_PARTNERSHIP_MESSAGES_SUCCESS: string =
  "LOAD_PARTNERSHIP_MESSAGES_SUCCESS";
export const LOAD_PARTNERSHIP_MESSAGES_FAIL: string =
  "LOAD_PARTNERSHIP_MESSAGES_FAIL";

export const LOAD_USER_PARTNERSHIPS_START: string =
  "LOAD_USER_PARTNERSHIPS_START";
export const LOAD_USER_PARTNERSHIPS_SUCCESS: string =
  "LOAD_USER_PARTNERSHIPS_SUCCESS";
export const LOAD_USER_PARTNERSHIPS_FAIL: string =
  "LOAD_USER_PARTNERSHIPS_FAIL";

export const PROCESS_UPDATE_PARTNERSHIP_START: string =
  "PROCESS_UPDATE_PARTNERSHIP_START";
export const PROCESS_UPDATE_PARTNERSHIP_SUCCESS: string =
  "PROCESS_UPDATE_PARTNERSHIP_SUCCESS";
export const PROCESS_UPDATE_PARTNERSHIP_FAIL: string =
  "PROCESS_UPDATE_PARTNERSHIP_FAIL";

export const PROCESS_DELETE_PARTNERSHIP_START: string =
  "PROCESS_DELETE_PARTNERSHIP_START";
export const PROCESS_DELETE_PARTNERSHIP_SUCCESS: string =
  "PROCESS_DELETE_PARTNERSHIP_SUCCESS";
export const PROCESS_DELETE_PARTNERSHIP_FAIL: string =
  "PROCESS_DELETE_PARTNERSHIP_FAIL";

export const PROCESS_NEW_MESSAGE_START: string = "PROCESS_NEW_MESSAGE_START";
export const PROCESS_NEW_MESSAGE_SUCCESS: string =
  "PROCESS_NEW_MESSAGE_SUCCESS";

export const PROCESS_REQUEST_NEW_PARTNERSHIP_START: string =
  "PROCESS_REQUEST_NEW_PARTNERSHIP_START";
export const PROCESS_REQUEST_NEW_PARTNERSHIP_SUCCESS: string =
  "PROCESS_REQUEST_NEW_PARTNERSHIP_SUCCESS";
export const PROCESS_REQUEST_NEW_PARTNERSHIP_FAIL: string =
  "PROCESS_REQUEST_NEW_PARTNERSHIP_FAIL";

export const REPORT_PARTNERSHIP_START: string = "REPORT_PARTNERSHIP_START";
export const REPORT_PARTNERSHIP_SUCCESS: string = "REPORT_PARTNERSHIP_SUCCESS";
export const REPORT_PARTNERSHIP_FAIL: string = "REPORT_PARTNERSHIP_FAIL";

export const UPDATE_PARTNERSHIP_START: string = "UPDATE_PARTNERSHIP_START";
export const UPDATE_PARTNERSHIP_SUCCESS: string = "UPDATE_PARTNERSHIP_SUCCESS";
export const UPDATE_PARTNERSHIP_FAIL: string = "UPDATE_PARTNERSHIP_FAIL";

export const UPDATE_USER_PREFERENCE_ANSWER: string =
  "UPDATE_USER_PREFERENCE_ANSWER";

export const PARTNERSHIP_RESET_STATE: string = "PARTNERSHIP_RESET_STATE";

export const LEAVE_ROOM: string = "LEAVE_ROOM";

export const SET_SELECTED_MESSAGE: string = "SET_SELECTED_MESSAGE";

export const UPDATE_SELECTED_PARTNERSHIP: string =
  "UPDATE_SELECTED_PARTNERSHIP";

export const SCROLL_TO_BOTTOM: string = "SCROLL_TO_BOTTOM";

export const SET_PARTNERSHIP_STATE: string = "SET_PARTNERSHIP_STATE";
