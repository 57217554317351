import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import ourBrainApi from "../../../api/createApiService";
import {
  ForbiddenError,
  NoContent,
  UnauthorizedError,
} from "../../../api/errors";
import { createAction } from "../../../rootRedux/rootActions";
import { PROCESS_LOGOUT_START } from "../../Auth/redux/constants";
import { GET_FEED_START } from "../../Hub/redux/constants";
import { PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS } from "../../Partnerships/redux/constants";
import {
  ADMIN_RESET_STATE,
  CANCEL_ACTION,
  CREATE_PARTNERSHIP_FAIL,
  CREATE_PARTNERSHIP_START,
  CREATE_PARTNERSHIP_SUCCESS,
  GET_PARTNERSHIP_FAIL,
  GET_PARTNERSHIP_START,
  GET_PARTNERSHIP_SUCCESS,
  GET_USER_EMAIL_FAIL,
  GET_USER_EMAIL_START,
  GET_USER_EMAIL_SUCCESS,
  LOAD_ALL_USERS_FAIL,
  LOAD_ALL_USERS_START,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_USER_PREFERENCES_FAIL,
  LOAD_ALL_USER_PREFERENCES_START,
  LOAD_ALL_USER_PREFERENCES_SUCCESS,
  LOAD_PARTNERSHIPS_FAIL,
  LOAD_PARTNERSHIPS_START,
  LOAD_PARTNERSHIPS_SUCCESS,
  LOAD_USER_PREFERENCE_ANSWERS_FAIL,
  LOAD_USER_PREFERENCE_ANSWERS_START,
  LOAD_USER_PREFERENCE_ANSWERS_SUCCESS,
  MODERATE_CHECK_INS_FAIL,
  MODERATE_CHECK_INS_START,
  MODERATE_CHECK_INS_SUCCESS,
  MODERATE_POSTS_FAIL,
  MODERATE_POSTS_START,
  MODERATE_POSTS_SUCCESS,
  UPDATE_PARTNERSHIP_CHECK_IN_FAIL,
  UPDATE_PARTNERSHIP_CHECK_IN_START,
  UPDATE_PARTNERSHIP_CHECK_IN_SUCCESS,
} from "./constants";

function* processLoadAllUsers({ payload }: AnyAction): Generator {
  const { condition, limit, select, populate } = payload;
  let users: any;
  try {
    users = yield call([ourBrainApi, "post"], "/user/find", {
      condition,
      limit,
      select,
      populate,
    });
    yield put(createAction(LOAD_ALL_USERS_SUCCESS, users));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(LOAD_ALL_USERS_FAIL, error));
  }
}

function* processCreatePartnership({ payload }: AnyAction): Generator {
  const { createPartnershipData } = payload;
  const dashboardSearchConditions = yield select(
    (state) => state.admins.dashboardSearchConditions
  );
  try {
    yield call(
      [ourBrainApi, "post"],
      "/partnership/create",
      createPartnershipData
    );
    yield put(createAction(CREATE_PARTNERSHIP_SUCCESS, { showSuccess: true }));
    yield put(
      createAction(ADMIN_RESET_STATE, {
        selectedUserIds: [],
        partnership: {},
      })
    );
    yield put(
      createAction(LOAD_ALL_USER_PREFERENCES_START, {
        dashboardSearchConditions,
      })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(CREATE_PARTNERSHIP_FAIL, { showFail: false }));
  }
}

function* processGetPartnership({ payload }: AnyAction): Generator {
  const { index } = payload;
  try {
    const { foundPartnership }: any = yield call(
      [ourBrainApi, "get"],
      `/partnership/${index}/find`
    );
    yield put(createAction(GET_PARTNERSHIP_SUCCESS, foundPartnership));
  } catch (error) {
    if (error instanceof NoContent) {
      yield put(createAction(GET_PARTNERSHIP_SUCCESS, null));
      return;
    }
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(GET_PARTNERSHIP_FAIL, error));
  }
}

function* processLoadAllUserPreferences({ payload }: AnyAction): Generator {
  const { dashboardSearchConditions } = payload;
  try {
    const {
      userPreferenceAnswers,
      userPreferences,
      userEmails,
    }: any = yield call(
      [ourBrainApi, "post"],
      "/user/preference",
      dashboardSearchConditions
    );
    yield put(
      createAction(LOAD_ALL_USER_PREFERENCES_SUCCESS, {
        userPreferences,
        userPreferenceAnswers,
        userEmails,
      })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(LOAD_ALL_USER_PREFERENCES_FAIL, error));
  }
}

function* processLoadUserPreferenceAnswers({ payload }: AnyAction): Generator {
  const {
    userPreferenceId,
    selectedUserPreferenceIds,
    renderRowsState,
  } = payload;
  if (selectedUserPreferenceIds.includes(userPreferenceId)) {
    yield put(createAction(CANCEL_ACTION, { renderRows: !renderRowsState }));
    return;
  }
  try {
    const { foundUserPreferenceAnswers }: any = yield call(
      [ourBrainApi, "post"],
      "/user/preference/answers",
      { userPreferenceId }
    );
    yield put(
      createAction(
        LOAD_USER_PREFERENCE_ANSWERS_SUCCESS,
        foundUserPreferenceAnswers
      )
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(LOAD_USER_PREFERENCE_ANSWERS_FAIL, error));
  }
}

function* processModerateCheckIns({ payload }: AnyAction): Generator {
  const { moderatedCheckIns } = payload;
  try {
    const foundPartnershipCheckIns: any = yield call(
      [ourBrainApi, "post"],
      "/partnership/check-in/moderate",
      moderatedCheckIns
    );
    yield put(createAction(MODERATE_CHECK_INS_SUCCESS, {}));
    yield put(
      createAction(PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS, {
        partnershipCheckIns: foundPartnershipCheckIns,
      })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(MODERATE_CHECK_INS_FAIL, error));
  }
}

function* getAllPartnershipsSaga({ payload }: AnyAction): Generator {
  try {
    const foundPartnerships: any = yield call(
      [ourBrainApi, "get"],
      "/partnership"
    );
    yield put(
      createAction(LOAD_PARTNERSHIPS_SUCCESS, {
        partnerships: foundPartnerships,
      })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(LOAD_PARTNERSHIPS_FAIL, error));
  }
}

function* getUserEmailSaga({ payload }: AnyAction): Generator {
  try {
    const foundEmail: any = yield call(
      [ourBrainApi, "post"],
      "/user/email",
      payload
    );
    yield put(createAction(GET_USER_EMAIL_SUCCESS, foundEmail));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(GET_USER_EMAIL_FAIL, error));
  }
}
function* updatePartnershipCheckInSaga({ payload }: AnyAction): Generator {
  try {
    const { partnershipCheckIns }: any = yield call(
      [ourBrainApi, "post"],
      "/partnership/check-in/update",
      payload
    );
    yield put(createAction(UPDATE_PARTNERSHIP_CHECK_IN_SUCCESS, {}));
    yield put(
      createAction(PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS, {
        partnershipCheckIns,
      })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(UPDATE_PARTNERSHIP_CHECK_IN_FAIL, error));
  }
}

function* moderatePosts({ payload }: AnyAction): Generator {
  const { data } = payload;
  try {
    const response = yield call([ourBrainApi, "post"], `/hub/moderate`, data);
    yield put(createAction(MODERATE_POSTS_SUCCESS, response));
    yield put(createAction(GET_FEED_START, { query: "?type=all" }));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(MODERATE_POSTS_FAIL, { showError: error.message }));
  }
}

export default [
  takeLatest(LOAD_ALL_USERS_START, processLoadAllUsers),
  takeLatest(CREATE_PARTNERSHIP_START, processCreatePartnership),
  takeLatest(GET_PARTNERSHIP_START, processGetPartnership),
  takeLatest(LOAD_ALL_USER_PREFERENCES_START, processLoadAllUserPreferences),
  takeLatest(
    LOAD_USER_PREFERENCE_ANSWERS_START,
    processLoadUserPreferenceAnswers
  ),
  takeLatest(MODERATE_CHECK_INS_START, processModerateCheckIns),
  takeLatest(LOAD_PARTNERSHIPS_START, getAllPartnershipsSaga),
  takeLatest(GET_USER_EMAIL_START, getUserEmailSaga),
  takeLatest(UPDATE_PARTNERSHIP_CHECK_IN_START, updatePartnershipCheckInSaga),
  takeLatest(MODERATE_POSTS_START, moderatePosts),
];
