import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import classNames from "classnames";
import { checkmark, close } from "ionicons/icons";
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { NavBar, Notification } from "../../../../components";
import { EFeedPostTypes } from "../../../../interfaces/interfaces/feedPost";
import { ModerationResult } from "../../../../interfaces/interfaces/partnership";
import { actions as hubActions } from "../../../Hub/redux";
import { IHubActions } from "../../../Hub/redux/actions";
import { IHubState } from "../../../Hub/redux/reducer";
import { actions as adminActions } from "../../redux";
import { IAdminActions } from "../../redux/actions";
import { IAdminState } from "../../redux/reducer";
import styles from "./index.module.scss";

type FeedPostItemProps = {
  text: string;
  feedPostId: string;
  actions: IAdminActions;
  moderation: { decision: ModerationResult };
  moderatedFeedPosts: any[];
  type: EFeedPostTypes;
};

const FeedPostItem: FC<FeedPostItemProps> = (props) => {
  const {
    text,
    actions,
    type,
    moderatedFeedPosts,
    feedPostId,
    moderation,
  } = props;

  const [selected, setSelected] = useState<string>(moderation.decision);

  function handleClick(decision: string) {
    let isSelected: boolean = false;
    if (typeof selected !== "undefined") {
      isSelected = true;
    }
    setSelected(decision);

    let newModeratedFeedPosts: any[] = [];
    if (isSelected) {
      newModeratedFeedPosts = moderatedFeedPosts.filter(
        (moderatedFeedPost) => moderatedFeedPost.feedPostId !== feedPostId
      );
      newModeratedFeedPosts.push({ feedPostId, decision });
    } else {
      newModeratedFeedPosts.push({ feedPostId, decision });
    }
    actions.setAdminState({
      moderatedFeedPosts: newModeratedFeedPosts,
    });
  }

  return (
    <IonItem>
      <div
        className={classNames(
          styles.feedPostItemContainer,
          styles[`${type}__border`]
        )}
      >
        <div>
          <p>{text}</p>
        </div>
        <div>
          <IonButtons>
            <IonButton
              onClick={() => handleClick("fail")}
              color={selected === "fail" ? "danger" : "light"}
            >
              <IonIcon icon={close} />
            </IonButton>
            <IonButton
              onClick={() => handleClick("pass")}
              color={selected === "pass" ? "success" : "light"}
            >
              <IonIcon icon={checkmark} />
            </IonButton>
          </IonButtons>
        </div>
      </div>
    </IonItem>
  );
};

type ModeratePostsProps = {
  actions: IHubActions & IAdminActions;
  hub: IHubState;
  admins: IAdminState;
};

const ModeratePosts: FC<ModeratePostsProps> = (props) => {
  const { actions, hub, admins } = props;

  const { getFeed } = actions;
  const { feedPosts } = hub;
  const { moderatedFeedPosts, showSuccess } = admins;

  useEffect(() => {
    getFeed({ query: "?type=all" });
  }, []);

  function handleModeration() {
    actions.moderatePosts({ data: moderatedFeedPosts });
  }

  return (
    <IonPage>
      <Notification
        position="top"
        color="success"
        message={"Moderation successful"}
        isOpen={showSuccess}
        onDidDismiss={() => actions.resetState({ showSuccess: false })}
      />
      <NavBar title="Moderate posts" backButton backLink="/admin" />
      <IonContent>
        <IonList>
          {feedPosts.map(({ text, _id, moderation, type }) => (
            <FeedPostItem
              key={_id}
              feedPostId={_id}
              text={text}
              actions={actions}
              moderatedFeedPosts={moderatedFeedPosts}
              moderation={moderation}
              type={type}
            />
          ))}
        </IonList>
      </IonContent>
      <IonFooter>
        <IonSelect
          onIonChange={(e) =>
            getFeed({ query: `?type=all&limit=${e.detail.value || 100}` })
          }
          defaultValue={100}
          placeholder="Limit"
        >
          <IonSelectOption value={100}>100</IonSelectOption>
          <IonSelectOption value={200}>200</IonSelectOption>
          <IonSelectOption value={300}>300</IonSelectOption>
          <IonSelectOption value={500}>500</IonSelectOption>
          <IonSelectOption value={0}>All</IonSelectOption>
        </IonSelect>
        <IonButton expand="block" onClick={handleModeration}>
          Moderate
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    user: state.auth.user, // CHANGE
    loading: state.admins.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...hubActions, ...adminActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModeratePosts);
