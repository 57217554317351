import React, { FC } from "react"
import { IonButton, IonIcon } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { JSX } from "@ionic/core";
import { chevronBack } from "ionicons/icons";

interface OurBrainButtonProps extends JSX.IonButton, Pick<HTMLIonButtonElement, any>, Pick<HTMLButtonElement, any> {
    link?: string
    backButton?: boolean
}


const OurBrainButton: FC<OurBrainButtonProps> = ({link, backButton, children, ...props}) => {
    const {push} = useHistory()

    const handleOnClick = (e: any) => {
        e.preventDefault()
        if (link?.includes("http")) {
            window.open(link)
            return
        }
        if (typeof link !== 'undefined') {
            push(link)
        }
    }
    return (
        <IonButton {...props} onClick={(e) => handleOnClick(e)}>
            <IonIcon icon={chevronBack} hidden={!backButton}/>
            {children}
        </IonButton>
    )
}

export default OurBrainButton
