import {
  IPartnershipCheckInConfig,
  IPartnershipModel,
  IPreferenceModel,
  IUser,
  IUserPreferenceAnswerModel,
  IUserPreferenceModel_populated,
} from "../../../interfaces/interfaces";
import {
  ADD_PARTNERSHIP_CHECK_IN_TO_LIST,
  ADMIN_RESET_STATE,
  CANCEL_ACTION,
  CREATE_PARTNERSHIP_FAIL,
  CREATE_PARTNERSHIP_START,
  CREATE_PARTNERSHIP_SUCCESS,
  GET_PARTNERSHIP_FAIL,
  GET_PARTNERSHIP_START,
  GET_PARTNERSHIP_SUCCESS,
  GET_USER_EMAIL_FAIL,
  GET_USER_EMAIL_START,
  GET_USER_EMAIL_SUCCESS,
  LOAD_ALL_USERS_FAIL,
  LOAD_ALL_USERS_START,
  LOAD_ALL_USERS_SUCCESS,
  LOAD_ALL_USER_PREFERENCES_FAIL,
  LOAD_ALL_USER_PREFERENCES_START,
  LOAD_ALL_USER_PREFERENCES_SUCCESS,
  LOAD_PARTNERSHIPS_FAIL,
  LOAD_PARTNERSHIPS_START,
  LOAD_PARTNERSHIPS_SUCCESS,
  LOAD_USER_PREFERENCE_ANSWERS_FAIL,
  LOAD_USER_PREFERENCE_ANSWERS_START,
  LOAD_USER_PREFERENCE_ANSWERS_SUCCESS,
  MODERATE_CHECK_INS_FAIL,
  MODERATE_CHECK_INS_START,
  MODERATE_CHECK_INS_SUCCESS,
  MODERATE_POSTS_FAIL,
  MODERATE_POSTS_START,
  MODERATE_POSTS_SUCCESS,
  PROCESS_SELECT_USER,
  SET_ADMIN_STATE,
  SHOW_ADMIN_MODAL,
  UPDATE_PARTNERSHIP_CHECK_IN_FAIL,
  UPDATE_PARTNERSHIP_CHECK_IN_START,
  UPDATE_PARTNERSHIP_CHECK_IN_SUCCESS,
} from "./constants";

export interface IModeratedCheckIns extends IPartnershipCheckInConfig {
  partnershipCheckInId: string;
  partnershipId: string;
  userId: string;
}

export interface IAdminState {
  isModalOpen: boolean;
  loading: boolean;
  createPartnershipData?: any[];
  partnerships: IPartnershipModel[];
  users: IUser[];
  showSuccess: boolean;
  showFail: boolean;
  foundPartnership: any;
  userPreferences?: IUserPreferenceModel_populated[];
  selectedPreference?: IPreferenceModel;
  userPreferenceAnswers?: IUserPreferenceAnswerModel[];
  showTable?: boolean;
  selectedUserPreferenceId?: string;
  selectedUserPreferenceIds: string[];
  renderRows: boolean;
  moderatedCheckIns: IModeratedCheckIns[];
  moderatedCheckInIds: string[];
  foundUserEmail?: string;
  userEmails?: string[];
  dashboardSearchConditions?: any;
  moderatedFeedPosts: any[];
}

const defaultState: IAdminState = {
  isModalOpen: false,
  loading: false,
  users: [],
  showFail: false,
  showSuccess: false,
  foundPartnership: null,
  selectedUserPreferenceIds: [],
  renderRows: true,
  moderatedCheckInIds: [],
  moderatedCheckIns: [],
  moderatedFeedPosts: [],
  partnerships: [],
};

function reducer(state = defaultState, action: any) {
  const { payload, type } = action as any;
  switch (type) {
    case LOAD_ALL_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case LOAD_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: payload,
      };
    case LOAD_ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_PARTNERSHIP_START:
      return {
        ...state,
        loading: true,
        foundPartnership: null,
      };
    case GET_PARTNERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        foundPartnership: payload,
      };
    case GET_PARTNERSHIP_FAIL:
      return {
        ...state,
        loading: false,
      };
    case LOAD_PARTNERSHIPS_START:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PARTNERSHIPS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case LOAD_PARTNERSHIPS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PARTNERSHIP_START:
      return {
        ...state,
        loading: true,
        showSuccess: false,
        showFail: false,
      };
    case CREATE_PARTNERSHIP_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case SHOW_ADMIN_MODAL:
      return {
        ...state,
        isModalOpen: !state.isModalOpen,
      };
    case CREATE_PARTNERSHIP_FAIL:
      return {
        ...state,
        loading: false,
        showFail: true,
      };
    case PROCESS_SELECT_USER:
      return {
        ...state,
        ...payload,
      };

    case LOAD_ALL_USER_PREFERENCES_START:
      return {
        ...state,
        ...payload,
        loading: true,
        userPreferences: [],
      };
    case LOAD_ALL_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        selectedUserPreferenceIds: [],
        renderRows: !state.renderRows,
      };
    case LOAD_ALL_USER_PREFERENCES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case LOAD_USER_PREFERENCE_ANSWERS_START:
      return {
        ...state,
      };
    case LOAD_USER_PREFERENCE_ANSWERS_SUCCESS:
      return {
        ...state,
        // ...payload,
        loadedUserPreferenceAnswers: payload.userPreferenceAnswers,
        loading: false,
        showTable: true,
        renderRows: !state.renderRows,
      };
    case LOAD_USER_PREFERENCE_ANSWERS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case CANCEL_ACTION:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case ADD_PARTNERSHIP_CHECK_IN_TO_LIST:
      let moderatedCheckInIds: string[];
      let moderatedCheckIns: IModeratedCheckIns[];
      if (
        state.moderatedCheckInIds.includes(
          payload.moderatedCheckIn.partnershipCheckInId
        )
      ) {
        moderatedCheckInIds = [...state.moderatedCheckInIds];
        moderatedCheckIns = state.moderatedCheckIns.map((moderatedCheckIn) => {
          if (
            moderatedCheckIn.partnershipCheckInId ===
            payload.moderatedCheckIn.partnershipCheckInId
          ) {
            return payload.moderatedCheckIn;
          }
          return moderatedCheckIn;
        });
      } else {
        moderatedCheckIns = [
          ...state.moderatedCheckIns,
          payload.moderatedCheckIn,
        ];
        moderatedCheckInIds = [
          ...state.moderatedCheckInIds,
          payload.moderatedCheckIn.partnershipCheckInId,
        ];
      }
      return {
        ...state,
        moderatedCheckInIds,
        moderatedCheckIns,
      };
    case MODERATE_CHECK_INS_START:
      return {
        ...state,
        loading: true,
      };
    case MODERATE_CHECK_INS_SUCCESS:
      return {
        ...state,
        loading: false,
        moderatedCheckInIds: [],
        moderatedCheckIns: [],
      };
    case MODERATE_CHECK_INS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case ADMIN_RESET_STATE:
      return {
        ...state,
        ...payload,
      };

    case GET_USER_EMAIL_START:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_EMAIL_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case GET_USER_EMAIL_FAIL:
      return {
        ...state,
      };

    case UPDATE_PARTNERSHIP_CHECK_IN_START:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PARTNERSHIP_CHECK_IN_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_PARTNERSHIP_CHECK_IN_FAIL:
      return {
        ...state,
      };

    case MODERATE_POSTS_START:
      return {
        ...state,
        loading: true,
      };
    case MODERATE_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
        showSuccess: true,
      };
    case MODERATE_POSTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_ADMIN_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export default reducer;
