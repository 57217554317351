import React, { FC } from 'react'
import { IonContent, IonSpinner } from "@ionic/react";
import styles from "./index.module.scss";

const Loader: FC<any> = ({hidden}) => {
    return (
        <IonContent hidden={hidden} className={styles.loaderContainer}>
            <div className={styles.loaderContainer}>
                <IonSpinner name="dots"/>
            </div>
        </IonContent>
    )
}

export default Loader
 
