import { IonPage } from "@ionic/react";
import { Push, push } from "connected-react-router";
import { isNil } from "lodash";
import React, { FC, useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useQuery } from "../../../../App";
import { Loader } from "../../../../components";
import {
  IPartnershipMessage,
  IPartnershipModel,
  IPartnershipUsersInfo,
  IUserModel,
  PartnershipClass,
} from "../../../../interfaces/interfaces";
import { IAuthState } from "../../../Auth/redux/reducer";
import OurBrainPartnershipNavBar from "../../components/OurBrainPartnershipNavBar";
import partnershipActions, { IPartnershipActions } from "../../redux/actions";
import { IPartnershipDefaultState } from "../../redux/reducer";
import styles from "./index.module.scss";
import PartnershipChat from "./PartnershipClasses/Chat/PartnershipChat";
import PartnershipCheckIn from "./PartnershipClasses/CheckIn/PartnershipCheckIn";
import PartnershipInfoModal from "./PartnershipModalBase";

type PartnershipProps = {
  partnership: IPartnershipDefaultState;
  auth: IAuthState;
  actions: IPartnershipActions & { push: Push };
  messages: IPartnershipMessage[];
  activeUser: IUserModel;
  loading: boolean;
  currentPartnershipId: string;
};

const Partnership: FC<PartnershipProps> = ({
  partnership,
  actions,
  activeUser,
}) => {
  const {
    userPartnerships,
    selectedPartnership,
    loading,
    activeUsersInfo,
  } = partnership;
  const [showPopover, setShowPopover] = useState(false);
  const [typing] = useState(false);
  const query = useQuery();
  const partnershipArrayIndex: number = parseInt(query.get("index")!, 10);
  useEffect(() => {
    const selectedPartnership: IPartnershipModel = userPartnerships![
      partnershipArrayIndex
    ].partnershipId;
    actions.setPartnershipState({
      selectedPartnership,
    });

    const partnerUsersInfo:
      | IPartnershipUsersInfo
      | undefined = selectedPartnership!.usersInfo?.find(
      ({ _id }) => _id !== activeUser._id
    );
    const activeUsersInfo:
      | IPartnershipUsersInfo
      | undefined = selectedPartnership!.usersInfo?.find(
      ({ _id }) => _id === activeUser._id
    );

    if (typeof activeUsersInfo !== "undefined" && !activeUsersInfo.seenInfo) {
      actions.setPartnershipState({ showModal: "info" });
    }
    actions.setPartnershipState({
      partnerUsersInfo,
      activeUsersInfo,
    });
    return () => {
      actions.setPartnershipState({ showSuccess: undefined });
    };
  }, []);

  const RenderIPartnershipClass = () => {
    const props = { actions, activeUser, partnership };
    const { CheckIn, Chat } = PartnershipClass;
    if (selectedPartnership!.class === Chat) {
      return <PartnershipChat {...props} />;
    }
    if (selectedPartnership!.class === CheckIn) {
      return <PartnershipCheckIn {...props} />;
    }
  };

  if (isNil(selectedPartnership)) {
    return <Loader />;
  }

  return (
    <IonPage className={styles.partnershipContainer}>
      <OurBrainPartnershipNavBar
        selectedPartnership={selectedPartnership}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        typing={typing}
        activeUser={activeUser}
        partnershipArrayIndex={partnershipArrayIndex}
        actions={actions}
      />
      {RenderIPartnershipClass()}
      {typeof activeUsersInfo !== "undefined" && (
        <PartnershipInfoModal
          activeUsersInfo={activeUsersInfo}
          showModal={partnership.showModal}
          loading={loading!}
          actions={actions}
          partnership={partnership}
          partnershipId={selectedPartnership?._id}
        />
      )}
    </IonPage>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
    activeUser: state.auth.user,
    partnership: state.partnership,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions, push }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Partnership);
