import {
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItemDivider,
} from "@ionic/react";
import { intersection } from "lodash";
import React, { FC } from "react";
import { OurBrainCard } from "../index";

type ActionMenuSectionProps = {
  section: IActionMenuItem;
  isAdmin: boolean;
  role?: string;
};

export interface IActionMenuItem {
  icon?: string;
  title: string;
  color: string;
  link: string;
  dividerContent?: any;
  admin?: boolean;
  allowedRoles?: string[];
}

const ActionMenuSection: FC<ActionMenuSectionProps> = ({
  section,
  isAdmin,
  role,
}) => {
  let hidden: boolean = false;
  if (typeof section.allowedRoles !== "undefined") {
    hidden = intersection(role, section.allowedRoles).length === 0;
  }
  return (
    <OurBrainCard
      index={section.title}
      color={section.color}
      link={section.link}
      hidden={hidden}
      cursor="pointer"
    >
      <IonCardHeader className="ion-align-items-center ion-justify-content-center">
        <IonIcon
          size="large"
          hidden={typeof section.icon === "undefined"}
          icon={section.icon}
          className="ion-margin-end"
        />
        <IonCardTitle>{section.title}</IonCardTitle>
      </IonCardHeader>
      <IonItemDivider
        hidden={typeof section.dividerContent === "undefined"}
        color="medium"
      >
        {section.dividerContent}
      </IonItemDivider>
    </OurBrainCard>
  );
};

export default ActionMenuSection;
