import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonImg,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { listOutline } from "ionicons/icons";
import React, { FC } from "react";
import { OurBrainButton } from "../../../../components";
import { getAssetPath } from "../../../../config/common";
import {
  IPartnershipModel,
  IUserModel,
} from "../../../../interfaces/interfaces";
import { IPartnershipActions } from "../../redux/actions";
import OurBrainPartnershipPopover from "../OurBrainPartnershipPopover";

type OurBrainPartnershipNavBarProps = {
  selectedPartnership?: IPartnershipModel;
  setShowPopover: any;
  showPopover: boolean;
  typing: boolean;
  activeUser: IUserModel;
  partnershipArrayIndex: number;
  actions: IPartnershipActions;
};

const OurBrainPartnershipNavBar: FC<OurBrainPartnershipNavBarProps> = (
  props
) => {
  const { setShowPopover, showPopover, partnershipArrayIndex, actions } = props;
  return (
    <IonHeader>
      <OurBrainPartnershipPopover
        actions={actions}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        index={partnershipArrayIndex}
      />
      <IonToolbar>
        <IonButtons slot="start">
          <IonButtons slot="start">
            <OurBrainButton
              color="secondary"
              backButton
              link="/app/partnership"
            >
              Back
            </OurBrainButton>
          </IonButtons>
        </IonButtons>
        <IonTitle>
          <IonImg
            src={getAssetPath({ name: "brain_notitle_tile" })}
            style={{ height: "30px" }}
          />
        </IonTitle>
        <IonButtons slot="end">
          <IonButton fill="clear" onClick={() => setShowPopover(true)}>
            <IonIcon color="primary" size="large" icon={listOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default OurBrainPartnershipNavBar;
