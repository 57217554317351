import { IonModal } from "@ionic/react";
import React, { FC } from "react";
import { IPartnershipUsersInfo } from "../../../../../interfaces/interfaces";
import { IPartnershipActions } from "../../../redux/actions";
import { IPartnershipDefaultState } from "../../../redux/reducer";
import Feedback from "./Modals/Feedback";
import Info from "./Modals/Info";
import Rate from "./Modals/Rate";
import Report from "./Modals/Report";

type PartnershipInfoModalProps = {
  partnershipId: string;
  partnership: IPartnershipDefaultState;
  loading: boolean;
  actions: IPartnershipActions;
  showModal: string;
  activeUsersInfo: IPartnershipUsersInfo;
};

const PartnershipInfoModal: FC<PartnershipInfoModalProps> = (props) => {
  const { showModal, actions } = props;
  const renderModal = () => {
    switch (showModal) {
      case "info":
        return <Info {...props} />;
      case "report":
        return <Report {...props} />;
      case "rate":
        return <Rate {...props} />;
      case "feedback":
        return <Feedback {...props} />;
    }
  };

  return (
    <IonModal
      isOpen={showModal !== ""}
      onDidDismiss={() => actions.setPartnershipState({ showModal: "" })}
    >
      {renderModal()}
    </IonModal>
  );
};

export default PartnershipInfoModal;
