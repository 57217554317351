import { IModalBase } from "../common";

export interface IQuestion {
  alias?: string;
  question: string;
  description?: string;
  tooltip?: string;
  options?: string[];
  range?: string[] | number[];
  level?: number;
  slide?: number;
  or?: string;
  inputType: QuestionType;
}

export interface IQuestionModel extends IQuestion, IModalBase {}

export enum QuestionType {
  Number = "number",
  Time = "time",
  Text = "text",
  Input = "input",
  Tel = "tel",
  Url = "url",
  Email = "email",
  Search = "search",
  Date = "date",
  Password = "password",
  Select = "select",
  Slider = "slider",
  TalkingPoint = "talkingPoint",
  File = "file",
  Button = "button",
}
