import { IAction } from "../../config/common";
import {
  LOAD_FORM_START,
  SET_GLOBAL_STATE,
  SUBMIT_FORM_START,
} from "./constants";

const processLoadFormStart: any = (payload: string): IAction => {
  return { type: LOAD_FORM_START, payload };
};

const processSubmitFormStart: any = (payload: string): IAction => {
  return { type: SUBMIT_FORM_START, payload };
};

const setGlobalState: any = (payload: string): IAction => {
  return { type: SET_GLOBAL_STATE, payload };
};

const gtagEvent: any = (payload: string): IAction => {
  return { type: SET_GLOBAL_STATE, payload };
};

export interface IGlobalActions {
  processLoadFormStart: Function;
  processSubmitFormStart: Function;
  setGlobalState: Function;
  gtagEvent: Function;
}

export default {
  processLoadFormStart,
  processSubmitFormStart,
  setGlobalState,
  gtagEvent,
};
