import { IonButton, IonIcon, IonInput, IonPage } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Input, OurBrainContent, OurBrainLogo } from "../../../../components";
import NavBar from "../../../../components/NavBar";
import OurBrainInformation, {
  IInformation,
} from "../../../../components/OurBrainInformation";
import { ICreateSendReferralRequestPayload } from "../../../../interfaces/interfaces";
import { actions as homeActions } from "../../redux";
import styles from "./index.module.scss";

type ReferralsProps = {
  actions: any;
  auth: any;
  home: any;
};

const referralInformation: IInformation = {
  title: "Referral scheme",
  paragraphs: [
    "At ourBrain we are growing community of listeners, as well as a connectivity platform. We are currently only taking new users through referrals. Who can you help?",
    "We simply need a first name and an email and your referral will get a unique code to join.",
  ],
};

const Referrals: FC<ReferralsProps> = ({ actions, auth }) => {
  const [submittedReferral, setSubmittedReferral] = useState(false);
  const { errors, control, handleSubmit, formState } = useForm({
    defaultValues: { firstName: "", email: "" },
    mode: "onBlur",
  });
  useEffect(() => {}, [auth.user._id]);

  const handleSubmitReferral = (values: any) => {
    const referralData: ICreateSendReferralRequestPayload = {
      referrerId: auth.user._id,
      referrerName: auth.user.firstName,
      referralEmail: values.email,
      referralName: values.firstName,
    };
    actions.processSendReferral(referralData);
    setSubmittedReferral(true);
  };

  return (
    <IonPage>
      <NavBar backButton backLink="/app" actions={actions} title="Referrals" />
      <OurBrainContent>
        <div className={styles.referralContainer}>
          <OurBrainInformation information={referralInformation} />
          <form onSubmit={handleSubmit(handleSubmitReferral)}>
            <Input
              errors={errors}
              control={control}
              labelPosition="stacked"
              name="firstName"
              type="text"
              rules={{ required: true }}
              as={IonInput}
            />
            <Input
              errors={errors}
              control={control}
              labelPosition="stacked"
              name="email"
              type="text"
              rules={{ required: true }}
              as={IonInput}
            />
            <div className={styles.referralButtons}>
              <IonButton
                hidden={submittedReferral}
                type="submit"
                disabled={!formState.isValid}
                fill="clear"
              >
                Submit
              </IonButton>
              <IonIcon
                className="ion-margin-vertical"
                size="large"
                color="primary"
                hidden={!submittedReferral}
                icon={checkmark}
              />
            </div>
          </form>
        </div>
        <OurBrainLogo />
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    activeUser: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...homeActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
