import classNames from "classnames";
import React, { FC, memo } from "react";
import styles from "./styles.module.scss";
type StylishInputProps = {
  forwardKey?: any;
  name: string;
  value: any;
  placeholder?: string;
  onChange: any;
  type: string;
  errors?: any;
  optional?: boolean;
  label?: string;
};

const StylishInput: FC<StylishInputProps> = (props) => {
  const {
    name,
    placeholder,
    value,
    onChange,
    errors,
    type,
    forwardKey,
    optional,
    label,
  } = props;

  let inputErrors: { [key: string]: any } = {};
  if (typeof errors !== "undefined") {
    inputErrors = errors;
  }
  return (
    <div key={forwardKey}>
      <label htmlFor={name}>
        {label || placeholder}
        {optional && " (optional)"}
      </label>
      <input
        type={type}
        className={classNames(
          styles.form__input,
          value?.length !== 0 ? styles.form__input__filled : "",
          typeof inputErrors[name] !== "undefined"
            ? styles.form__input__error
            : ""
        )}
        id={name}
        name={name}
        placeholder={placeholder || "Type here..."}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default memo(StylishInput);
