import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import ourBrainApi from "../../../api/createApiService";
import { ForbiddenError, UnauthorizedError } from "../../../api/errors";
import { createAction } from "../../../rootRedux/rootActions";
import { PROCESS_LOGOUT_START } from "../../Auth/redux/constants";
import {
  PROCESS_SEND_REFERRAL_FAIL,
  PROCESS_SEND_REFERRAL_START,
  PROCESS_SEND_REFERRAL_SUCCESS,
} from "./constants";

function* processSendReferral({ payload }: AnyAction): Generator {
  try {
    yield call([ourBrainApi, "post"], "/referral/send", payload);
    yield put(createAction(PROCESS_SEND_REFERRAL_SUCCESS, {}));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(PROCESS_SEND_REFERRAL_FAIL, error.response));
  }
}

const watchSendReferral = takeLatest(
  PROCESS_SEND_REFERRAL_START,
  processSendReferral
);

export default [watchSendReferral];
