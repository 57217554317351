import {
  IonIcon,
  IonItem,
  IonLabel,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonTextarea,
} from "@ionic/react";
import { happyOutline, sadOutline } from "ionicons/icons";
import { isNil, startCase } from "lodash";
import React, { FC } from "react";
import { IQuestionModel, QuestionType } from "../../interfaces/interfaces";

export interface IQuestionModelF extends IQuestionModel {
  _id: any;
}
type OurBrainQuestionInputProps = {
  feedbackFormQuestion: IQuestionModelF;
  formik: any;
};

const OurBrainQuestionInput: FC<OurBrainQuestionInputProps> = (props) => {
  const { formik, feedbackFormQuestion } = props;

  if (isNil(feedbackFormQuestion._id)) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (feedbackFormQuestion.inputType === QuestionType.Text) {
    return (
      <IonItem key={feedbackFormQuestion._id}>
        <IonLabel position="stacked" className="ion-text-wrap">
          {feedbackFormQuestion.question}
        </IonLabel>
        <IonTextarea
          value={formik.values[feedbackFormQuestion._id]}
          onIonChange={(e: any) =>
            (formik.values[feedbackFormQuestion._id] = e.detail.value)
          }
          name={feedbackFormQuestion._id}
          placeholder="Type here..."
          rows={2}
          autocapitalize="sentences"
        />
      </IonItem>
    );
  }
  if (feedbackFormQuestion.inputType === QuestionType.Select) {
    return (
      <IonItem key={feedbackFormQuestion._id} className="ion-text-wrap">
        <IonLabel position="stacked">{feedbackFormQuestion.question}</IonLabel>
        <IonSelect
          placeholder="Select"
          className="ion-text-wrap"
          onIonChange={(e) => {
            formik.values[feedbackFormQuestion._id] = e.detail.value;
          }}
        >
          {feedbackFormQuestion.options?.map((option: string) => (
            <IonSelectOption key={option} value={option}>
              {!option.includes(" ") ? startCase(option) : option}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    );
  }
  if (feedbackFormQuestion.inputType === QuestionType.Slider) {
    return (
      <IonItem>
        <IonRange
          min={feedbackFormQuestion.range![0] as number}
          max={feedbackFormQuestion.range![1] as number}
          step={1}
          snaps={true}
          ticks={true}
          debounce={1000}
          onIonChange={(e) =>
            (formik.values[feedbackFormQuestion._id] = e.detail.value)
          }
        >
          <IonIcon size="small" slot="start" icon={sadOutline} />
          <IonIcon slot="end" icon={happyOutline} />
        </IonRange>
      </IonItem>
    );
  }

  return <div></div>;
};
export default OurBrainQuestionInput;
