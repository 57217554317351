import { IonIcon, IonText } from "@ionic/react";
import { radioButtonOn } from "ionicons/icons";
import { startCase } from "lodash";
import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";

type OurBrainInformationProps = {
  information: IInformation;
  hidden?: boolean;
  useStartCase?: boolean;
  center?: boolean;
};

export interface IInformation {
  title?: string;
  subtitle?: string;
  paragraphs?: string[];
  bullets?: number[];
  icon?: string;
  icons?: string[];
}

const OurBrainInformation: FC<OurBrainInformationProps> = (props) => {
  const { information, hidden, useStartCase, center } = props;
  const { bullets, icon, icons, paragraphs, title, subtitle } = information;

  const [editedTitle, setEditedTitle] = useState(title);
  const [sentenceEnding, setSentenceEnding] = useState(".");

  function chooseIcon(i: number) {
    if (typeof icon !== "undefined") {
      return icon;
    }
    if (typeof icons !== "undefined") {
      return icons[i];
    } else return radioButtonOn;
  }

  useEffect(() => {
    if (typeof title !== "undefined") {
      const titleEnd: string = title[title.length - 1];
      if (titleEnd === "!" || titleEnd === "?") {
        setSentenceEnding(titleEnd);
        setEditedTitle(title.substr(0, title.length - 1));
      }
    }
  }, [title]);

  return (
    <div className={styles.informationContainer} hidden={hidden}>
      <IonText
        className={center ? "ion-text-center" : "ion-text-left"}
        color="tertiary"
        hidden={typeof title === "undefined"}
      >
        <h3 className={styles.title}>
          {useStartCase ? startCase(editedTitle) : editedTitle}
          <IonText color="secondary">{sentenceEnding}</IonText>
        </h3>
      </IonText>
      <IonText
        className={center ? "ion-text-center" : "ion-text-left"}
        color="tertiary"
        hidden={typeof subtitle === "undefined"}
      >
        <h4>{subtitle}</h4>
      </IonText>
      {paragraphs?.map((paragraph, i) => (
        <div className={styles.paragraph} key={i}>
          <IonIcon
            className="ion-text-left"
            hidden={!bullets?.includes(i)}
            icon={chooseIcon(i)}
            color="secondary"
          />
          <IonText className={center ? "ion-text-center" : "ion-text-left"}>
            <p>{paragraph}</p>
          </IonText>
        </div>
      ))}
    </div>
  );
};
export default OurBrainInformation;
