import { IonCard, IonCardContent, IonIcon, IonText } from "@ionic/react";
import cn from "classnames";
import { flag, timeOutline } from "ionicons/icons";
import React, { FC, useEffect, useState } from "react";
import {
  IPartnershipCheckInModel,
  IUserModel,
  ModerationResult,
} from "../../../../../../../interfaces/interfaces";
import { IOpenModal } from "../PartnershipCheckIn";
import styles from "./index.module.scss";

type PartnershipCheckInBoxProps = {
  partnershipCheckIn?: IPartnershipCheckInModel;
  activeUser: IUserModel;
  setOpenModal: React.Dispatch<React.SetStateAction<IOpenModal>>;
};
const PartnershipCheckInBox: FC<PartnershipCheckInBoxProps> = (props) => {
  const { partnershipCheckIn, activeUser, setOpenModal } = props;
  const [checkIn, setCheckIn] = useState(true);

  useEffect(() => {
    if (typeof partnershipCheckIn === "undefined") {
      setCheckIn(false);
    }
  }, [partnershipCheckIn]);

  const handleOnClick = () => {
    if (checkIn) {
      setOpenModal({ isOpen: true, answer: partnershipCheckIn?.message });
    }
  };

  const renderCheckIn = () => {
    if (partnershipCheckIn?.config.decision === ModerationResult.Pass) {
      return `${partnershipCheckIn?.message.substr(0, 75)}${
        typeof partnershipCheckIn !== "undefined" &&
        partnershipCheckIn?.message.length > 75
          ? "..."
          : ""
      }`;
    }
    if (
      partnershipCheckIn?.config.decision === ModerationResult.Pending &&
      activeUser._id !== partnershipCheckIn?.userId
    ) {
      return <IonIcon icon={timeOutline} />;
    }
    if (
      partnershipCheckIn?.config.decision.includes("warning") &&
      activeUser._id !== partnershipCheckIn?.userId
    ) {
      return <IonIcon icon={flag} />;
    }
    if (
      partnershipCheckIn?.config.decision === ModerationResult.Fail &&
      activeUser._id !== partnershipCheckIn?.userId
    ) {
      return <IonIcon icon={flag} />;
    }
    return `${partnershipCheckIn?.message.substr(0, 75)}${
      typeof partnershipCheckIn !== "undefined" &&
      partnershipCheckIn?.message.length > 75
        ? "..."
        : ""
    }`;
  };

  const isActiveUserPartnershipCheckIn =
    partnershipCheckIn?.userId === activeUser._id;
  return (
    <IonCard
      className={cn(
        styles.partnershipCheckInBoxContainer,
        !isActiveUserPartnershipCheckIn && styles.notActiveUser
      )}
      onClick={handleOnClick}
      style={{ cursor: "pointer" }}
    >
      <IonCardContent>
        <IonText color="light">
          <p style={{ overflowWrap: "anywhere" }}>{renderCheckIn()}</p>
        </IonText>
      </IonCardContent>
    </IonCard>
  );
};
export default PartnershipCheckInBox;
