import { IonButton, IonPage, IonSpinner } from "@ionic/react";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainButton, OurBrainContent } from "../../../../../components";
import Title from "../../../../../components/OurBrainHeader";
import StylishInput from "../../../../../components/StylishInput";
import { actions as resetActions } from "../../../redux/";

type ResetProps = {
  handleReset: Function;
  actions: any;
  loading: boolean;
  auth: any;
};
let initialValues = {
  password: "",
  email: "",
};

export interface IResetPasswordVerifyParams {
  verificationCode?: string;
}

const Verify: FC<ResetProps> = ({ actions, loading }) => {
  const { handleSubmit, errors, control, formState, watch } = useForm({
    defaultValues: { ...initialValues },
    mode: "onSubmit",
  });
  const history = useHistory();
  const { verificationCode } = useParams() as IResetPasswordVerifyParams;
  const handleResetPasswordVerify = async (data: any) => {
    data.verificationCode = verificationCode;
    await actions.processPasswordResetVerifyStart({ data, history });
  };

  return (
    <IonPage>
      <Title />
      <OurBrainContent>
        <h3>Reset password</h3>
        <form onSubmit={handleSubmit(handleResetPasswordVerify)}>
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            as={({ onChange, value }) => (
              <StylishInput
                name="password"
                type="password"
                label="Password"
                value={value}
                onChange={onChange}
                errors={errors}
              />
            )}
          />
          <Controller
            name="repeatPassword"
            control={control}
            rules={{ required: true }}
            as={({ onChange, value }) => (
              <StylishInput
                name="repeatPassword"
                type="password"
                label="Repeat password"
                value={value}
                onChange={onChange}
                errors={errors}
              />
            )}
          />
          <IonButton
            shape="round"
            expand="block"
            size="large"
            type="submit"
            className="ion-margin-top"
          >
            {loading ? <IonSpinner name="crescent" /> : "Submit"}
          </IonButton>
        </form>
        <OurBrainButton
          color="secondary"
          fill="clear"
          expand="block"
          size="large"
          link="/"
          className="ion-margin-top"
        >
          Back
        </OurBrainButton>
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    user: state.auth.user, // CHANGE
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...resetActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Verify);
