import { IonButton, IonIcon, IonItem, IonList, IonPopover } from "@ionic/react";
import {
  informationCircle,
  swapVertical,
  textOutline,
  warning,
} from "ionicons/icons";
import React, { FC } from "react";
import { IPartnershipActions } from "../../redux/actions";

type OurBrainPartnershipPopoverProps = {
  index: number;
  setShowPopover: any;
  showPopover: boolean;
  actions: IPartnershipActions;
};
const OurBrainPartnershipPopover: FC<OurBrainPartnershipPopoverProps> = (
  props
) => {
  const { setShowPopover, showPopover, actions } = props;
  return (
    <IonPopover isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>
      <IonList>
        <IonItem>
          <IonIcon icon={informationCircle} />
          <IonButton
            size="large"
            fill="clear"
            onClick={() => actions.setPartnershipState({ showModal: "info" })}
          >
            Info
          </IonButton>
        </IonItem>
        <IonItem>
          <IonIcon icon={swapVertical} />
          <IonButton
            size="large"
            fill="clear"
            onClick={() => actions.setPartnershipState({ showModal: "rate" })}
          >
            Rating
          </IonButton>
        </IonItem>
        <IonItem>
          <IonIcon icon={textOutline} />
          <IonButton
            size="large"
            fill="clear"
            onClick={() =>
              actions.setPartnershipState({ showModal: "feedback" })
            }
          >
            Feedback
          </IonButton>
        </IonItem>
        <IonItem>
          <IonIcon icon={warning} />
          <IonButton
            size="large"
            fill="clear"
            onClick={() => actions.setPartnershipState({ showModal: "report" })}
          >
            Report
          </IonButton>
        </IonItem>
      </IonList>
    </IonPopover>
  );
};

export default OurBrainPartnershipPopover;
