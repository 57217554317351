import { IModalBase } from "../common";
import { IQuestionModel } from "../question";

export interface IPreference {
  type: PreferenceTypes;
  title: string;
  description: string | string[];
  isActive: boolean;
  questionIds: IQuestionModel["_id"][];
}

export interface IPreferenceModel extends IPreference, IModalBase {}

enum PreferenceTypes {
  Interest = "interest",
  Experiences = "experiences",
  MentalHealth = "mentalHealth",
}
