import { IAction } from "../../../config/common";
import {
    PROCESS_CHANGE_PASSWORD_START,
    PROCESS_UPDATE_USER_START,
    PROCESS_UPLOAD_PHOTO_START,
    PROFILE_RESET_STATE,
    SHOW_PROFILE_MODAL
} from "./constants";

const processChangePassword: any = (payload: string): IAction => {
    return {type: PROCESS_CHANGE_PASSWORD_START, payload}
}

export const showModal: any = (payload: string): IAction => {
    return {type: SHOW_PROFILE_MODAL, payload}
}

export const processUpdateUserStart: any = (payload: any): IAction => { // CHANGE
    return {type: PROCESS_UPDATE_USER_START, payload} // CHANGE
}
export const processUploadPhotoStart: any = (payload: any): IAction => {
    return {type: PROCESS_UPLOAD_PHOTO_START, payload}
}

export const reset: any = (payload: any): IAction => {
    return {type: PROFILE_RESET_STATE, payload}
}

export interface IProfileActions {
    processChangePassword: Function
    showModal: Function
    processUpdateUserStart: Function // CHANGE
    processUploadPhotoStart: Function
    reset: Function
}

export default {
    processChangePassword,
    showModal,
    processUpdateUserStart, // CHANGE
    processUploadPhotoStart,
    reset
}
