import { IonButton, IonInput, IonPage, IonSpinner } from "@ionic/react";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainButton, OurBrainContent } from "../../../../../components";
import Input from "../../../../../components/Input";
import Title from "../../../../../components/OurBrainHeader";
import { actions as resetActions } from "../../../redux/";

type ResetProps = {
  handleReset: Function;
  actions: any;
  loading: boolean;
  auth: any;
};
let initialValues = {
  password: "",
  email: "",
};

const Start: FC<ResetProps> = ({ actions, loading }) => {
  const { handleSubmit, errors, control, formState } = useForm({
    defaultValues: { ...initialValues },
    mode: "onChange",
  });
  const history = useHistory();
  const handleResetPasswordStart = async (data: any) => {
    await actions.processPasswordResetStartStart({ data, history });
  };

  return (
    <IonPage>
      <Title />
      <OurBrainContent>
        <h3>Reset password</h3>
        <form onSubmit={handleSubmit(handleResetPasswordStart)}>
          <Input
            errors={errors}
            control={control}
            labelPosition="stacked"
            name="email"
            type="email"
            rules={{ required: true }}
            as={IonInput}
          />
          <IonButton
            shape="round"
            expand="block"
            size="large"
            type="submit"
            disabled={!formState.isValid}
            className="ion-margin-top"
          >
            {loading ? <IonSpinner name="crescent" /> : "Submit"}
          </IonButton>
        </form>
        <OurBrainButton
          color="secondary"
          fill="clear"
          expand="block"
          size="large"
          link="/"
          className="ion-margin-top"
        >
          Back
        </OurBrainButton>
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    user: state.auth.user, // CHANGE
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...resetActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Start);
