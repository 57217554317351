import { IonButton, IonModal, IonPage, IonText } from "@ionic/react";
import {
  albumsOutline,
  codeWorking,
  cogOutline,
  peopleOutline,
  sendOutline,
} from "ionicons/icons";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import {
  ActionMenuItem,
  IActionMenuItem,
  OurBrainContent,
  OurBrainLogo,
  TabBar,
} from "../../../../components";
import NavBar from "../../../../components/NavBar";
import OurBrainInformation, {
  IInformation,
} from "../../../../components/OurBrainInformation";
import { actions as authActions } from "../../../Auth/redux";
import { IAuthActions } from "../../../Auth/redux/actions";
import { IAuthState } from "../../../Auth/redux/reducer";
import { actions as partnershipActions } from "../../../Partnerships/redux";
import { IPartnershipActions } from "../../../Partnerships/redux/actions";
import { IPartnershipDefaultState } from "../../../Partnerships/redux/reducer";
import { actions as globalActions } from "../../../reduxGlobal";
import { IGlobalActions } from "../../../reduxGlobal/actions";
import styles from "./index.module.scss";

type HomeProps = {
  actions: IAuthActions;
  auth: IAuthState;
  partnership: IPartnershipDefaultState;
};

const homeSections: IActionMenuItem[] = [
  {
    icon: peopleOutline,
    title: "Partnerships",
    color: "primary",
    link: "/app/partnership",
  },
  {
    icon: albumsOutline,
    title: "Hub",
    color: "secondary",
    link: "/app/hub?type=all",
  },
  {
    icon: sendOutline,
    title: "Referrals",
    color: "tertiary",
    link: "/app/referrals",
  },
  {
    icon: cogOutline,
    title: "Services",
    color: "medium",
    link: "/app/services",
  },

  { title: "Help", color: "success", link: "/app/help" },
  {
    icon: codeWorking,
    title: "Admin",
    color: "light",
    link: "/admin",
    allowedRoles: ["admin"],
  },
];

const HomePopup: FC<any> = ({
  auth,
  actions,
}: {
  auth: IAuthState;
  actions: IPartnershipActions & IAuthActions & IGlobalActions;
}) => {
  const question: string | undefined = auth.notification?.homePopup?.question;
  const homePopupInformation: IInformation = {
    title: "Question",
    paragraphs: [question!],
  };

  function handleYes() {
    gtag("event", "question", {
      question,
      answer: "yes",
    });
    actions.setAuthState({ notification: undefined });
  }
  function handleNo() {
    gtag("event", "question", {
      question,
      answer: "no",
    });
    actions.setAuthState({ notification: undefined });
  }
  return (
    <IonModal
      isOpen={typeof question !== "undefined"}
      // isOpen={true}
      onDidDismiss={() => actions.setAuthState({ notification: null })}
    >
      <OurBrainContent sizeMd="11" className={styles.home__popup}>
        <OurBrainInformation information={homePopupInformation} />
        <div className={styles.home__popup__buttons}>
          <IonButton
            expand="block"
            size="large"
            fill="outline"
            color="secondary"
            onClick={handleNo}
          >
            No
          </IonButton>
          <IonButton
            expand="block"
            size="large"
            fill="solid"
            onClick={handleYes}
          >
            Yes
          </IonButton>
        </div>
      </OurBrainContent>
    </IonModal>
  );
};

const HomeDashboard: FC<HomeProps> = ({ actions, auth, partnership }) => {
  const homeTitle: IInformation = {
    title: `Welcome ${auth.user?.username}`,
  };
  const { push } = useHistory();
  const { user } = auth;
  useEffect(() => {
    if (!user?.config.completedSignUp) {
      push("/app/welcome");
    }
  }, [auth.user, push]);

  const dividerContent = () => {
    const { userPartnerships, partnershipsToAction } = partnership;
    const activePartnershipsCount = userPartnerships!.length;
    const requiredActionsCount = partnershipsToAction.length;
    return (
      <div className={styles.dividerContent}>
        <div className={styles.dividerNotification}>
          <p> Active partnerships:</p>
          <p> {activePartnershipsCount}</p>
        </div>
        <div className={styles.dividerNotification}>
          <p> Check-ins required:</p>
          <IonText>
            <p> {requiredActionsCount}</p>
          </IonText>
        </div>
      </div>
    );
  };

  homeSections[0]["dividerContent"] = dividerContent();
  return (
    <IonPage>
      <NavBar actions={actions} title="Home" reload />
      <OurBrainContent>
        <HomePopup auth={auth} actions={actions} />
        <div className={styles.avatarContainer}>
          <OurBrainLogo width="80px" />
          <OurBrainInformation center information={homeTitle} />
        </div>
        {homeSections.map((section: any) => (
          <ActionMenuItem
            key={section.title}
            section={section}
            isAdmin={auth?.isAdmin || false}
            role={auth.role}
          />
        ))}
      </OurBrainContent>
      <TabBar />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...partnershipActions, ...authActions, ...globalActions },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeDashboard);
