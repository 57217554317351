import { IonIcon, IonRow, IonText } from "@ionic/react";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";
import { play } from "ionicons/icons";
import moment from "moment";
import React, { FC, memo } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import { animated, useSpring } from "react-spring";
import {
  IPartnershipMessageModel,
  PartnershipMessageType,
} from "../../../../interfaces/interfaces";
import styles from "./index.module.scss";

type MessageBoxProps = {
  message: IPartnershipMessageModel;
  activeUser: any;
  key: any;
  actions: any;
  selectedMessageId?: string;
  audioSrc?: string;
};

const getTimestamp = (createdAt: Date) => {
  return moment(createdAt).format("LT");
};

const VoiceMessagePlayer = (props: any): any => (
  <AudioPlayer
    src={props.audioSrc}
    showSkipControls={false}
    showJumpControls={false}
    customAdditionalControls={[]}
    customVolumeControls={[]}
    layout="horizontal-reverse"
    customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
    customIcons={{ play: <IonIcon size="small" icon={play} /> }}
  />
);

const MessageBox: FC<MessageBoxProps> = ({
  message,
  activeUser,
  actions: { setSelectedMessage },
  selectedMessageId,
  audioSrc,
}) => {
  const messageAlignment: string =
    message.userId === activeUser._id
      ? styles.rightJustify
      : styles.leftJustify;
  const props = useSpring({
    opacity: selectedMessageId === message._id ? 1 : 0,
  });
  return (
    <div className={cn(styles.messageBoxContainer, messageAlignment)}>
      <Grid>
        <IonRow>
          {message.type === PartnershipMessageType.SMS && (
            <p
              className={cn(styles.messageBox)}
              onClick={() => {
                selectedMessageId === message._id
                  ? setSelectedMessage("")
                  : setSelectedMessage(message._id);
              }}
            >
              {message.message}
            </p>
          )}
          {message.type === PartnershipMessageType.VMS && (
            <VoiceMessagePlayer audioSrc={audioSrc} />
          )}
        </IonRow>
        <IonRow>
          <animated.div style={props}>
            <IonText
              hidden={selectedMessageId !== message._id}
              color="medium"
              className="ion-margin-horizontal"
            >
              <small>{getTimestamp(message.createdAt)}</small>
            </IonText>
          </animated.div>
        </IonRow>
      </Grid>
    </div>
  );
};

export default memo(MessageBox);
