import { IonCol, IonGrid, IonHeader, IonRow, IonText } from "@ionic/react";
import React, { FC, memo } from "react";
import { OurBrainLogo } from "../index";
import styles from "./index.module.scss";

type OurBrainHeaderProps = {
  sizeMd?: string;
};
const OurBrainHeader: FC<OurBrainHeaderProps> = (props) => {
  const { sizeMd } = props;
  return (
    <IonHeader className={styles.header__container}>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" sizeMd={sizeMd || "5"}>
            <div className={styles.ourBrainTItleContainer}>
              <IonText color="primary">
                <h1>ourBrain</h1>
              </IonText>
              <OurBrainLogo width="60px" />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonHeader>
  );
};

export default memo(OurBrainHeader);
