import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import ourBrainApi from "../../../api/createApiService";
import { ForbiddenError, UnauthorizedError } from "../../../api/errors";
import { createAction } from "../../../rootRedux/rootActions";
import { PROCESS_LOGOUT_START } from "../../Auth/redux/constants";
import {
  CREATE_POST_FAIL,
  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  GET_FEED_FAIL,
  GET_FEED_START,
  GET_FEED_SUCCESS,
  SAVE_POST_FAIL,
  SAVE_POST_START,
  SAVE_POST_SUCCESS,
} from "./constants";

function* getFeed({ payload }: AnyAction): Generator {
  const { type, query } = payload;
  try {
    const response = yield call([ourBrainApi, "get"], `/hub/feed${query}`);
    yield put(createAction(GET_FEED_SUCCESS, response));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(GET_FEED_FAIL, error.response));
  }
}

function* createPost({ payload }: AnyAction): Generator {
  const { type, data } = payload;
  try {
    const response: any = yield call(
      [ourBrainApi, "post"],
      `/hub/${type}`,
      data
    );
    yield put(
      createAction(CREATE_POST_SUCCESS, {
        ...response,
        showSuccess: "Post created successfully",
      })
    );
    gtag("event", "create_post", {
      type,
    });
    yield put(push("/app/hub?type=all&limit=10"));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(CREATE_POST_FAIL, { showError: error.message }));
  }
}

function* savePost({ payload }: AnyAction): Generator {
  const { data } = payload;
  try {
    const response = yield call([ourBrainApi, "post"], `/hub/save`, data);
    yield put(createAction(SAVE_POST_SUCCESS, response));
    gtag("event", "save_post");
    yield put(push("/app/hub?type=all&limit=10"));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(SAVE_POST_FAIL, { showError: error.message }));
  }
}

export default [
  takeLatest(GET_FEED_START, getFeed),
  takeLatest(CREATE_POST_START, createPost),
  takeLatest(SAVE_POST_START, savePost),
];
