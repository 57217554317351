import {
  PROCESS_SEND_REFERRAL_FAIL,
  PROCESS_SEND_REFERRAL_START,
  PROCESS_SEND_REFERRAL_SUCCESS,
} from "./constants";

export interface IHomeState {
  loading: boolean;
}

const defaultState: IHomeState = {
  loading: false,
};

function reducer(state = defaultState, action: any) {
  const { type } = action as any;
  switch (type) {
    case PROCESS_SEND_REFERRAL_START:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_SEND_REFERRAL_SUCCESS:
      return {
        ...defaultState,
      };
    case PROCESS_SEND_REFERRAL_FAIL:
      return {
        ...defaultState,
      };
    default:
      return state;
  }
}

export default reducer;
