import { IonPage } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import React, { FC } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainButton, OurBrainContent } from "../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import partnershipActions, {
  IPartnershipActions,
} from "../../../redux/actions";
import { IPartnershipDefaultState } from "../../../redux/reducer";
import styles from "./index.module.scss";

const partnershipDisclaimerInformation: IInformation = {
  title: "Information",
  paragraphs: [
    "We always aim to make sure your partnership is as accurate as possible. However, we cannot guarantee that all 3 category requests will always be available at this time.",
    "Once ourBrain has found your partner, we will notify you by email so you can begin your conversation. The platform uses 'daily check-ins' between users.",
    "At this early stage, we can only process one check-in per day. View this as a chance to unwind in a longer, more thoughtful message, growing your partnership day by day.",
  ],
  bullets: [0, 1, 2],
  icon: chevronDown,
};

type PartnershipDisclaimerProps = {
  actions: IPartnershipActions;
  partnership: IPartnershipDefaultState;
};
const PartnershipDisclaimer: FC<PartnershipDisclaimerProps> = (props) => {
  return (
    <IonPage>
      <OurBrainContent className={styles.partnershipBeginContainer}>
        <OurBrainInformation information={partnershipDisclaimerInformation} />
        <OurBrainButton
          color="secondary"
          fill="solid"
          expand="block"
          link={`/app/partnership/thank-you`}
        >
          Understood
        </OurBrainButton>
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions }, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipDisclaimer);
