import axios, { AxiosInstance, AxiosResponse } from "axios";
import { API_BASE_URL } from "../config/env";
import {
  BadRequestError,
  ForbiddenError,
  NotFound,
  UnauthorizedError,
} from "./errors";

const server: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 1000 * 60,
  withCredentials: true,
  timeoutErrorMessage: "Error: Network timeout",
  headers: {
    Accept: "application/json",
    "Referrer-Policy": "no-referrer-when-downgrade",
    SameSite: "None",
  },
});

const CreateApiService = () => {
  const get = async (
    url: string,
    headers?: any
  ): Promise<AxiosResponse["data"]> => {
    let res: AxiosResponse;
    try {
      res = await server.get(url, { headers });
    } catch (error) {
      if (error.response.status === 401) {
        throw new UnauthorizedError(error);
      }
      if (error.response.status === 403) {
        throw new ForbiddenError(error);
      }
      if (error.response.status === 404) {
        throw new NotFound(error);
      }
      throw new Error(error.response);
    }
    return res.data;
  };
  const post = async (
    url: string,
    data: any,
    headers?: any
  ): Promise<AxiosResponse["data"]> => {
    let res: AxiosResponse;
    try {
      res = await server.post(url, data, { headers });
    } catch (error) {
      if (error.response.status === 401) {
        throw new UnauthorizedError(error.response.data.message);
      }
      if (error.response.status === 403) {
        throw new ForbiddenError(error.response.data.message);
      }
      if (error.response.status === 404) {
        throw new NotFound(error);
      }
      if (error.response.status === 400) {
        throw new BadRequestError(error.response.data.message);
      }
      throw new Error(error.response);
    }
    return res.data;
  };

  return {
    get,
    post,
  };
};

const ourBrainApi = CreateApiService();

export default ourBrainApi;
