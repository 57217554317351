import { IonCol, IonContent, IonGrid, IonRow } from "@ionic/react";
import React, { FC } from "react";

interface OurBrainContentProps {
  className?: any;
  hidden?: boolean;
  ref?: any;
  sizeMd?: string;
  setRef?: any;
}

const OurBrainContent: FC<OurBrainContentProps> = React.forwardRef(
  ({ className, children, hidden, sizeMd, setRef }, ref: any) => {
    return (
      <IonContent className={className} hidden={hidden} ref={ref}>
        <div ref={ref}>
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol
                size="12"
                sizeMd={sizeMd || "5"}
                className="ion-justify-content-center"
              >
                {children}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    );
  }
);

export default OurBrainContent;
