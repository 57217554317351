/* eslint-disable */
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonRow,
  IonSpinner,
  IonText,
  IonTextarea,
} from "@ionic/react";
import cn from "classnames";
import { delay } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  IPartnershipMessageModel,
  IUserModel,
  PartnershipMessageType,
} from "../../../../../../../interfaces/interfaces";
import { socket } from "../../../../../../../socket/initializeSocket";
import MessageBox from "../../../../../components/MessageBox";
import { IPartnershipActions } from "../../../../../redux/actions";
import { IPartnershipDefaultState } from "../../../../../redux/reducer";
import styles from "../../../index.module.scss";
import PartnershipChatTalkingPoint from "../PartnershipChatTalkingPoint";

type PartnershipChatProps = {
  partnership: IPartnershipDefaultState;
  actions: IPartnershipActions;
  activeUser: IUserModel;
};

const PartnershipChat: FC<PartnershipChatProps> = (props) => {
  const { actions, partnership, activeUser } = props;
  const {
    loading,
    messages,
    disableScroll,
    messageLimit,
    selectedPartnership,
  } = partnership;
  const partnershipId = selectedPartnership!._id;
  const { loadPartnershipMessagesStart } = actions;

  const [, setLoadingNewMessages] = useState(false);
  const [showTalkingPoint] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [, setTyping] = useState(false);

  const textRef: any = useRef();
  const contentRef: any = useRef();

  const scrollToBottom = useCallback(() => {
    delay(async () => await contentRef.current.scrollToBottom(), 100);
  }, []);
  const setKeyboardFocus = useCallback(() => {
    delay(async () => await textRef.current.setFocus(), 100);
  }, []);

  const loadPartnershipMessages = useCallback(() => {
    setLoadingNewMessages(true);
    loadPartnershipMessagesStart({
      partnershipId,
      limit: messageLimit,
      scrollToBottom,
      setKeyboardFocus,
    });
  }, [messageLimit, partnershipId, scrollToBottom, setKeyboardFocus, actions]);

  let typingTimeout: any | undefined;
  useEffect(() => {
    socket.on("UserTyping", () => {
      clearTimeout(typingTimeout);
      typingTimeout = setTimeout(() => setTyping(false), 2000);
      setTyping(true);
    });
    socket.emit("ConnectToRoom", partnershipId);
  }, [partnershipId, actions, scrollToBottom]);

  useEffect(() => {
    loadPartnershipMessages();
    setKeyboardFocus();
    socket.connect();
    socket.on("MessageReceived", async (storedMessage: any) => {
      clearTimeout(typingTimeout);
      setTyping(false);
      actions.processNewMessageStart({ storedMessage, scrollToBottom });
    });
    return () => {
      socket.emit("LeaveRoom", partnershipId);
      socket.removeAllListeners();
      socket.disconnect();
      actions.leaveRoom();
    };

    // if (!voiceMemoDisabled) {
    //     navigator.mediaDevices.getUserMedia({audio: true})
    //         .then(function (stream) {
    //             console.log('You let me use your mic!')
    //         })
    //         .catch(function (err) {
    //             console.log('No mic for you!')
    //         });
    // }
  }, []);

  const handleMessageSubmit = (data: any, type?: string) => {
    if (data.length === 0) {
      return;
    }
    const storedMessage = {
      partnershipId,
      message: data,
      type: type || PartnershipMessageType.SMS,
      userId: activeUser._id,
    };
    setMessageInput("");
    if (typeof partnership.selectedMessageId !== "undefined") {
      actions.setSelectedMessage("");
    }
    socket.emit("MessageSent", storedMessage, partnershipId);
  };
  const handleTyping = (e: React.KeyboardEvent<HTMLIonTextareaElement>) => {
    socket.emit("Typing", partnershipId);
    if (e.key === "Enter") {
      e.preventDefault();
      handleMessageSubmit(messageInput);
    }
  };

  const renderMessages = () => {
    if (loading) {
      return (
        <IonText className="ion-text-center">
          <IonSpinner name={"bubbles"} />
        </IonText>
      );
    }
    if (messages.length === 0) {
      return (
        <IonText className="ion-text-center">
          <h3>No messages yet</h3>
        </IonText>
      );
    }
    return messages?.map((message: IPartnershipMessageModel, i: number) => (
      <MessageBox
        selectedMessageId={partnership.selectedMessageId}
        actions={actions}
        key={i}
        activeUser={activeUser}
        message={message}
      />
    ));
  };
  return (
    <>
      <PartnershipChatTalkingPoint showTalkingPoint={showTalkingPoint} />
      <IonContent
        scrollEvents
        className="ion-justify-content-end"
        ref={contentRef}
      >
        <div
          className={cn(
            styles.messages,
            messages.length === 0 && styles.noMessages
          )}
        >
          <IonButton
            fill="clear"
            hidden={disableScroll || messages.length < 30}
            onClick={() => loadPartnershipMessages()}
          >
            Load more messages
          </IonButton>
          {renderMessages()}
        </div>
      </IonContent>
      <IonFooter>
        <IonGrid>
          {/*<IonRow className="ion-justify-content-end">*/}
          {/*    <IonCol size="5">*/}
          {/*        <PartnershipChatToolbar setShowTalkingPoint={setShowTalkingPoint}*/}
          {/*                                showTalkingPoint={showTalkingPoint}/>*/}
          {/*    </IonCol>*/}
          {/*</IonRow>*/}
          <IonRow className="ion-justify-content-between">
            <IonCol size="9" sizeMd="10" className={styles.textAreaCenter}>
              <IonTextarea
                name="message"
                rows={1}
                placeholder="Type a message..."
                id="message"
                autofocus
                ref={textRef}
                value={messageInput}
                onIonChange={(e: any) => setMessageInput(e.detail.value)}
                onKeyDown={(e) => handleTyping(e)}
                className="ion-text-left"
                autocapitalize="sentences"
              />
            </IonCol>
            <IonCol
              size="3"
              sizeMd="2"
              className="ion-align-items-end ion-justify-content-center"
            >
              <IonButton
                fill="clear"
                className="ion-text-right"
                color={messageInput !== "" ? "primary" : "light"}
                onClick={(e) => {
                  e.preventDefault();
                  textRef.current.setFocus();
                  messageInput !== "" && handleMessageSubmit(messageInput);
                }}
              >
                Send
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  );
};

export default PartnershipChat;
