import { IonButton, IonInput, IonItem, IonLabel, IonList } from "@ionic/react";
import React, { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import OurBrainInformation from "../../../../../components/OurBrainInformation";
import { IUserModel } from "../../../../../interfaces/interfaces";
import { IProfileActions } from "../../../../Profile/redux/actions";
import Avatar from "../../../components/Avatar";
import { IAuthActions } from "../../../redux/actions";
import { IAuthState } from "../../../redux/reducer";

const {
  uniqueNamesGenerator,
  colors,
  animals,
} = require("unique-names-generator");

const avatarBaseUrl: string =
  "https://ourbrain-avatars.s3.eu-west-2.amazonaws.com";
const avatarUrls: string[] = [
  `${avatarBaseUrl}/brain_blue.svg`,
  `${avatarBaseUrl}/brain_floating.svg`,
  `${avatarBaseUrl}/brain_hands.svg`,
  `${avatarBaseUrl}/brain_pink.svg`,
  `${avatarBaseUrl}/brain_plus.svg`,
  `${avatarBaseUrl}/brain_tick.svg`,
  `${avatarBaseUrl}/brain_weights.svg`,
  `${avatarBaseUrl}/brain_yin_yang.svg`,
];

type YourDisplayProps = {
  actions: IAuthActions & IProfileActions;
  activeUser: IUserModel;
  form: any;
  loading: boolean;
  styles: any;
  auth: IAuthState;
  hidden?: boolean;
};

const YourDisplay: FC<YourDisplayProps> = ({
  actions,
  hidden,
  styles,
  form,
}) => {
  const { control, setValue } = form;

  const createUsername = () =>
    uniqueNamesGenerator({
      dictionaries: [colors, animals], // colors can be omitted here as not used
      length: 2,
      style: "capital",
      separator: " ",
    });
  const [shortName, setShortName] = useState(createUsername());
  useEffect(() => setValue("username", ""), [setValue]);

  return (
    <div hidden={hidden}>
      <IonList>
        <OurBrainInformation information={{ title: "Your display" }} />
        <Controller
          name="username"
          control={control}
          as={({ onChange, value }: any) => (
            <IonItem>
              <div className={styles.usernameLabelContainer}>
                <IonLabel position="stacked">Choose a username</IonLabel>
                <IonButton
                  type="button"
                  slot="end"
                  color="tertiary"
                  onClick={() => {
                    setValue("username", shortName);
                    setShortName(createUsername());
                  }}
                >
                  {/*<IonIcon*/}
                  {/*  className="ion-padding-vertical"*/}
                  {/*  icon={refresh}*/}
                  {/*/>*/}
                  <small>Random username!</small>
                </IonButton>
              </div>
              <IonInput
                placeholder="Type here..."
                type="text"
                value={value}
                onIonChange={onChange}
              />
            </IonItem>
          )}
        />
        <Controller
          name="avatarUrl"
          control={control}
          as={({ value }: any) => (
            <IonItem>
              <IonLabel position="stacked">Select an avatar</IonLabel>
              <div className={styles.avatarsContainer}>
                {avatarUrls.map((srcUrl) => (
                  <Avatar
                    key={srcUrl}
                    src={srcUrl}
                    current={value}
                    onClick={() => {
                      actions.setAuthState({
                        currentPhotoSelect: srcUrl,
                      });
                      setValue("avatarUrl", srcUrl);
                    }}
                  />
                ))}
              </div>
            </IonItem>
          )}
        />
        {/*<div>*/}
        {/*    <ImageUploading*/}
        {/*        onChange={handlePhotoUpload}*/}
        {/*        onError={handlePhotoUpload}*/}
        {/*        maxNumber={1}*/}
        {/*        multiple*/}
        {/*        maxFileSize={10000000}*/}
        {/*        acceptType={["jpg", "gif", "png", "jpeg"]}*/}
        {/*    >*/}
        {/*        {({onImageUpload}: any) => (*/}
        {/*            <div onClick={onImageUpload}>*/}
        {/*                <IonButton fill="clear" expand="block">Upload*/}
        {/*                    a photo</IonButton>*/}
        {/*            </div>*/}
        {/*        )}*/}
        {/*    </ImageUploading>*/}

        {/*    <div className={styles.selectedAvatar}>*/}
        {/*        {loading && <IonSpinner/>}*/}
        {/*        {!loading && <Avatar*/}
        {/*            src={auth.currentPhotoSelect}/>}*/}
        {/*    </div>*/}
        {/*</div>*/}
      </IonList>
    </div>
  );
};

export default YourDisplay;
