import { IonToast } from "@ionic/react";
import React, { FC } from "react";

type NotificationProps = {
  position: "top" | "bottom";
  color: string;
  message?: string;
  isOpen: boolean;
  actions?: any;
  onDidDismiss?: any;
};

const Notification: FC<NotificationProps> = ({
  message,
  onDidDismiss,
  position,
  color,
  isOpen = false,
}) => {
  return (
    <IonToast
      isOpen={isOpen}
      position={position}
      color={color}
      message={message}
      duration={2000}
      onDidDismiss={onDidDismiss}
    />
  );
};

export default Notification;
