import { SagaIterator } from "redux-saga";
import { all } from "redux-saga/effects";
import { sagas as adminSagas } from "../modules/Admin/redux";
import { sagas as authSagas } from "../modules/Auth/redux";
import { sagas as homeSagas } from "../modules/Home/redux";
import { sagas as hubSagas } from "../modules/Hub/redux";
import { sagas as partnershipSagas } from "../modules/Partnerships/redux";
import { sagas as profileSagas } from "../modules/Profile/redux";
import { sagas as generalSagas } from "../modules/reduxGlobal";

export default function* rootSaga(): SagaIterator {
  yield all([
    ...partnershipSagas,
    ...authSagas,
    ...profileSagas,
    ...adminSagas,
    ...generalSagas,
    ...homeSagas,
    ...hubSagas,
  ]);
}
