export const PROCESS_CHANGE_PASSWORD_START: string =
  "PROCESS_CHANGE_PASSWORD_START";
export const PROCESS_CHANGE_PASSWORD_SUCCESS: string =
  "PROCESS_CHANGE_PASSWORD_SUCCESS";
export const PROCESS_CHANGE_PASSWORD_FAIL: string =
  "PROCESS_CHANGE_PASSWORD_FAIL";

export const PROCESS_UPDATE_USER_START: string = "PROCESS_UPDATE_USER_START";
export const PROCESS_UPDATE_USER_SUCCESS: string =
  "PROCESS_UPDATE_USER_SUCCESS";
export const PROCESS_UPDATE_USER_FAIL: string = "PROCESS_UPDATE_USER_FAIL";

export const PROCESS_UPLOAD_PHOTO_START: string = "PROCESS_UPLOAD_PHOTO_START";
export const PROCESS_UPLOAD_PHOTO_SUCCESS: string =
  "PROCESS_UPLOAD_PHOTO_SUCCESS";
export const PROCESS_UPLOAD_PHOTO_FAIL: string = "PROCESS_UPLOAD_PHOTO_FAIL";

export const SHOW_PROFILE_MODAL: string = "SHOW_PROFILE_MODAL";

export const PROFILE_RESET_STATE: string = "PROFILE_RESET_STATE";
