import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import { createRouteReducer } from "./rootReducer";
import rootSaga from "./rootSaga";

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  whitelist: [""],
};

const rootReducer = createRouteReducer(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware: any = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware, routerMiddleware(history))
    )
  );
  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}
