import { connectRouter } from "connected-react-router";
import { combineReducers, Reducer } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as admins } from "../modules/Admin/redux";
import { reducer as auth } from "../modules/Auth/redux";
import { reducer as hub } from "../modules/Hub/redux";
import { reducer as partnership } from "../modules/Partnerships/redux";
import { reducer as profile } from "../modules/Profile/redux";
import { reducer as general } from "../modules/reduxGlobal";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["isAuthenticated", "user"],
};

const partnershipPersistConfig = {
  key: "partnership",
  storage,
  whitelist: [
    "partnershipId",
    "preferences",
    "preferenceQuestions",
    "userPartnerships",
    "selectedPartnership",
    "partnershipsToAction",
  ],
};

const profilePersistConfig = {
  key: "profile",
  storage,
  blacklist: [
    "loading",
    "isModalOpen",
    "userDetails",
    "showSuccess",
    "popupMessage",
    "user",
  ],
};
const adminPersistConfig = {
  key: "admins",
  storage,
  whitelist: [""],
};
const generalPersistConfig = {
  key: "general",
  storage,
  whitelist: [""],
};

const hubPersistConfig = {
  key: "hub",
  storage,
  whitelist: [""],
};

export function createRouteReducer(history: any) {
  return combineReducers({
    partnership: persistReducer(partnershipPersistConfig, partnership),
    auth: persistReducer(authPersistConfig, auth),
    profile: persistReducer(profilePersistConfig, profile),
    admins: persistReducer(adminPersistConfig, admins),
    general: persistReducer(generalPersistConfig, general),
    hub: persistReducer(hubPersistConfig, hub),
    router: connectRouter(history),
  });
}

const rootReducer: Reducer = combineReducers({
  partnership: persistReducer(partnershipPersistConfig, partnership),
  auth: persistReducer(authPersistConfig, auth),
  profile: persistReducer(profilePersistConfig, profile),
  admins: persistReducer(adminPersistConfig, admins),
  general: persistReducer(generalPersistConfig, general),
});

export default rootReducer;
