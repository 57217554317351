import { Moment } from "moment";
import { IModalBase } from "../common";
import { ISendEmailMessage } from "../email";
import { IUserModel } from "../user";

export interface INotification {
  userId: IUserModel["_id"];
  triggerAt: Moment;
  triggered?: boolean;
  type: NotificationType;
  emailMessage?: ISendEmailMessage;
  homePopup?: IHomePopupInformation;
}

export interface INotificationModel extends INotification, IModalBase {}

export enum NotificationType {
  Email = "email",
  HomePopup = "homePopup",
}

export enum EHomePopupInformationType {
  YesNo = "yesNo",
}

export interface IHomePopupInformation {
  question: string;
  type: EHomePopupInformationType;
}
