import {
    PROCESS_CHANGE_PASSWORD_FAIL,
    PROCESS_CHANGE_PASSWORD_START,
    PROCESS_CHANGE_PASSWORD_SUCCESS,
    PROCESS_UPDATE_USER_FAIL,
    PROCESS_UPDATE_USER_START,
    PROCESS_UPDATE_USER_SUCCESS,
    PROCESS_UPLOAD_PHOTO_FAIL,
    PROCESS_UPLOAD_PHOTO_START,
    PROCESS_UPLOAD_PHOTO_SUCCESS,
    PROFILE_RESET_STATE,
    SHOW_PROFILE_MODAL
} from "./constants";

export interface IProfileState {
    loading: boolean
    formKey: string
    isModalOpen: {
        [key: string]: boolean | {
            [key: string]: boolean
        }
    }
}

const defaultState: IProfileState = {
    loading: false,
    formKey: "",
    isModalOpen: {},
}

function reducer(state = defaultState, action: any) {
    const {payload, type} = action as any
    switch (type) {
        case PROCESS_CHANGE_PASSWORD_START:
            return {
                ...state,
                ...payload,
                loading: true,
            }
        case PROCESS_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case PROCESS_CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case PROCESS_UPDATE_USER_START:
            return {
                ...state,
                ...payload,
                loading: true,
                showSuccess: false
            }
        case PROCESS_UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isModalOpen: {
                    details: false
                },
                showSuccess: true,
                popupMessage: `Changes to ${state.formKey} were successful`
            }
        case PROCESS_UPDATE_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case PROCESS_UPLOAD_PHOTO_START:
            return {
                ...state,
                ...payload,
                loading: true,
            }
        case PROCESS_UPLOAD_PHOTO_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case PROCESS_UPLOAD_PHOTO_FAIL:
            return {
                ...state,
                loading: false,
                error: payload
            }

        // SAGALESS
        case SHOW_PROFILE_MODAL:
            return {
                ...state,
                formKey: payload.formKey,
                isModalOpen: {
                    [payload.modal]: !state.isModalOpen[payload.modal]
                }
            }
        case PROFILE_RESET_STATE:
            return {
                ...defaultState
            }

        default:
            return state
    }
}

export default reducer
