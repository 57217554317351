import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonIcon,
  IonPage,
} from "@ionic/react";
import {
  arrowUpCircle,
  checkmarkOutline,
  closeCircle,
  mail,
  refreshCircleOutline,
  warning,
} from "ionicons/icons";
import { Column } from "material-table";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { NavBar } from "../../../../components";
import {
  IPartnershipCheckInModel,
  ModerationResult,
} from "../../../../interfaces/interfaces";
import { actions as partnershipActions } from "../../../Partnerships/redux";
import { IPartnershipActions } from "../../../Partnerships/redux/actions";
import { IPartnershipDefaultState } from "../../../Partnerships/redux/reducer";
import OurBrainTable from "../../components/OurBrainTable";
import SubmitCheckInsModal from "../../components/SubmitCheckInsModal";
import { actions as adminActions } from "../../redux";
import { IAdminActions } from "../../redux/actions";
import { IAdminState, IModeratedCheckIns } from "../../redux/reducer";

type ModerateCheckInsProps = {
  actions: IPartnershipActions & IAdminActions;
  partnership: IPartnershipDefaultState;
  admins: IAdminState;
};

interface IModerationResult {
  decision: ModerationResult;
}

const ModerateCheckIns: FC<ModerateCheckInsProps> = (props) => {
  const { actions, partnership, admins } = props;
  const { partnershipCheckIns } = partnership;
  const { loading, foundUserEmail } = admins;
  const [slideIndex, setSlideIndex] = useState(0);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [formatWith, setFormatWith] = useState("table");
  const [selectedCheckIn, setSelectedCheckIn] = useState<any>([]);
  const [foundPartnershipCheckIns, setFoundPartnershipCheckIns] = useState<
    IPartnershipCheckInModel[]
  >([]);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [slideOptions] = useState({
    initialSlide: 0,
  });

  const [columns] = useState<Column<any>[]>([
    {
      title: "Message",
      field: "message",
      editable: "never",
      cellStyle: {
        minWidth: "400px",
      },
    },
    {
      title: "Decision",
      field: "decision",
      initialEditValue: "pass",
      cellStyle: {
        maxWidth: "50px !important",
      },
      lookup: {
        pending: "Pending",
        pass: "Pass",
        "pass:flag": "Pass & Flag",
        "pass:flag:email": "Pass & Flag & Email",
        "warning:suicide": "Warning - Suicide/self harm",
        "warning:abuse": "Warning - Abuse",
        "warning:advice": "Warning - Medical Advice",
        "warning:antisocial": "Warning - AntiSocial Behaviours",
        "warning:depend": "Warning - Over Dependency",
        "warning:privacy": "Warning - Privacy",
        fail: "Fail",
      },
    },
    { title: "Date submitted", field: "createdAt" },
    // { title: "Resolved", field: "isResolved" },
  ]);

  const [data, setData] = useState<any>([]);

  const slidesRef: any = useRef();

  useEffect(() => {
    actions.loadPartnershipCheckInsStart({ limit: 100 });
  }, [actions]);

  useEffect(() => {
    setFoundPartnershipCheckIns(partnershipCheckIns!);
    const formattedData = partnershipCheckIns?.map(
      ({ message, config, partnershipId, _id, createdAt, userId }) => {
        return {
          message,
          decision: config.decision,
          userId,
          partnershipId,
          partnershipCheckInId: _id,
          createdAt: moment(createdAt).format("HH:mm DD/MM/YY"),
          isResolved: config.isResolved,
        };
      }
    );
    setData(formattedData!);
  }, [partnershipCheckIns]);

  const handleModeration = async (moderationResult: IModerationResult) => {
    const partnershipCheckInId: string = partnershipCheckIns![slideIndex]._id;
    const message: string = partnershipCheckIns![slideIndex].message;
    const userId: string = partnershipCheckIns![slideIndex].userId; // CHANGE
    const partnershipId: string = partnershipCheckIns![slideIndex]
      .partnershipId;
    const moderatedCheckIn: IModeratedCheckIns = {
      partnershipCheckInId,
      message,
      userId, // CHANGE
      partnershipId,
      ...moderationResult,
    };
    actions.addPartnershipCheckInToList({ moderatedCheckIn, slideIndex });
    await slidesRef.current.slideNext();

    isLastSlide && setOpenSummaryModal(true);

    const lastSlide: boolean = await slidesRef.current.isEnd();
    lastSlide && setIsLastSlide(true);
  };

  const handleResolveCheckIns = () => {
    const partnershipCheckInIds: string[] = selectedCheckIn.map(
      ({ partnershipCheckInId }: any) => partnershipCheckInId
    );
    actions.updatePartnershipCheckInStart({
      partnershipCheckInIds,
      document: { "config.isResolved": true },
    });
  };

  return (
    <IonPage>
      <NavBar title="Moderate" backButton backLink="/admin" />
      <IonButtons>
        <IonButton color="primary" onClick={() => setFormatWith("slides")}>
          Slides
        </IonButton>
        <IonButton color="secondary" onClick={() => setFormatWith("table")}>
          Table
        </IonButton>
      </IonButtons>
      <IonContent>
        <OurBrainTable
          title={"Moderate check-ins"}
          columns={columns}
          data={data}
          actions={actions}
          onSelect={(values: any) => setSelectedCheckIn(values)}
        />
        <IonFab vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton>
            <IonIcon icon={arrowUpCircle} />
          </IonFabButton>
          <IonFabList side="end">
            <IonFabButton
              onClick={() =>
                actions.loadPartnershipCheckInsStart({ limit: 100 })
              }
            >
              <IonIcon icon={refreshCircleOutline} />
            </IonFabButton>
            <IonFabButton
              disabled={selectedCheckIn.length === 0}
              onClick={() => {
                actions.getUserEmailStart({
                  userId: selectedCheckIn[0].userId,
                });
              }}
            >
              <IonIcon icon={mail} />
            </IonFabButton>
            <IonFabButton
              disabled={selectedCheckIn.length === 0}
              onClick={handleResolveCheckIns}
            >
              <IonIcon icon={checkmarkOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
      <IonFooter hidden={formatWith === "table"}>
        <IonButtons className="ion-justify-content-evenly ion-padding-vertical">
          <IonButton
            color="danger"
            fill="outline"
            disabled={partnershipCheckIns?.length === 0}
            onClick={() =>
              handleModeration({ decision: ModerationResult.Fail })
            }
          >
            <IonIcon icon={closeCircle} />
          </IonButton>
          <IonButton
            color="warning"
            fill="outline"
            disabled={partnershipCheckIns?.length === 0}
            onClick={() =>
              handleModeration({ decision: ModerationResult.Warning })
            }
          >
            <IonIcon icon={warning} />
          </IonButton>
          <IonButton
            color="success"
            fill="outline"
            disabled={partnershipCheckIns?.length === 0}
            onClick={() =>
              handleModeration({ decision: ModerationResult.Pass })
            }
          >
            <IonIcon icon={checkmarkOutline} />
          </IonButton>
        </IonButtons>
        <IonButton
          color="secondary"
          expand="full"
          onClick={() => setOpenSummaryModal(true)}
          disabled={admins.moderatedCheckInIds?.length === 0}
        >
          Submit
        </IonButton>
      </IonFooter>
      <SubmitCheckInsModal
        openSummaryModal={openSummaryModal}
        setOpenSummaryModal={setOpenSummaryModal}
        actions={actions}
        admins={admins}
      />
      <IonAlert
        onDidDismiss={() => actions.resetState({ foundUserEmail: undefined })}
        isOpen={typeof foundUserEmail !== "undefined"}
        buttons={["Ok"]}
        message={admins.foundUserEmail}
      />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...adminActions, ...partnershipActions },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModerateCheckIns);
