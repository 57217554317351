import { IAction } from "../../../config/common";
import { PROCESS_SEND_REFERRAL_START } from "./constants";

const processSendReferral: any = (payload: string): IAction => {
    return {type: PROCESS_SEND_REFERRAL_START, payload}
}


export default {
    processSendReferral,
}
