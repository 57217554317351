import {
  IonAlert,
  IonAvatar,
  IonBackdrop,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonFooter,
  IonItemDivider,
  IonText,
} from "@ionic/react";
import classNames from "classnames";
import { Push } from "connected-react-router";
import { groupBy } from "lodash";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { OurBrainContent } from "../../../../../../../components";
import OurBrainContainer from "../../../../../../../components/OurBrainContainer";
import OurBrainInformation from "../../../../../../../components/OurBrainInformation";
import {
  IPartnershipCheckInModel,
  IUserModel,
} from "../../../../../../../interfaces/interfaces";
import { IPartnershipActions } from "../../../../../redux/actions";
import { IPartnershipDefaultState } from "../../../../../redux/reducer";
import PartnershipCheckInBox from "../PartnershipCheckInBox";
import PartnershipCheckInMessageModal from "../PartnershipCheckInMessageModal";
import PartnershipCheckInStats from "../PartnershipCheckInStats";
import PartnershipCheckInSubmitModal from "../PartnershipCheckInSubmitModal";
import styles from "./index.module.scss";

type PartnershipCheckInProps = {
  partnership: IPartnershipDefaultState;
  actions: IPartnershipActions & { push: Push };
  activeUser: IUserModel;
};

export interface IOpenModal {
  isOpen: boolean;
  answer?: string;
}

const CheckInUserCard: FC<any> = (props) => {
  const { avatarUrl, username, color } = props;
  return (
    <IonCard className={styles.checkInCard}>
      <IonCardContent>
        <div className={styles.checkInCardContent}>
          <IonAvatar>
            <img src={avatarUrl} alt="avatar" height="30px" />
          </IonAvatar>
          <IonCardSubtitle>{username}</IonCardSubtitle>
        </div>
      </IonCardContent>
      <IonItemDivider color={color} />
    </IonCard>
  );
};

export const TalkingPoint: FC<any> = ({ talkingPoint }) => (
  <div>
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>Current talking point:</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonText className="ion-text-left">
          <h5>
            <i>{talkingPoint}</i>
          </h5>
        </IonText>
      </IonCardContent>
    </IonCard>
  </div>
);

export const NoCheckIn: FC<any> = () => (
  <>
    <span role="img" aria-label="itsokay">
      No check-ins
    </span>
  </>
);

const PartnershipCheckIn: FC<PartnershipCheckInProps> = (props) => {
  const { actions, partnership, activeUser } = props;
  const {
    partnershipCheckIns,
    loading,
    foundTalkingPoint,
    selectedPartnership,
    activeUsersInfo,
    partnerUsersInfo,
  } = partnership;

  const [
    activeUserHasCheckedInToday,
    setActiveUserHasHasCheckedInToday,
  ] = useState(false);
  const [activeUsersCheckIn, setActiveUsersCheckIn] = useState<
    IPartnershipCheckInModel
  >();
  const [partnersCheckIn, setPartnersCheckIn] = useState<
    IPartnershipCheckInModel
  >();
  const [openAnswerModal, setOpenAnswerModal] = useState<IOpenModal>({
    isOpen: false,
    answer: "",
  });
  const [openCheckInModal, setOpenCheckInModal] = useState(false);

  useEffect(() => {
    actions.loadPartnershipCheckInsStart({
      partnershipId: selectedPartnership!._id,
      isDormant: selectedPartnership?.isDormant,
      seenInfo: activeUsersInfo?.seenInfo,
    });

    if (typeof selectedPartnership?.activeTalkingPoint !== "undefined") {
      actions.loadTalkingPointStart({
        activeTalkingPoint: selectedPartnership!.activeTalkingPoint,
        partnershipId: selectedPartnership?._id,
      });
    }
  }, [selectedPartnership]);

  useEffect(() => {
    const groupedCheckIns: any = groupBy(partnershipCheckIns, function ({
      createdAt,
    }) {
      return moment(createdAt).startOf("day").format();
    });

    const today: any = moment().startOf("day").format();
    const todaysCheckIns: IPartnershipCheckInModel[] = groupedCheckIns[today];
    let foundActiveUsersCheckIn: IPartnershipCheckInModel | undefined;
    let foundPartnersCheckIn: IPartnershipCheckInModel | undefined;
    if (typeof todaysCheckIns !== "undefined") {
      todaysCheckIns.map((checkIn): void => {
        if (checkIn.userId === activeUser._id) {
          foundActiveUsersCheckIn = checkIn;
          setActiveUsersCheckIn(checkIn);
          setActiveUserHasHasCheckedInToday(true);
        } else {
          foundPartnersCheckIn = checkIn;
          setPartnersCheckIn(checkIn);
        }
      });
    }

    if (typeof foundActiveUsersCheckIn === "undefined") {
      foundActiveUsersCheckIn = partnershipCheckIns
        ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        .find(({ userId }) => activeUsersInfo?._id === userId);
      setActiveUsersCheckIn(foundActiveUsersCheckIn);
    }
    if (typeof foundPartnersCheckIn === "undefined") {
      foundPartnersCheckIn = partnershipCheckIns
        ?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        .find(({ userId }) => partnerUsersInfo?._id === userId);
      setPartnersCheckIn(foundPartnersCheckIn);
    }
  }, [activeUser._id, activeUsersInfo, partnerUsersInfo, partnershipCheckIns]);

  function handleRequestTalkingPoint() {
    actions.requestTalkingPointStart({
      partnershipId: selectedPartnership!._id,
      talkingPoints: selectedPartnership!.talkingPoints,
      activeTalkingPoint: selectedPartnership?.activeTalkingPoint,
    });
  }

  const isCheckInButtonDisabled = useCallback((): boolean => {
    return activeUserHasCheckedInToday;
  }, [activeUserHasCheckedInToday]);

  const isTalkingPointButtonDisabled = useCallback((): boolean => {
    return moment().isBefore(
      moment(selectedPartnership?.talkingPointRequestedAt).add(1, "day")
    );
  }, [selectedPartnership]);

  // Renders
  const RenderFooterButtons = () => {
    return (
      <div className={styles.footerButtons}>
        <IonButton expand="block" onClick={() => setOpenCheckInModal(true)}>
          Check In
        </IonButton>
        <IonButton
          expand="block"
          color="secondary"
          onClick={handleRequestTalkingPoint}
          disabled={loading || isTalkingPointButtonDisabled()}
        >
          {isTalkingPointButtonDisabled()
            ? `Available every 24 hours`
            : "Request Talking Point"}
        </IonButton>
      </div>
    );
  };
  return (
    <>
      <IonAlert
        isOpen={partnership.showAlert}
        onDidDismiss={() => actions.setPartnershipState({ showAlert: false })}
        header={"Your new talking point"}
        message={loading ? "Loading..." : foundTalkingPoint?.question}
        buttons={["OK"]}
      />
      <OurBrainContent>
        <div>
          <OurBrainInformation
            information={{
              subtitle: `Your partnership with ${partnerUsersInfo?.username}`,
            }}
            center
          />
        </div>
        <IonCard className={styles.checkInUsers}>
          <CheckInUserCard
            avatarUrl={activeUser.avatarUrl}
            username={activeUser.username}
            color="primary"
          />
          <CheckInUserCard
            avatarUrl={partnerUsersInfo?.avatarUrl}
            username={partnerUsersInfo?.username}
            color="secondary"
          />
        </IonCard>
        <TalkingPoint
          talkingPoint={
            foundTalkingPoint?.question ||
            "Why don’t you tell us a little bit about yourself and why you’re here?"
          }
        />
        <IonCard className={classNames(styles.partnershipCheckInContainer)}>
          <div className={styles.partnershipCheckInContainerInner}>
            <IonCardHeader>
              <IonCardSubtitle>Your check-ins:</IonCardSubtitle>
            </IonCardHeader>
            <div className={styles.partnershipCheckInContainerContent}>
              {typeof partnershipCheckIns !== "undefined" &&
                partnershipCheckIns
                  .sort(
                    (
                      a: IPartnershipCheckInModel,
                      b: IPartnershipCheckInModel
                    ): any => (a.createdAt > b.createdAt ? -1 : 1)
                  )
                  .map((checkIn: IPartnershipCheckInModel, index: number) => {
                    let hideDate: boolean = false;
                    if (index > 0) {
                      const lastCheckIn = moment(
                        partnershipCheckIns[index - 1].createdAt
                      );
                      hideDate =
                        lastCheckIn.format("DD") ===
                        moment(checkIn.createdAt).format("DD");
                    }
                    return (
                      <div key={index}>
                        <div hidden={hideDate} className={styles.checkInDate}>
                          <p>
                            {moment(checkIn.createdAt).format("DD/MM/yyyy")}
                          </p>
                        </div>
                        <PartnershipCheckInBox
                          partnershipCheckIn={checkIn}
                          activeUser={activeUser}
                          setOpenModal={setOpenAnswerModal}
                        />
                      </div>
                    );
                  })}
            </div>
          </div>
        </IonCard>

        <div>
          <PartnershipCheckInStats
            partnership={selectedPartnership!}
            partnershipCheckins={partnershipCheckIns!}
          />
        </div>
        <IonBackdrop hidden={!openCheckInModal} />
      </OurBrainContent>

      <IonFooter className={styles.checkInFooter}>
        <OurBrainContainer>{RenderFooterButtons()}</OurBrainContainer>
      </IonFooter>

      <PartnershipCheckInMessageModal
        openAnswerModal={openAnswerModal}
        setOpenAnswerModal={setOpenAnswerModal}
      />
      {openCheckInModal && (
        <PartnershipCheckInSubmitModal
          activeUsersCheckIn={activeUsersCheckIn}
          partnersCheckIn={partnersCheckIn}
          openCheckInModal={openCheckInModal}
          setOpenCheckInModal={setOpenCheckInModal}
          actions={actions}
          selectedPartnership={selectedPartnership}
          activeUser={activeUser}
          talkingPoint={
            foundTalkingPoint?.question ||
            "Why don’t you tell us a little bit about yourself and why you’re here?"
          }
          partnershipState={partnership}
          isCheckInButtonDisabled={isCheckInButtonDisabled}
          loading={loading!}
        />
      )}
    </>
  );
};
export default PartnershipCheckIn;
