import {
  IonAlert,
  IonButton,
  IonFooter,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from "@ionic/react";
import {
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
} from "ionicons/icons";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Controller } from "react-hook-form";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import { IQuestionModel } from "../../../../../interfaces/interfaces";
import { IPartnershipActions } from "../../../redux/actions";
import { IPartnershipDefaultState } from "../../../redux/reducer";
import styles from "./index.module.scss";

type WarningAlertProps = {
  showResetWarning: boolean;
  showCancelWarning: boolean;
  setShopResetWarning: Dispatch<SetStateAction<boolean>>;
  setShowCancelWarning: Dispatch<SetStateAction<boolean>>;
  push: any;
};
const WarningAlert: FC<WarningAlertProps> = (props) => {
  const {
    showResetWarning,
    showCancelWarning,
    setShopResetWarning,
    setShowCancelWarning,
    push,
  } = props;
  return (
    <IonAlert
      isOpen={showResetWarning || showCancelWarning}
      onDidDismiss={() => {
        setShopResetWarning(false);
        setShowCancelWarning(false);
      }}
      header={"Are you sure?"}
      message={"Your answers will not be saved."}
      buttons={[
        { text: "Cancel", role: "cancel" },
        {
          text: "I'm sure",
          handler: () => {
            if (showCancelWarning) push("/app/partnership");
            if (showResetWarning) push("/app/partnership/begin");
          },
        },
      ]}
    />
  );
};

type SubmitButtonsProps = {
  partnershipDisclaimerInformation: IInformation;
  errors: any;
  reset: any;
  defaultValues: any;
  partnership: IPartnershipDefaultState;
  actions: IPartnershipActions;
  push: any;
};
export const SubmitButtons: FC<SubmitButtonsProps> = (props) => {
  const { partnershipDisclaimerInformation, errors, partnership, push } = props;

  const [showResetWarning, setShowResetWarning] = useState(false);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  return (
    <div className={styles.slideContent}>
      <WarningAlert
        showResetWarning={showResetWarning}
        showCancelWarning={showCancelWarning}
        setShopResetWarning={setShowResetWarning}
        setShowCancelWarning={setShowCancelWarning}
        push={push}
      />
      <div className={styles.submitButtons}>
        <OurBrainInformation information={partnershipDisclaimerInformation} />
        <IonButton
          className="ion-margin-top"
          color={Object.keys(errors).length === 0 ? "primary" : "danger"}
          fill="solid"
          expand="block"
          type="submit"
          disabled={partnership.loading}
        >
          Submit
        </IonButton>
        {Object.keys(errors).length !== 0 && (
          <IonText color="danger">
            <small>Please answer all questions.</small>
          </IonText>
        )}
        <IonButton
          color="secondary"
          fill="solid"
          expand="block"
          onClick={() => setShowResetWarning(true)}
        >
          Reset
        </IonButton>
        <IonButton
          color="medium"
          fill="outline"
          expand="block"
          type="button"
          onClick={() => setShowCancelWarning(true)}
        >
          Cancel
        </IonButton>
      </div>
    </div>
  );
};

type FooterButtonsProps = {
  slidesRef: any;
};
export const FooterButtons: FC<FooterButtonsProps> = (props) => {
  const { slidesRef } = props;
  return (
    <IonFooter className={styles.footer}>
      <IonButton
        color="secondary"
        size="default"
        onClick={async () => slidesRef.current.slidePrev()}
      >
        <IonIcon icon={arrowBackCircleOutline} size="large" />
      </IonButton>
      <IonButton
        color="primary"
        size="default"
        onClick={async () => slidesRef.current.slideNext()}
      >
        <IonIcon icon={arrowForwardCircleOutline} size="large" />
      </IonButton>
    </IonFooter>
  );
};

type TextBoxProps = {
  _id: any;
  control: any;
  errors: any;
  question: string;
};

export const TextBox: FC<TextBoxProps> = (props) => {
  const { _id, control, errors, question } = props;
  return (
    <Controller
      name={_id}
      control={control}
      errors={errors}
      rules={{ required: true }}
      as={({ onChange, value }) => (
        <div>
          <p className={styles.label}>{question}</p>
          <IonTextarea
            name={_id}
            rows={6}
            placeholder="Type here..."
            id="message"
            maxlength={200}
            debounce={200}
            onIonChange={onChange}
            className="ion-text-left"
            value={value}
            autocapitalize="sentences"
          />
          <small>{value?.length ? value.length : 0}/200</small>
        </div>
      )}
    />
  );
};

type SelectBoxProps = {
  _id: string;
  control: any;
  errors: any;
  question: string;
  range: any[];
  options: string[];
  or?: string;
  orQuestionSelected?: string;
  actions: IPartnershipActions;
};

export const SelectBox: FC<SelectBoxProps> = (props) => {
  const {
    _id,
    control,
    errors,
    question,
    range,
    options,
    or,
    orQuestionSelected,
    actions,
  } = props;

  const renderOptions = (options: IQuestionModel["options"]) => {
    if (typeof options === "undefined") {
      return;
    }
    return options.map((option: string) => {
      const { value, question, extraInfo } = getValueAndQuestion(option);
      return (
        <IonSelectOption
          className={styles.selectOption}
          key={option}
          slot="end"
          value={value}
          draggable
        >
          {question} {extraInfo}
        </IonSelectOption>
      );
    });
  };
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getValueAndQuestion = (
    option: string
  ): { value: string; question: string; extraInfo?: string } => {
    if (option.includes("::")) {
      const splitOption = option.split("::");
      return {
        value: splitOption[0],
        question: capitalizeFirstLetter(splitOption[1]),
      };
    }
    if (option.includes("|")) {
      const splitOption = option.split("|");
      return {
        value: splitOption[0],
        question: capitalizeFirstLetter(splitOption[0]),
        extraInfo: splitOption[1],
      };
    }
    return { value: option, question: capitalizeFirstLetter(option) };
  };

  function handleOrSelection(e: any, onChange: any) {
    onChange(e);
    if (typeof or !== "undefined") {
      actions.setPartnershipState({ orQuestionSelected: or });
    }
  }

  return (
    <Controller
      name={_id}
      control={control}
      errors={errors}
      rules={{ required: typeof or === "undefined" }}
      as={({ onChange, value }) => (
        <div className={styles.slideQuestion}>
          <p className={styles.label}>{question}</p>
          <IonSelect
            title={question}
            placeholder="Click here to select..."
            multiple={range!.length > 0}
            disabled={orQuestionSelected === _id}
            onIonChange={(e) => handleOrSelection(e, onChange)}
            value={value}
          >
            {renderOptions(options)}
          </IonSelect>
        </div>
      )}
    />
  );
};
