import { IonAvatar, IonImg } from "@ionic/react";
import classNames from "classnames";
import React, { FC, memo } from "react";
import styles from "../../pages/Welcome/index.module.scss";

const Avatar: FC<any> = ({ src, alt, onClick, current }) => {
  const isClicked: boolean = current === src;
  return (
    <IonAvatar
      onClick={onClick}
      className={classNames(
        isClicked ? styles.isClicked : styles.notClicked,
        "ion-justify-content-center ion-margin-vertical ion-text-center"
      )}
    >
      <IonImg src={src} alt={alt} />
    </IonAvatar>
  );
};

export default memo(Avatar);
