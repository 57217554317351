export const GET_FEED_START: string = "GET_FEED_START";
export const GET_FEED_SUCCESS: string = "GET_FEED_SUCCESS";
export const GET_FEED_FAIL: string = "GET_FEED_FAIL";

export const CREATE_POST_START: string = "CREATE_POST_START";
export const CREATE_POST_SUCCESS: string = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAIL: string = "CREATE_POST_FAIL";

export const SAVE_POST_START: string = "SAVE_POST_START";
export const SAVE_POST_SUCCESS: string = "SAVE_POST_SUCCESS";
export const SAVE_POST_FAIL: string = "SAVE_POST_FAIL";

export const SET_HUB_STATE: string = "SET_HUB_STATE";
