import { IonFooter, IonIcon, IonLabel } from "@ionic/react";
import { cogOutline, home, people, settings } from "ionicons/icons";
import React, { FC } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { actions as authActions } from "../../modules/Auth/redux";
import styles from "./index.module.scss";

type TabProps = {
  value: string;
  icon: string;
  link: string;
};
const Tab: FC<TabProps> = ({ value, icon, link }) => {
  const { location, push } = useHistory();
  const handleOnClick = (e: any) => {
    e.preventDefault();
    if (location.pathname === link) {
      return;
    }
    push(link);
  };
  return (
    <div className={styles.tab} onClick={(e) => handleOnClick(e)}>
      <IonIcon
        icon={icon}
        size="large"
        color={location.pathname === link ? "primary" : "medium"}
      />
      <IonLabel>
        <small>{value}</small>
      </IonLabel>
    </div>
  );
};

const TabBar: React.FC = () => {
  return (
    <IonFooter id="tab-bar" className={styles.tabBarContainer}>
      <Tab value="Home" icon={home} link="/app" />
      <Tab value="Partnerships" icon={people} link="/app/partnership" />
      <Tab value="Services" icon={cogOutline} link="/app/services" />
      <Tab value="Profile" icon={settings} link="/app/profile" />
    </IonFooter>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TabBar);
