import { IonButton } from "@ionic/react";
import React, { FC } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { NavBar, OurBrainContent } from "../../../../../../../components";
import OurBrainForm from "../../../../../../../components/OurBrainForm";
import partnershipActions, {
  IPartnershipActions,
} from "../../../../../redux/actions";

type PartnershipRateProps = {
  partnershipId: string;
  actions: IPartnershipActions;
};
const Rate: FC<PartnershipRateProps> = (props) => {
  const { partnershipId, actions } = props;
  return (
    <>
      <NavBar title="Rating" />
      <OurBrainContent sizeMd="11">
        <OurBrainForm
          noBack
          formKey={process.env.REACT_APP_BRAIN_FORM_RATE!}
          partnershipId={partnershipId}
        />
        <IonButton
          color="secondary"
          expand="block"
          type="button"
          fill="clear"
          onClick={() => actions.setPartnershipState({ showModal: "" })}
        >
          Back
        </IonButton>
      </OurBrainContent>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
    partnershipId: state.partnership.selectedPartnership._id,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rate);
