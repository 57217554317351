import {
  IonAlert,
  IonButton,
  IonItem,
  IonList,
  IonTextarea,
} from "@ionic/react";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { NavBar, OurBrainContent } from "../../../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../../../components/OurBrainInformation";
import partnershipActions, {
  IPartnershipActions,
} from "../../../../../redux/actions";
import { IPartnershipDefaultState } from "../../../../../redux/reducer";
import styles from "./index.module.scss";

const reportInformation: IInformation = {
  title: "Report",
  paragraphs: [
    "We’re sorry you wish to report this partnership. Thank you for bringing it to our attention. Can you please explain the reason and details?",
  ],
};

type PartnershipReportProps = {
  actions: IPartnershipActions;
  partnership: IPartnershipDefaultState;
};
const Report: FC<PartnershipReportProps> = (props) => {
  const { actions, partnership } = props;
  const { push } = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const { control, register, getValues } = useForm({
    defaultValues: {
      userReport: "",
      partnershipId: partnership.selectedPartnership?._id,
    },
  });
  const handleSubmitReport = () => {
    let values = getValues();
    values.partnershipId = partnership.selectedPartnership?._id;
    actions.reportPartnershipStart(values);
    push("/app/partnership");
  };

  return (
    <>
      <NavBar title="Report" />
      <OurBrainContent
        sizeMd="11"
        className={styles.partnershipReportContainer}
      >
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={"Report partnership"}
          subHeader={"Are you sure you wish to continue?"}
          message={
            "This partnership will become dormant. Your partner will not be notified unless our team believe it is necessary."
          }
          buttons={[
            { text: "Cancel", role: "cancel" },
            { text: "Yes", handler: handleSubmitReport },
          ]}
        />
        <OurBrainInformation information={reportInformation} />
        <IonList>
          <form>
            <Controller
              name="userReport"
              control={control}
              as={({ onChange, value }: any) => (
                <IonItem>
                  <IonTextarea
                    name="userReport"
                    rows={5}
                    placeholder="Type here..."
                    id="userReport"
                    onIonChange={onChange}
                    value={value}
                    debounce={1000}
                    className="ion-text-left"
                    autocapitalize="sentences"
                  />
                </IonItem>
              )}
            />
            <IonButton
              color="tertiary"
              expand="block"
              type="button"
              onClick={() => setShowAlert(true)}
            >
              Report
            </IonButton>
            <IonButton
              color="secondary"
              expand="block"
              type="button"
              fill="clear"
              onClick={() => actions.setPartnershipState({ showModal: "" })}
            >
              Back
            </IonButton>
          </form>
        </IonList>
      </OurBrainContent>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Report);
