import React, { FC } from 'react'
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import OurBrainInformation, { IInformation } from "../../../../../components/OurBrainInformation";
import { caretDownCircleOutline } from "ionicons/icons";


const registeredInformation: IInformation[] = [
    {
        title: "We're here for you",
        subtitle: "We want to help people overcome the following:",
        paragraphs: [
            "Locking in your mental health feelings, letting them develop and worsen.",
        ],
        bullets: [0],
        icon: caretDownCircleOutline
    },
    {
        paragraphs: ["Feeling like you do not have the right person to speak with or open up to.",],
        bullets: [0],
        icon: caretDownCircleOutline
    },
    {
        paragraphs: ["Being embarrassed or worried of negative stigma when you discuss your situation.",],
        bullets: [0],
        icon: caretDownCircleOutline
    },
    {
        paragraphs: ["Feeling alone, like no one out there will understand you and how you feel."],
        bullets: [0],
        icon: caretDownCircleOutline
    }
]
const Goal: FC<any> = ({hidden}) => {
    return (
        <div hidden={hidden}>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        {registeredInformation.map(((info, i) => (
                            <OurBrainInformation key={i} information={info}/>
                        )))}
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}

export default Goal
