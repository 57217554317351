import React, { FC } from 'react'

import './index.scss'

const LineDivider: FC<any> = () => {
    return (
        <div id="line"/>
    )
}

export default LineDivider
