import {
  IonAvatar,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonFooter,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSpinner,
  IonText,
  IonToggle,
} from "@ionic/react";
import classNames from "classnames";
import { push } from "connected-react-router";
import {
  bookmark,
  bookmarkOutline,
  ellipsisHorizontal,
  informationCircleOutline,
} from "ionicons/icons";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Notification,
  OurBrainCard,
  OurBrainContent,
} from "../../../../components";
import NavBar from "../../../../components/NavBar";
import OurBrainInformation, {
  IInformation,
} from "../../../../components/OurBrainInformation";
import { IFeedPostModel } from "../../../../interfaces/interfaces/feedPost";
import { ModerationResult } from "../../../../interfaces/interfaces/partnership";
import { actions as authActions } from "../../../Auth/redux";
import { IAuthActions } from "../../../Auth/redux/actions";
import { IAuthState } from "../../../Auth/redux/reducer";
import { actions as hubActions } from "../../../Hub/redux";
import { actions as partnershipActions } from "../../../Partnerships/redux";
import { IPartnershipActions } from "../../../Partnerships/redux/actions";
import { IHubActions } from "../../redux/actions";
import { IHubState } from "../../redux/reducer";
import styles from "./index.module.scss";

type FeedProps = {
  actions: IAuthActions & IPartnershipActions & IHubActions & { push: any };
  auth: IAuthState;
  hub: IHubState;
  router: any;
  push: any;
};

type PostProps = {
  feedPost: IFeedPostModel;
  savedPosts: string[];
  actions: IHubActions;
  activeUserId: string;
};

const Post: FC<PostProps> = (props) => {
  const { feedPost, activeUserId, actions, savedPosts } = props;
  const { text, type, user, moderation, createdAt } = feedPost;

  const [selected, setSelected] = useState(false);
  const [postFormat, setPostFormat] = useState("hidden");
  const [openNotification, setOpenNotification] = useState(false);

  function getPostFormat(
    feedPost: IFeedPostModel,
    activeUserId: string
  ): "greyed" | "hidden" | "visible" {
    const { user, moderation } = feedPost;

    if (
      activeUserId === user._id &&
      moderation.decision === ModerationResult.Pending
    ) {
      return "greyed";
    }
    return "visible";
  }

  function handleSave() {
    actions.savePost({ data: { feedPostId: feedPost._id, isSaved: selected } });

    if (!selected) setOpenNotification(true);
    setSelected(!selected);
  }
  function getTime() {
    const diffDays: number = moment().diff(moment(createdAt), "days");
    if (moderation.decision === ModerationResult.Pending) {
      return "Pending";
    }
    if (diffDays === 0) {
      return "Today";
    }
    if (diffDays === 1) {
      return "Yesterday";
    }
    return `${diffDays} days ago`;
  }

  useEffect(() => {
    setPostFormat(getPostFormat(feedPost, activeUserId));
    if (savedPosts.includes(feedPost._id)) {
      setSelected(true);
    }
  }, [savedPosts, feedPost, feedPost._id]);

  const title: any = {
    thought: "Thoughts Box",
    tip: "Tips & Tricks",
    story: "Short Stories",
    announcement: "Announcement",
  };
  return (
    <OurBrainCard
      className={classNames(styles.post__container, styles[`${type}__border`])}
      disabled={postFormat === "greyed"}
    >
      <Notification
        position="top"
        color="success"
        isOpen={openNotification}
        message="Post saved"
      />
      <IonItemDivider
        className={classNames(styles[`${type}__bg`], styles.type__box)}
      >
        <div className={styles.type__box__text}>
          <p>{`${title[type]}`}</p>
        </div>
      </IonItemDivider>
      <IonCardHeader>
        <div className={styles.header__container}>
          <div>
            <IonAvatar className={styles.post__avatar}>
              <img src={user?.url} alt={user?.username + "'s avatar"} />
            </IonAvatar>
          </div>
          <div>
            <p>
              {user?.username}
              {activeUserId === feedPost.user._id ? " (you)" : ""}
            </p>
          </div>
        </div>
      </IonCardHeader>
      <IonCardContent>
        <IonText color="dark">{text}</IonText>
      </IonCardContent>
      <IonItemDivider className={styles.post__footer}>
        <div className={styles.post__footer__time}>
          <p>{getTime()}</p>
        </div>
        <IonButton fill="clear" onClick={handleSave} slot="end" color="dark">
          <IonIcon icon={selected ? bookmark : bookmarkOutline} />
          <p className={styles.post__footer__save}>Save</p>
        </IonButton>
      </IonItemDivider>
    </OurBrainCard>
  );
};

type FilterModalProps = {
  actions: IHubActions;
  isOpen: boolean;
  setOpenFilter: any;
};

const FilterModal: FC<FilterModalProps> = (props) => {
  const { isOpen, setOpenFilter, actions } = props;

  const [query, setQuery] = useState("?type=all");

  function handleClose() {
    actions.getFeed({ query: "?type=all&limit=10" });
    setOpenFilter(false);
  }

  function handleFilter() {
    actions.getFeed({ query });
    setOpenFilter(false);
  }
  return (
    <IonModal isOpen={isOpen}>
      <OurBrainContent sizeMd="11">
        <IonList>
          <IonItem>
            <IonLabel>All posts</IonLabel>
            <IonToggle
              checked={query === "?type=all"}
              onClick={() => setQuery("?type=all")}
            />
          </IonItem>
          <IonList className={styles.filter__nested}>
            <IonItem>
              <IonLabel>Thoughts Box</IonLabel>
              <IonToggle
                checked={query === "?type=thought"}
                onClick={() => setQuery("?type=thought  ")}
              />
            </IonItem>
            <IonItem>
              <IonLabel>Tips & Tricks</IonLabel>
              <IonToggle
                checked={query === "?type=tip"}
                onClick={() => setQuery("?type=tip")}
              />
            </IonItem>
            <IonItem>
              <IonLabel>Short Stories</IonLabel>
              <IonToggle
                checked={query === "?type=story"}
                onClick={() => setQuery("?type=story")}
              />
            </IonItem>
          </IonList>
          <IonItem>
            <IonLabel>My posts</IonLabel>
            <IonToggle
              checked={query === "?type=user"}
              onClick={() => setQuery("?type=user")}
            />
          </IonItem>
          <IonItem>
            <IonLabel>Saved posts</IonLabel>
            <IonToggle
              checked={query === "?type=saved"}
              onClick={() => setQuery("?type=saved")}
            />
          </IonItem>
        </IonList>
      </OurBrainContent>
      <IonFooter>
        <IonButton expand="block" onClick={handleFilter}>
          Filter
        </IonButton>
        <IonButton
          expand="block"
          fill="clear"
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

interface InfoModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const InfoModal: FC<InfoModalProps> = (props) => {
  const { isOpen, setIsOpen } = props;

  function handleClose() {
    setIsOpen(false);
  }

  const guidelineInformation: IInformation[] = [
    {
      title: "The Hub",
      subtitle: "The new mental health content feed.",
      paragraphs: [
        "Welcome to the ourBrain Hub. This feed is a new and constantly developing area for you to access and share content with other users of ourBrain.",
      ],
    },
    {
      subtitle: "How to use it?",
      paragraphs: [
        "For learning from the feed, we recommend staying up to date with posts and saving what's relevant for you and your mental health. We hope this can build a catalogue of useful information for you to always refer back to, but also to connect with other users on ourBrain.",
        "For sharing onto the feed, this is great to do as you are instantly helping someone else and the wider ourBrain community, so thank you. It's also a great way to open up about your feelings and build towards a 1:2:1 conversation. Please pick the relevant category for your post.",
      ],
    },
    {
      subtitle: "Guidelines",
      paragraphs: [
        "As per our standard community rules document, please only post helpful, value adding information. Each post will go through the same moderation process as our partnership check-ins, so anything harmful, rude, or violent will not be passed.",
        "Thank you and please reach out to us with any further questions on contactus@ourbrain.io",
      ],
    },
  ];
  return (
    <IonModal isOpen={isOpen}>
      <OurBrainContent sizeMd="11">
        {guidelineInformation.map((information) => (
          <OurBrainInformation
            key={information.title}
            information={information}
          />
        ))}
      </OurBrainContent>
      <IonFooter>
        <IonButton
          expand="block"
          fill="clear"
          color="secondary"
          onClick={handleClose}
        >
          Close
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

const Feed: FC<FeedProps> = (props) => {
  const { actions, hub, router, auth } = props;
  const { user } = auth;
  const { location } = router;
  const { feedPosts, loading, savedPosts } = hub;

  const [limit, setLimit] = useState(10);
  const [type, setType] = useState(location.query.type);
  const [openFilter, setOpenFilter] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  function maxPostsReached() {
    return feedPosts.length < limit && feedPosts.length !== 0;
  }

  useEffect(() => {
    if (maxPostsReached()) {
      return;
    }
    const newType: string = type;
    const query: string = `?type=${newType}&limit=${limit}`;
    actions.getFeed({
      query,
    });
    return () => {
      actions.setHubState({ feedPosts: [] });
    };
  }, []);

  function handleLoadMore() {
    if (maxPostsReached()) {
      return;
    }
    const type: string = location.query.type;
    const newLimit: number = limit + 10;
    const query: string = `?type=${type}&limit=${newLimit}`;
    actions.getFeed({
      query,
    });
    setLimit(newLimit);
  }
  function handleFilter() {
    actions.setHubState({ feedPosts: [] });
    setOpenFilter(true);
  }
  function handleInfo() {
    setOpenInfo(true);
  }

  return (
    <IonPage className={styles.feed__container}>
      <NavBar actions={actions} title="Hub" backButton backLink="/app" />
      <Notification
        position="top"
        color="success"
        isOpen={hub.showSuccess.length > 0}
        message={hub.showSuccess}
        onDidDismiss={() => actions.setHubState({ showSuccess: "" })}
      />
      <OurBrainContent>
        {feedPosts
          .sort((a) => (a.type === "announcement" ? -1 : 1))
          .map((feedPost, i: number) => (
            <Post
              key={i}
              feedPost={feedPost}
              activeUserId={user?._id}
              actions={actions}
              savedPosts={savedPosts}
            />
          ))}
        <div className={styles.feed__loading__dots}>
          {loading ? (
            <IonSpinner name="dots" />
          ) : maxPostsReached() ? (
            <p>You're all caught up</p>
          ) : (
            <IonIcon icon={ellipsisHorizontal} color="primary" />
          )}
        </div>
        <IonButton
          disabled={maxPostsReached()}
          fill="outline"
          expand="block"
          onClick={handleLoadMore}
          className={styles.load__more__btn}
        >
          Load more...
        </IonButton>
      </OurBrainContent>
      <IonFooter>
        <div className={styles.fab}>
          <IonButton
            expand="full"
            fill="solid"
            color="secondary"
            onClick={handleFilter}
          >
            Filter
          </IonButton>
          <IonButton
            expand="full"
            fill="solid"
            color="tertiary"
            onClick={handleInfo}
          >
            <IonIcon icon={informationCircleOutline} />
          </IonButton>
          <IonButton
            expand="full"
            fill="solid"
            onClick={() => actions.push("/app/hub/create")}
          >
            Create
          </IonButton>
        </div>
      </IonFooter>
      <FilterModal
        actions={actions}
        isOpen={openFilter}
        setOpenFilter={setOpenFilter}
      />
      <InfoModal isOpen={openInfo} setIsOpen={setOpenInfo} />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...partnershipActions, ...authActions, ...hubActions, push },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Feed);
