import {
  IonButton,
  IonCardContent,
  IonContent,
  IonIcon,
  IonItemDivider,
  IonPage,
} from "@ionic/react";
import { moonOutline } from "ionicons/icons";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { NavBar, OurBrainCard } from "../../../../components";
import { actions as adminActions } from "../../redux";
import { IAdminActions } from "../../redux/actions";
import { IAdminState } from "../../redux/reducer";
import styles from "./index.module.scss";

type ViewLivePartnershipsProps = {
  actions: IAdminActions;
  admins: IAdminState;
};

const ViewLivePartnerships: FC<ViewLivePartnershipsProps> = (props) => {
  const { actions, admins } = props;

  useEffect(() => {
    actions.getAllPartnershipsStart();
  }, [actions]);

  const RenderPartnerships = () => {
    return admins.partnerships.map(
      ({ _id, usersInfo, isDormant, createdAt }) => (
        <div key={_id}>
          <OurBrainCard index={_id}>
            <IonCardContent className={styles.partnershipCardContainer}>
              <div className={styles.partnershipCardContent}>
                <div>
                  <p>
                    {usersInfo[0].username} & {usersInfo[1].username}
                  </p>
                  <p>
                    Live for: {moment().diff(moment(createdAt), "days")} days
                  </p>
                </div>
                <IonButton fill="clear">
                  <IonIcon icon={moonOutline} />
                </IonButton>
              </div>
            </IonCardContent>
            <IonItemDivider color={isDormant ? "warning" : "success"} />
          </OurBrainCard>
        </div>
      )
    );
  };

  return (
    <IonPage>
      <NavBar
        backButton
        backLink="/admin"
        title="View partnerships"
        actions={actions}
      />

      <IonContent>
        <div className={styles.contentContainer}>{RenderPartnerships()}</div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...adminActions }, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewLivePartnerships);
