import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSkeletonText,
} from "@ionic/react";
import { Grid } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainButton, TabBar } from "../../../../components";
import NavBar from "../../../../components/NavBar";
import OurBrainInformation, {
  IInformation,
} from "../../../../components/OurBrainInformation";
import { IUserPartnershipModel } from "../../../../interfaces/interfaces";
import authActions, { IAuthActions } from "../../../Auth/redux/actions";
import profileActions, {
  IProfileActions,
} from "../../../Profile/redux/actions";
import PartnershipCard from "../../components/PartnershipCard";
import partnershipListActions, {
  IPartnershipActions,
} from "../../redux/actions";
import { IPartnershipDefaultState } from "../../redux/reducer";
import styles from "./index.module.scss";

type PartnershipListProps = {
  actions: IPartnershipActions & IAuthActions & IProfileActions;
  userPartnership: IUserPartnershipModel;
  partnership: IPartnershipDefaultState;
  loading: boolean;
  activeUser: any;
  isAuthenticated: boolean;
  userPartnerships: [];
};

const currentInformation: IInformation = {
  title: "Your partnerships",
  subtitle: "Active:",
};

const newbieInformation: IInformation = {
  paragraphs: ["No partnerships created yet."],
};
const dormantInformation: IInformation = {
  subtitle: "Dormant:",
};
const newInformationTop: IInformation = {
  title: "New",
  subtitle: "Connect with someone who truly understands how you feel.",
};

const requestedPartnershipInformation: IInformation = {
  paragraphs: [
    "Thank you, we will notify you by email when your partnership is ready.",
  ],
};

const newInformationBottom: IInformation = {
  subtitle: "Let's open up, not lock it in.",
};

const PartnershipDashboard: FC<PartnershipListProps> = ({
  actions,
  activeUser,
  loading,
  partnership,
  userPartnerships,
  isAuthenticated,
}) => {
  const renderPartnerships = (isDormant: boolean) => {
    return partnership.userPartnerships?.map(
      (userPartnership: IUserPartnershipModel, index: number) => (
        <div key={index}>
          <PartnershipCard
            userPartnership={userPartnership}
            activeUser={activeUser}
            actions={actions}
            index={index}
            isDormant={isDormant}
          />
        </div>
      )
    );
  };
  useEffect(() => {
    actions.loadUserPartnershipsStart({ userId: activeUser._id });
    actions.setPartnershipState({
      selectedPartnership: {},
      partnershipCheckIns: [],
      foundTalkingPoint: {},
    });
  }, []);

  return (
    <IonPage className={styles.partnershipListContainer}>
      <NavBar actions={actions} title="Partnerships" />
      <IonContent>
        <Grid>
          <IonRow className="ion-margin-horizontal ion-justify-content-center">
            <IonCol>
              <OurBrainInformation information={currentInformation} />
              <IonCard
                hidden={
                  userPartnerships.length > 0 ||
                  activeUser.config.requestedPartnership
                }
              >
                <IonCardContent>
                  <IonSkeletonText hidden={!loading} animated />
                  <OurBrainInformation
                    hidden={loading}
                    center
                    information={newbieInformation}
                  />
                </IonCardContent>
              </IonCard>
              {renderPartnerships(false)}
              <OurBrainInformation
                hidden={userPartnerships.length === 0}
                information={dormantInformation}
              />
              {renderPartnerships(true)}
            </IonCol>
            <IonCol>
              <div className={styles.dashboardNew}>
                <OurBrainInformation information={newInformationTop} />
                <IonCard
                  hidden={!activeUser.config.requestedPartnership}
                  className={styles.requestedPartnershipCard}
                >
                  <IonCardContent>
                    <OurBrainInformation
                      center
                      information={requestedPartnershipInformation}
                      hidden={!activeUser.config.requestedPartnership}
                    />
                  </IonCardContent>
                </IonCard>
                <OurBrainButton
                  disabled={activeUser.config.requestedPartnership}
                  className="ion-margin-vertical"
                  size="large"
                  expand="block"
                  color="secondary"
                  link="/app/partnership/begin"
                >
                  {activeUser.config.requestedPartnership ? (
                    <IonProgressBar type="indeterminate" />
                  ) : (
                    "Get started"
                  )}
                </OurBrainButton>

                <OurBrainInformation information={newInformationBottom} />
              </div>
            </IonCol>
          </IonRow>
        </Grid>
      </IonContent>
      <TabBar />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    userPartnerships: state.partnership.userPartnerships,
    loading: state.partnership.loading,
    activeUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...partnershipListActions, ...authActions, ...profileActions },
      dispatch
    ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipDashboard);
