import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import styles from "./index.module.scss";
import { Controller } from "react-hook-form";
import React, { FC } from "react";
import { isNil, startCase } from "lodash";
import ReactTooltip from "react-tooltip";
import { informationCircle } from "ionicons/icons";

type InputProps = {
    errors?: any
    control: any
    labelPosition?: "stacked" | "fixed" | "floating" | undefined
    name: string
    type?: "number" | "time" | "text" | "tel" | "url" | "email" | "search" | "date" | "password" | undefined
    rules?: any
    as: any
    tooltip?: string
    showLabel?: boolean
    render?: any
}

const Input: FC<InputProps> = (props) => {
    const {as, errors, control, name, tooltip, children, type = "text", labelPosition, showLabel = false, rules} = props
    const labelText: string | undefined | null = children?.toString()
    return (
        <div className={styles.inputContainer}>
            <IonItem className={errors[name] && styles.error}>
                <IonLabel
                    hidden={showLabel}
                    position={labelPosition || "stacked"}
                >{isNil(labelText) ? startCase(name) : labelText}{!rules?.required && " (optional)"}
                </IonLabel>
                <Controller as={as}
                            name={name}
                            control={control}
                            clearInput
                            onChangeName="onIonChange"
                            onChange={([selected]) => selected.detail.value}
                            type={type}
                            rules={rules}/>
                <ReactTooltip/>
                {typeof tooltip !== "undefined" &&
                <div data-place="left" data-type="info" data-multiline={true}
                     data-background-color={"var(--ion-color-primary)"} data-delay-hide={1000} data-tip={tooltip}>
                    <IonIcon
                        icon={informationCircle}/>
                </div>}
            </IonItem>
        </div>
    )
}

export default Input
