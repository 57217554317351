import { PROCESS_FORM_UPDATE, } from "../../../config/constants";
import { IAction } from "../../../config/common";
import {
    AUTH_SET_STATE,
    PROCESS_LOGIN_START,
    PROCESS_LOGOUT_START,
    PROCESS_PASSWORD_RESET_START_START,
    PROCESS_PASSWORD_RESET_VERIFY_START,
    PROCESS_SIGNUP_START,
    SET_PERMISSIONS_START,
    UPDATE_USER
} from "./constants";

const processLoginStart: any = (payload: string): IAction => {
    return {type: PROCESS_LOGIN_START, payload}
}
const processLogoutStart: any = (payload: string): IAction => {
    return {type: PROCESS_LOGOUT_START, payload}
}
const processSignUpStart: any = (payload: string): IAction => {
    return {type: PROCESS_SIGNUP_START, payload}
}
const processPasswordResetStartStart: any = (payload: string): IAction => {
    return {type: PROCESS_PASSWORD_RESET_START_START, payload}
}
const processPasswordResetVerifyStart: any = (payload: string): IAction => {
    return {type: PROCESS_PASSWORD_RESET_VERIFY_START, payload}
}
export const processFormUpdate: any = (payload: string): IAction => {
    return {type: PROCESS_FORM_UPDATE, payload}
}
export const processSetPermissions: any = (payload: string): IAction => {
    return {type: SET_PERMISSIONS_START, payload}
}
export const updateUser: any = (payload: string): IAction => {
    return {type: UPDATE_USER, payload} // CHANGE
}
export const setAuthState: any = (payload: string): IAction => {
    return {type: AUTH_SET_STATE, payload} // CHANGE
}

export interface IAuthActions {
    processLogoutStart: Function
    processLoginStart: Function
    processSignUpStart: Function
    processPasswordResetStartStart: Function
    processPasswordResetVerifyStart: Function
    processFormUpdate: Function
    processSetPermissions: Function
    updateUser: Function // CHANGE
    setAuthState: Function
}


export default {
    processLogoutStart,
    processLoginStart,
    processSignUpStart,
    processPasswordResetStartStart,
    processPasswordResetVerifyStart,
    processFormUpdate,
    processSetPermissions,
    setAuthState,
    updateUser // CHANGE
}
