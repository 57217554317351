import { IAction } from "../../../config/common";
import {
  CREATE_POST_START,
  GET_FEED_START,
  SAVE_POST_START,
  SET_HUB_STATE,
} from "./constants";

const getFeed: any = (payload: string): IAction => {
  return { type: GET_FEED_START, payload };
};

const createPost: any = (payload: string): IAction => {
  return { type: CREATE_POST_START, payload };
};

const savePost: any = (payload: string): IAction => {
  return { type: SAVE_POST_START, payload };
};

const setHubState: any = (payload: string): IAction => {
  return { type: SET_HUB_STATE, payload };
};

export interface IHubActions {
  getFeed: Function;
  createPost: Function;
  savePost: Function;
  setHubState: Function;
}
export default {
  getFeed,
  createPost,
  savePost,
  setHubState,
};
