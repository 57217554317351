import { IModalBase } from "../common";
import { IQuestionModel } from "../question";
import { IUserModel } from "../user";

export interface IPartnership {
  title?: string;
  index: string;
  class: PartnershipClass;
  talkingPoints: IQuestionModel["_id"][];
  talkingPointRequestedAt?: Date;
  activeTalkingPoint: IQuestionModel;
  usersInfo: IPartnershipUsersInfo[];
  level: number;
  streak: IPartnershipStreak;
  isDormant: boolean;
  isReported: boolean;
  report: IPartnershipReport;
  reportedAt?: Date | null;
  isDeleted: boolean;
  deletedAt?: Date | null;
  isFlagged: boolean;
}

export interface IPartnershipReport {
  userReport: string;
  userId: IUserModel["_id"];
}

export interface IPartnershipStreak {
  currentStreak: number;
  longestStreak: number;
  streakLastCalculatedAt: Date;
}

export interface IPartnershipUsersInfo {
  _id: IUserModel["_id"];
  username: string;
  avatarUrl: string;
  lastCheckedInAt?: Date;
  seenInfo: boolean;
  firstPartnership: boolean;
}

export interface IPartnershipModel extends IPartnership, IModalBase {}

export enum PartnershipClass {
  CheckIn = "checkIn",
  Chat = "chat",
}

export enum PartnershipMessageType {
  SMS = "sms",
  MMS = "mms",
  VMS = "vms",
}

export interface IPartnershipMessage {
  partnershipId: IPartnershipModel["_id"];
  userId: IUserModel["_id"];
  message: string;
  userDisplayName: string[];
  type: PartnershipMessageType;
  attachmentThumbUrl?: string;
  attachmentUrl?: string;
  deletedAt?: Date | null;
}

export interface IPartnershipMessageModel
  extends IPartnershipMessage,
    IModalBase {}

export enum ModerationResult {
  Pass = "pass",
  PassFlag = "pass:flag",
  PassFlagEmail = "pass:flag:email",
  Warning = "warning",
  WarningSuicide = "warning:suicide",
  WarningAbuse = "warning:abuse",
  WarningAdvice = "warning:advice",
  WarningAntiSocial = "warning:antisocial",
  WarningDepend = "warning:depend",
  WarningPrivacy = "warning:privacy",
  Fail = "fail",
  Pending = "pending",
}

export interface IPartnershipCheckInConfig {
  decision: ModerationResult;
  message?: string;
  isResolved?: boolean;
}

export interface IPartnershipCheckIn {
  partnershipId: IPartnershipModel["_id"];
  userId: IUserModel["_id"];
  message: string;
  config: IPartnershipCheckInConfig;
}

export interface IPartnershipCheckInModel
  extends IPartnershipCheckIn,
    IModalBase {}
