import {
  IonAlert,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonItemDivider,
  IonModal,
  IonSpinner,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { Push } from "connected-react-router";
import { chevronDown, chevronUp } from "ionicons/icons";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { OurBrainContent } from "../../../../../../../components";
import OurBrainInformation from "../../../../../../../components/OurBrainInformation";
import { getAssetPath } from "../../../../../../../config/common";
import {
  IPartnershipCheckIn,
  IPartnershipCheckInModel,
  IPartnershipModel,
  IUserModel,
} from "../../../../../../../interfaces/interfaces";
import { IPartnershipActions } from "../../../../../redux/actions";
import { IPartnershipDefaultState } from "../../../../../redux/reducer";
import { NoCheckIn, TalkingPoint } from "../PartnershipCheckIn";
import styles from "./index.module.scss";

type PartnershipCheckInSubmitModalProps = {
  openCheckInModal: boolean;
  setOpenCheckInModal: Dispatch<SetStateAction<boolean>>;
  actions: IPartnershipActions & { push: Push };
  selectedPartnership?: IPartnershipModel;
  activeUser: IUserModel;
  partnersCheckIn?: IPartnershipCheckInModel;
  activeUsersCheckIn?: IPartnershipCheckInModel;
  talkingPoint?: string;
  isCheckInButtonDisabled: () => boolean;
  loading: boolean;
  partnershipState: IPartnershipDefaultState;
};

const validationSchema = yup.object().shape({
  message: yup.string().required(),
});

interface formValues {
  message: string;
}
const formatCheckIn = (checkInText: string | undefined) => {
  if (typeof checkInText === "undefined") {
    return { checkInText: "", hideExpand: true };
  }
  if (checkInText.length > 75) {
    return {
      checkInText: `${checkInText.substr(0, 50)}...`,
      hideExpand: false,
    };
  } else return { checkInText, hideExpand: true };
};

const CheckInCard: FC<any> = ({ message, loading, index }) => {
  const [expand, setExpand] = useState(false);

  const { checkInText, hideExpand } = formatCheckIn(message);
  return (
    <IonCard key={index}>
      <IonCardHeader>
        <IonCardSubtitle>
          <IonText color="primary">
            Your {index === 0 ? "" : "partner's"} last check-in
          </IonText>
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        {loading ? (
          <IonSpinner name="dots" />
        ) : (
          <pre>{expand ? message : checkInText || <NoCheckIn />}</pre>
        )}
      </IonCardContent>
      <IonItemDivider hidden={hideExpand}>
        <div className={styles.expandButton}>
          <IonButton
            fill="clear"
            onClick={() => setExpand(!expand)}
            type="button"
            size="small"
          >
            <IonIcon icon={expand ? chevronUp : chevronDown} />
          </IonButton>
        </div>
      </IonItemDivider>
    </IonCard>
  );
};

interface CheckInSuccessProps {
  actions: IPartnershipActions & { push: Push };
}
const CheckInSuccess: FC<CheckInSuccessProps> = (props) => {
  const { actions } = props;

  function handleClick(location: string) {
    console.log("handle");
    if (location === "hub") {
      gtag("event", "button_click", {
        event_category: "check_in_success",
        event_label: `to_hub`,
      });
      actions.setPartnershipState({ showSuccess: undefined });
      actions.push("/app/hub?type=all");
    }
    if (location === "partnerships") {
      actions.setPartnershipState({ showSuccess: undefined });
      gtag("event", "button_click", {
        event_category: "check_in_success",
        event_label: `to_partnerships`,
      });
    }
  }

  return (
    <div>
      <div>
        <OurBrainInformation
          information={{
            title: "Great work!",
          }}
        />
      </div>
      <div className={styles.celebrain__container}>
        <img
          className={styles.celebrain}
          src={getAssetPath({ name: "celebrain" })}
          alt="celebration-brain-picture"
        />
      </div>
      <div>
        <OurBrainInformation
          information={{
            subtitle: `You checked in today with your partner.`,
            paragraphs: [
              "Each check-in is an amazing achievement, wherever you are on your journey!",
              "What would you like to do now?",
            ],
          }}
        />
        <IonButton
          color="secondary"
          expand="block"
          onClick={() => handleClick("hub")}
        >
          Check out the Hub
        </IonButton>
        <IonButton expand="block" onClick={() => handleClick("partnerships")}>
          Back to Partnerships
        </IonButton>
      </div>
    </div>
  );
};

const PartnershipCheckInSubmitModal: FC<PartnershipCheckInSubmitModalProps> = (
  props
) => {
  const {
    setOpenCheckInModal,
    openCheckInModal,
    actions,
    selectedPartnership,
    partnersCheckIn,
    activeUsersCheckIn,
    talkingPoint,
    isCheckInButtonDisabled,
    loading,
    partnershipState,
  } = props;
  const [modalPresented, setModalPresented] = useState(false);
  const [showCloseWarning, setShowCloseWarning] = useState(false);
  const { handleSubmit, control, reset, getValues, errors } = useForm<
    formValues
  >({
    defaultValues: { message: "" },
    mode: "onBlur",
    validationSchema,
  });

  const handleSubmitCheckIn = async (data: formValues) => {
    try {
      await validationSchema.validate(data);
    } catch (error) {
      return;
    }
    const partnershipCheckIn: Partial<IPartnershipCheckIn> = {
      message: data.message,
    };
    const partnership = {
      isDormant: selectedPartnership?.isDormant,
    };
    actions.processSubmitPartnershipCheckIn({
      partnershipCheckIn,
      partnership,
      partnershipId: selectedPartnership!._id,
    });
    reset();
  };
  const messages = [partnersCheckIn?.message, activeUsersCheckIn?.message];

  function getPrompt(checkInLength: number) {
    if (checkInLength === 0) {
      return "";
    }
    if (checkInLength < 50) {
      return "Why don't you write a little more?";
    }
    if (checkInLength >= 50 && checkInLength <= 150) {
      return "Getting better!";
    }
    return "Great work, this a good check-in";
  }
  return (
    <IonModal
      isOpen={openCheckInModal}
      onDidPresent={() => setModalPresented(true)}
      onDidDismiss={() => setOpenCheckInModal(false)}
    >
      <OurBrainContent
        sizeMd="11"
        hidden={partnershipState.showSuccess !== "checkInSuccess"}
      >
        <CheckInSuccess actions={actions} />
      </OurBrainContent>
      <OurBrainContent
        sizeMd="11"
        hidden={
          !modalPresented || typeof partnershipState.showSuccess !== "undefined"
        }
      >
        <div className={styles.checkInContent}>
          <div>
            <TalkingPoint talkingPoint={talkingPoint} />
            <CheckInCard
              message={activeUsersCheckIn?.message}
              loading={loading}
              index={0}
            />
            <CheckInCard
              message={partnersCheckIn?.message}
              loading={loading}
              index={1}
            />
          </div>
          <div className={styles.partnershipCheckInModal}>
            <form onSubmit={handleSubmit(handleSubmitCheckIn)}>
              <Controller
                name="message"
                control={control}
                rules={{ required: true, minLength: 1 }}
                as={({ onChange, value }) => (
                  <div className={styles.checkInPrompt}>
                    <IonTextarea
                      name="message"
                      rows={3}
                      autoGrow
                      autofocus
                      autocapitalize="sentences"
                      debounce={300}
                      hidden={isCheckInButtonDisabled()}
                      placeholder="Type a message..."
                      id="message"
                      onIonChange={onChange}
                      value={value}
                      className="ion-text-left"
                    />
                    <small>{getPrompt(value.length)}</small>
                  </div>
                )}
              />

              <IonButton
                disabled={isCheckInButtonDisabled()}
                type="submit"
                fill="outline"
                color={
                  typeof errors.message !== "undefined" ? "warning" : "primary"
                }
              >
                {isCheckInButtonDisabled()
                  ? "You checked in today!"
                  : "Check In"}
              </IonButton>
              <IonButton
                onClick={() =>
                  getValues()["message"].length > 0
                    ? setShowCloseWarning(true)
                    : setOpenCheckInModal(false)
                }
                fill="clear"
                color="medium"
              >
                Close
              </IonButton>
            </form>
          </div>
        </div>
      </OurBrainContent>
      <IonAlert
        isOpen={showCloseWarning}
        header={"Are you sure?"}
        message={"Your check-in will not be saved."}
        buttons={[
          { text: "Cancel", role: "cancel" },
          { text: "I'm sure", handler: () => setOpenCheckInModal(false) },
        ]}
      />
    </IonModal>
  );
};

export default PartnershipCheckInSubmitModal;
