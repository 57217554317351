import { IonButton, IonFooter } from "@ionic/react";
import React, { FC } from "react";
import { OurBrainContent } from "../../../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../../../components/OurBrainInformation";
import { IPartnershipUsersInfo } from "../../../../../../../interfaces/interfaces";
import { IPartnershipActions } from "../../../../../redux/actions";
import { IPartnershipDefaultState } from "../../../../../redux/reducer";

type PartnershipInfoModalProps = {
  partnershipId: string;
  partnership: IPartnershipDefaultState;
  loading: boolean;
  actions: IPartnershipActions;
  activeUsersInfo: IPartnershipUsersInfo;
  showModal: string;
};

const infoInformations: IInformation[] = [
  {
    title: "Info",
  },
  {
    subtitle: "What is a partnership?",
    paragraphs: [
      "A partnership is the connection of two ourBrain users to discuss their mental health and open up, by using check-ins. Partnerships are created based on each user's unique requests.",
    ],
  },
  {
    subtitle: "What is a check-in?",
    paragraphs: [
      "A check-in is a daily message sent between users in a partnership, to talk about their current situation, daily activities, feelings and much more. They’re seen as a longer style, more thoughtful message.",
      "We recommend making your first check-in a light, introductory message about yourself and why you’re here.",
    ],
  },
  {
    subtitle: "What is a level?",
    paragraphs: [
      "As you and your partner open up through discussion, your partnership will grow. Each 7 day streak of conversation brings a new level, with new talking points and features.",
    ],
  },
  {
    subtitle: "What do I need to know?",
    paragraphs: [
      "1) Current partnerships are split into active and dormant. Dormant partnerships are currently not in use but can be brought back to life with activity.",
      "2) When you open your partnership, you will see the ‘check-in zone’ at the bottom of the page. There is also a menu bar in the top right corner.",
      "3) We use ‘talking points’ at ourBrain to help begin and continue the conversation.",
      "4) In order to continue creating partnerships, there are times when we will require feedback and ratings. To create more than 5 live partnerships, we will require a successful referral.",
    ],
  },
];

const Info: FC<PartnershipInfoModalProps> = (props) => {
  const { actions, partnershipId, loading, activeUsersInfo } = props;
  const handleOnClick = () => {
    if (!activeUsersInfo.seenInfo) {
      actions.updatePartnershipStart({
        data: { seenInfo: true, partnershipId },
      });
    } else {
      actions.setPartnershipState({ showModal: "" });
    }
  };
  return (
    <>
      <OurBrainContent sizeMd="11">
        {infoInformations.map((information, index) => (
          <OurBrainInformation information={information} key={index} />
        ))}
      </OurBrainContent>
      <IonFooter>
        <IonButton
          color="secondary"
          fill="solid"
          expand="block"
          disabled={loading}
          onClick={handleOnClick}
        >
          Understood
        </IonButton>
      </IonFooter>
    </>
  );
};

export default Info;
