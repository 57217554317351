import React, { FC } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import {
  chatbubblesOutline,
  heartOutline,
  keyOutline,
  trendingUp,
} from "ionicons/icons";

const introInformation: IInformation[] = [
  {
    title: "An overview",
    paragraphs: [
      "We are challenging the silence of mental health, by encouraging conversation and the ability to open up.",
    ],
    bullets: [0],
    icon: chatbubblesOutline,
  },
  {
    paragraphs: [
      "By connecting with someone who feels the same, we swap negative stigma for trust, empathy and understanding.",
    ],
    bullets: [0],
    icon: heartOutline,
  },
  {
    paragraphs: [
      "Through peer-to-peer partnerships we help you to grow, confide and build real relationships.",
    ],
    bullets: [0],
    icon: trendingUp,
  },
  {
    paragraphs: [
      "Safety is key to our environment, with check-in moderation, secured messaging and varying levels of anonymity.",
    ],
    bullets: [0],
    icon: keyOutline,
  },
];

const Overview: FC<any> = ({ hidden }) => {
  return (
    <div hidden={hidden}>
      <IonGrid>
        {introInformation.map((information, i) => (
          <IonRow key={i}>
            <IonCol>
              <OurBrainInformation information={information} />
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
    </div>
  );
};

export default Overview;
