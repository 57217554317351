import React, { FC } from "react";
import { getAssetPath } from "../../config/common";
import styles from "./index.module.scss";

type OurBrainLogoProps = {
  title?: boolean;
  width?: string;
};
const OurBrainLogo: FC<OurBrainLogoProps> = (props) => {
  const { title, width } = props;
  const logoOptions = {
    title: getAssetPath({ name: "brain_tile" }),
    noTitle: getAssetPath({ name: "brain_notitle_tile" }),
  };

  return (
    <div className={styles.ourBrainLogoContainer}>
      <img
        src={title ? logoOptions.title : logoOptions.noTitle}
        style={{ maxWidth: typeof width !== "undefined" ? width : "250px" }}
        alt="ourbrain-logo"
      />
    </div>
  );
};
export default OurBrainLogo;
