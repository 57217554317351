import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLoading,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  addCircleOutline,
  alertCircle,
  refreshCircleOutline,
} from "ionicons/icons";
import React, { FC, useState } from "react";
import { OurBrainButton } from "../index";

type NavBarProps = {
  actions?: any;
  backButton?: boolean;
  backLink?: string;
  title: string;
  warn?: boolean;
  list?: boolean;
  add?: boolean;
  reload?: boolean;
  menuRef?: any;
  addLink?: string;
};

const NavBar: FC<NavBarProps> = ({
  backButton,
  warn,
  backLink,
  title,
  reload,
  add,
  addLink,
}) => {
  const [showLoading, setShowLoading] = useState(false);
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButtons slot="start" hidden={!backButton}>
            <OurBrainButton backButton link={backLink}>
              Back
            </OurBrainButton>
          </IonButtons>
        </IonButtons>
        <IonTitle>{title}</IonTitle>
        <IonButton hidden={!warn} slot="end">
          <IonIcon color="primary" size="large" icon={alertCircle} />
        </IonButton>
        <OurBrainButton fill="clear" hidden={!add} slot="end" link={addLink}>
          <IonIcon color="primary" size="large" icon={addCircleOutline} />
        </OurBrainButton>
        <IonButton
          hidden={!reload}
          slot="end"
          fill="clear"
          onClick={() => {
            setShowLoading(true);
            window.location.reload();
          }}
        >
          <IonIcon color="primary" size="large" icon={refreshCircleOutline} />
        </IonButton>
        <IonLoading
          isOpen={showLoading}
          message={"Please wait..."}
          duration={5000}
        />
      </IonToolbar>
    </IonHeader>
  );
};

export default NavBar;
