import { config } from "dotenv";

let path: string = "../.env";

config({
  path: `${path}.${process.env.REACT_APP_BRAIN_ENVIRONMENT}`,
  debug: true,
});

export const API_BASE_URL: string | undefined =
  process.env.REACT_APP_BRAIN_API_BASE_URL;
export const API_BASE_URL_SANDBOX: string | undefined =
  process.env.REACT_APP_BRAIN_API_BASE_URL;
export const SENTRY_URL: string | undefined =
  process.env.REACT_APP_BRAIN_SENTRY_URL;

export const BRAIN_ENVIRONMENT: string | undefined =
  process.env.REACT_APP_BRAIN_ENVIRONMENT;

export const GA4_CODE: string | undefined = process.env.REACT_APP_GA4_CODE;

export const STREAM_API_KEY: string | undefined =
  process.env.REACT_APP_BRAIN_STREAM_API_KEY;
export const STREAM_APP_ID: string | undefined =
  process.env.REACT_APP_BRAIN_STREAM_APP_ID;
