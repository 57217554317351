import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonFooter,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from "@ionic/react";
import { arrowUpCircle, refreshCircleOutline } from "ionicons/icons";
import { groupBy, isNil, map, pick, startCase } from "lodash";
import { Column } from "material-table";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { NavBar, Notification } from "../../../../components";
import { IUserPartnershipModel } from "../../../../interfaces/interfaces";
import { actions as adminActions } from "../../../Admin/redux";
import { actions as partnershipActions } from "../../../Partnerships/redux";
import { IPartnershipActions } from "../../../Partnerships/redux/actions";
import { IPartnershipDefaultState } from "../../../Partnerships/redux/reducer";
import OurBrainTable from "../../components/OurBrainTable";
import { IAdminActions } from "../../redux/actions";
import { IAdminState } from "../../redux/reducer";
import "./index.module.scss";
import UserDetails from "./Modals/UserDetails";

type CreatePartnershipProps = {
  actions: IAdminActions & IPartnershipActions;
  partnership: IPartnershipDefaultState;
  userPartnership: IUserPartnershipModel;
  isModalOpen: boolean;
  foundPartnership: any;
  admins: IAdminState;
};
export const dashboardSearchConditions = {
  condition: { preferenceId: "", "partnershipIds.0": { $exists: false } },
  limit: 300,
};

interface ICreatePartnershipIndex {
  selectedUserIds: string[];
  index: string;
}

export function createPartnershipIndex(
  values: { _id: string }[]
): ICreatePartnershipIndex {
  const selectedUserIds: string[] = values.map(({ _id }) => _id);
  const index: string = selectedUserIds.sort().join("");

  return { selectedUserIds, index };
}

export function getUsersInfoFromTableValues(
  values: IPartnershipTableValues[]
): any[] {
  const requiredFields: string[] = ["_id", "username", "avatarUrl"];
  return values.map((value) => pick(value, requiredFields));
}

export interface IPartnershipTableValues {
  _id: string;
  username: string;
  avatarUrl: string;
  firstName: string;
  email: string;
  seenInfo: boolean;
  firstPartnership: boolean;

  [key: string]: any;
}

const CreatePartnership: FC<CreatePartnershipProps> = ({
  actions,
  partnership,
  admins,
}) => {
  const {
    userPreferences,
    createPartnershipData,
    userPreferenceAnswers,
    showSuccess,
    showFail,
    foundPartnership,
    isModalOpen,
    renderRows,
    userEmails,
  } = admins;
  const { preferences, preferenceQuestions } = partnership;
  const handleFindUserPreferencesByPreferenceId = (
    preferenceId: string,
    limit: number = 100
  ) => {
    if (preferenceId === "") {
      delete dashboardSearchConditions.condition["partnershipIds.0"];
      delete dashboardSearchConditions.condition.preferenceId;
    } else {
      dashboardSearchConditions.condition["partnershipIds.0"] = {
        $exists: false,
      };
      dashboardSearchConditions.condition.preferenceId = preferenceId;
    }

    dashboardSearchConditions.limit = limit;
    actions.findAllPreferenceQuestionsStart({
      questionIds: partnership.preferences[0].questionIds,
    });
    actions.processLoadAllUserPreferences({ dashboardSearchConditions });
  };
  const handleCreatePartnership = () => {
    actions.processCreatePartnership({
      createPartnershipData,
    });
  };

  const handleOnSelect = (values: IPartnershipTableValues[]) => {
    const createPartnershipData = values.map(
      ({
        userId,
        userPreferenceId,
        avatarUrl,
        username,
        firstName,
        email,
        partnershipsNo,
      }) => ({
        userId,
        userPreferenceId,
        avatarUrl,
        username,
        firstName,
        email,
        partnershipsNo,
      })
    );
    actions.processSelectUser({
      createPartnershipData,
    });
  };

  useEffect(() => {
    actions.findAllPreferencesStart();
    return () => {
      actions.resetState({
        selectedUserIds: [],
        foundPartnership: null,
        createPartnershipData: null,
        userPreferenceAnswers: undefined,
      });
    };
  }, [actions]);

  useEffect(() => {
    if (createPartnershipData?.length === 2) {
      const index: string = createPartnershipData
        .map(({ userId }) => userId)
        .sort()
        .join("");
      actions.processGetPartnership({ index });
    }
  }, [actions, createPartnershipData]);

  const options: any = {};
  const columns: any = React.useMemo(() => {
    const createdColumns: Column<any>[] = preferenceQuestions?.map(
      ({ _id, alias, tooltip }) => {
        return {
          title: alias,
          field: _id,
          tooltip,
        };
      }
    );
    const otherColumns: Column<any>[] = [
      {
        title: "First Name",
        field: "firstName",
      },
      {
        title: "Email",
        field: "email",
      },
      {
        title: "No. Partnerships",
        field: "partnershipsNo",
        type: "numeric",
      },
      {
        title: "Date of Birth",
        field: "dateOfBirth",
        type: "date",
      },
      {
        title: "City",
        field: "city",
      },
      {
        title: "Gender",
        field: "gender",
      },
    ];
    return [...otherColumns, ...createdColumns];
  }, [preferenceQuestions, userPreferenceAnswers]);

  const data = React.useMemo(() => {
    let rowArray: any[] = [];
    let dataRows: any = {};
    if (isNil(userPreferenceAnswers) || isNil(userPreferences)) {
      return rowArray;
    }
    const groupedUserPreferenceAnswers: any = groupBy(
      userPreferenceAnswers,
      "userPreferenceId"
    );
    const groupedUserPreferences: any = groupBy(userPreferences, "_id");
    if (typeof groupedUserPreferenceAnswers !== "undefined") {
      for (const userPreferenceId in groupedUserPreferenceAnswers) {
        const userPreference = groupedUserPreferences[userPreferenceId][0];
        if (typeof userPreference === "undefined") {
          return;
        }
        map(
          groupedUserPreferenceAnswers[userPreferenceId],
          // eslint-disable-next-line no-loop-func
          ({ answer, questionId }) => {
            dataRows[questionId] = answer;
          }
        );
        dataRows["firstName"] = userPreference?.userId?.firstName;
        dataRows["username"] = userPreference?.userId?.username;
        dataRows["userId"] = userPreference?.userId?._id;
        dataRows["userPreferenceId"] = userPreference?._id;
        dataRows["avatarUrl"] = userPreference?.userId?.avatarUrl;
        dataRows["gender"] = userPreference?.userId?.demographic.gender;
        dataRows["dateOfBirth"] = moment(
          userPreference?.userId?.demographic.dateOfBirth
        ).format("MM/YYYY");
        dataRows["city"] = userPreference?.userId?.demographic.city;
        dataRows["partnershipId"] = userPreference?.partnershipId;
        dataRows["email"] = userEmails![userPreference?.userId?._id];
        dataRows["partnershipsNo"] =
          userPreference?.userId?.partnerships.length || 0;
        rowArray.push(dataRows);
        dataRows = {};
      }
    }
    return rowArray;
  }, [renderRows]);

  return (
    <IonPage>
      <IonContent>
        <Notification
          position="top"
          color="success"
          message={"Partnership created successfully"}
          isOpen={showSuccess}
          onDidDismiss={() => actions.resetState({ showSuccess: false })}
        />
        <Notification
          position="top"
          color="danger"
          message={"Partnership creation failed"}
          isOpen={showFail}
          onDidDismiss={() => actions.resetState({ showFail: false })}
        />
        <NavBar
          backButton
          backLink="/admin"
          title="Create partnership"
          actions={actions}
        />
        <IonAlert
          isOpen={foundPartnership !== null}
          header={"Warning"}
          subHeader={"Invalid partnership"}
          message={"These users have already been partnered."}
          buttons={["OK"]}
        />
        <IonGrid>
          <IonRow>
            <IonCol>
              <OurBrainTable
                title={"Create new partnerships"}
                data={data}
                columns={columns}
                options={options}
                onSelect={handleOnSelect}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="start" slot="fixed">
          <IonFabButton>
            <IonIcon icon={arrowUpCircle} />
          </IonFabButton>
          <IonFabList side="end">
            <IonFabButton
              onClick={() =>
                actions.processLoadAllUserPreferences(dashboardSearchConditions)
              }
            >
              <IonIcon icon={refreshCircleOutline} />
            </IonFabButton>
          </IonFabList>
        </IonFab>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonSelect
            onIonChange={(e) =>
              handleFindUserPreferencesByPreferenceId(e.detail.value)
            }
            defaultValue={preferences[0]?._id}
            placeholder="Select a preference category"
          >
            <IonSelectOption value="">All</IonSelectOption>
            {preferences.map((preference) => (
              <IonSelectOption key={preference._id} value={preference._id}>
                {startCase(preference.type)}
              </IonSelectOption>
            ))}
          </IonSelect>
          <IonSelect
            onIonChange={(e) =>
              handleFindUserPreferencesByPreferenceId("", e.detail.value)
            }
            defaultValue={100}
            placeholder="Limit"
          >
            <IonSelectOption value={100}>100</IonSelectOption>
            <IonSelectOption value={200}>200</IonSelectOption>
            <IonSelectOption value={300}>300</IonSelectOption>
            <IonSelectOption value={500}>500</IonSelectOption>
            <IonSelectOption value={0}>All</IonSelectOption>
          </IonSelect>
          <IonButton
            onClick={handleCreatePartnership}
            expand="block"
            size="large"
            disabled={
              createPartnershipData?.length !== 2 || foundPartnership !== null
            }
          >
            Create Partnership
          </IonButton>
        </IonToolbar>
      </IonFooter>
      <UserDetails isOpen={isModalOpen} actions={actions} />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    users: state.admins.users,
    showSuccess: state.admins.showSuccess,
    showFail: state.admins.showFail,
    foundPartnership: state.admins.foundPartnership,
    isModalOpen: state.admins.isModalOpen,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...adminActions, ...partnershipActions },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePartnership);
