import { startCase } from "lodash";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { GA4_CODE } from "../../config/env";
import { actions as authActions } from "../../modules/Auth/redux";

export interface ProtectedRouteProps extends RouteProps {
  admin?: boolean;
  noAuth?: boolean;
}

const LoggedOutRoute: FC<ProtectedRouteProps> = ({
  component,
  isAuthenticated,
  admin,
  noAuth,
  isAdmin,
  ...rest
}: any) => {
  const location = useLocation();
  const pathSplit: string[] = location.pathname.split("/");
  const title: string = pathSplit.includes("reset")
    ? "Reset"
    : startCase(pathSplit[pathSplit.length - 1]);

  if (!isAuthenticated) {
    return (
      <>
        <Helmet>
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="8372271c-2624-437d-9ae7-f515441a59d4"
            data-blockingmode="auto"
            type="text/javascript"
          ></script>
          <script data-cookieconsent="ignore">
            {`
            (function(w,s,l,r){w[l]=w[l]||[];function gtag(){w[l].push(arguments)};
              gtag('consent','default',{ad_storage:s,analytics_storage:s,wait_for_update:500});
              gtag('set','ads_data_redaction',r)})(window,'denied','dataLayer',!0)`}
          </script>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA4_CODE}`}
          ></script>
          <script>
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA4_CODE}');
        `}
          </script>
          <title>ourBrain | {title}</title>
        </Helmet>
        <Route {...rest} component={component} />
      </>
    );
  } else {
    return <Redirect to={{ pathname: "/app" }} />;
  }
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAdmin,
    isUser: state.auth.isUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutRoute);
