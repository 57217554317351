import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { bug, document, lockClosed, man, send, woman } from "ionicons/icons";
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Notification, OurBrainContent, TabBar } from "../../../../components";
import NavBar from "../../../../components/NavBar";
import OurBrainInformation from "../../../../components/OurBrainInformation";
import { IUserModel } from "../../../../interfaces/interfaces";
import { actions as authActions } from "../../../Auth/redux";
import { IAuthActions } from "../../../Auth/redux/actions";
import { actions as profileActions } from "../../redux";
import { IProfileActions } from "../../redux/actions";
import styles from "./index.module.scss";
import { Feedback, UserDetails } from "./Modals";
import BugReport from "./Modals/BugReport";
import References from "./Modals/References";

type SettingsProps = {
  activeUser: IUserModel;
  actions: IProfileActions & IAuthActions;
  token: string;
  loading: boolean;
  showSuccess: boolean;
  popupMessage: string;
  showError: boolean;
  formKey: string;
  isModalOpen: {
    [key: string]: boolean;
  };
};

interface ISettings {
  formKey: string;
  label: string;
  color?: HTMLIonButtonElement["color"];
  fill?: HTMLIonButtonElement["fill"];
  icon: HTMLIonIconElement["icon"];
  isDivided?: boolean;
  modal: string;
}

const Settings: FC<SettingsProps> = ({
  token,
  activeUser,
  actions,
  isModalOpen,
  formKey,
  ...props
}) => {
  const history = useHistory();
  const { loading } = props;
  useEffect(() => {
    actions.reset();
    return () => {
      actions.reset();
    };
  }, [actions]);

  const handlePhotoUpload = (
    dataUrl: string,
    processedImage: { dataUrl: string; file: File }[]
  ) => {
    const { file } = processedImage[0];
    actions.processUploadPhotoStart({ file, _id: activeUser._id });
  };

  const [, setAvatarHovered] = useState(false);
  const settings: ISettings[] = [
    {
      formKey: "feedback",
      label: "Send feedback",
      icon: send,
      color: "primary",
      fill: "solid",
      modal: "feedback",
    },
    {
      formKey: "bug",
      label: "Submit bug report",
      icon: bug,
      color: "secondary",
      modal: "bugreport",
      isDivided: true,
    },
    {
      formKey: "references",
      label: "Key references",
      icon: document,
      modal: "references",
      color: "primary",
      isDivided: true,
    },
    {
      formKey: "username",
      label: "Change username",
      icon: activeUser.demographic.gender === "male" ? man : woman,
      modal: "details",
      color: "primary",
    },
    {
      formKey: "password",
      label: "Change password",
      icon: lockClosed,
      modal: "details",
      isDivided: true,
      color: "primary",
    },
  ];

  return (
    <IonPage className={styles.settingsContainer}>
      <Notification
        position={"top"}
        color={"success"}
        message={props.popupMessage}
        isOpen={props.showSuccess}
      />
      <NavBar actions={actions} title="Profile" />
      <OurBrainContent>
        <OurBrainInformation information={{ title: "Profile" }} />
        <div className={styles.settingsHeader}>
          <IonAvatar className={styles.settings__header__avatar}>
            <img
              src={activeUser?.avatarUrl}
              alt={activeUser?.username + "'s avatar"}
            />
          </IonAvatar>
          <IonText color="primary">
            <h4>
              Hi{" "}
              {activeUser.username !== ""
                ? activeUser.username
                : activeUser.firstName}
              <IonText color="secondary">.</IonText>
            </h4>
          </IonText>
        </div>
        {settings.map((st) => (
          <IonButton
            className={st.isDivided ? "ion-margin-bottom" : ""}
            fill={st.fill || "outline"}
            color={st.color || "medium"}
            expand="block"
            key={st.label}
            onClick={() =>
              actions.showModal({
                modal: st.modal,
                formKey: st.formKey,
              })
            }
          >
            <IonIcon slot="start" icon={st.icon} />
            <IonLabel>{st.label}</IonLabel>
          </IonButton>
        ))}
        <div className={styles.footerInformation}>
          <IonButton
            fill="clear"
            color="secondary"
            onClick={() => actions.processLogoutStart({ history })}
          >
            Log Out
          </IonButton>
          <IonText color="medium">
            <small>ourBrain ltd. </small>
          </IonText>
          <div className={styles.infoSecure}>
            <IonIcon
              icon={lockClosed}
              color="medium"
              className="ion-align-self-center"
            />
            <IonText color="medium">
              <small> Your data is secure </small>
            </IonText>
          </div>
        </div>
        <Feedback isOpen={isModalOpen["feedback"]} actions={actions} />
        <BugReport isOpen={isModalOpen["bugreport"]} actions={actions} />
        <UserDetails
          isOpen={isModalOpen["details"]}
          formKey={formKey}
          actions={actions}
          activeUser={activeUser}
          token={token}
          loading={props.loading}
        />
        <References
          isOpen={isModalOpen["references"]}
          styles={styles}
          actions={actions}
        />
      </OurBrainContent>
      <TabBar />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    loading: state.profile.loading,
    activeUser: state.auth.user,
    token: state.auth.token,
    isModalOpen: state.profile.isModalOpen,
    formKey: state.profile.formKey,
    showSuccess: state.profile.showSuccess,
    popupMessage: state.profile.popupMessage,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...profileActions, ...authActions },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
