import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonPage,
} from "@ionic/react";
import { arrowForwardCircleOutline } from "ionicons/icons";
import { startCase } from "lodash";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainCard, OurBrainContent } from "../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import partnershipActions, {
  IPartnershipActions,
} from "../../../redux/actions";
import { IPartnershipDefaultState } from "../../../redux/reducer";
import styles from "./index.module.scss";

const partnershipBeginInformation: IInformation = {
  title: "Partnership Type",
  paragraphs: ["What kind of connection are you looking to make"],
};

type PartnershipTypeProps = {
  partnership: IPartnershipDefaultState;
  actions: IPartnershipActions;
};
const PartnershipType: FC<PartnershipTypeProps> = ({
  partnership,
  actions,
}) => {
  const { preferences } = partnership;

  const { push } = useHistory();
  useEffect(() => {
    if (!partnership.beganPartnershipRequest) {
      push("/app/partnership");
    }
    actions.findAllPreferencesStart();
  }, [actions, partnership.beganPartnershipRequest, push]);

  return (
    <IonPage>
      <OurBrainContent className={styles.partnershipBeginContainer}>
        <OurBrainInformation information={partnershipBeginInformation} />
        {preferences.map(({ type, description, _id }) => (
          <OurBrainCard
            key={type}
            color="primary"
            link={`/app/partnership/preference?type=${type}&preferenceId=${_id}&slide=0`}
          >
            <IonCardHeader>
              <IonCardTitle>
                {startCase(type)}
                <IonIcon
                  className="ion-margin-horizontal"
                  icon={arrowForwardCircleOutline}
                />
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>{description}</IonCardContent>
          </OurBrainCard>
        ))}
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    activeUser: state.auth.user,
    partnership: state.partnership,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PartnershipType);
