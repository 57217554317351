import { IModalBase } from "../common";
import { IPartnershipModel } from "../partnership";

export interface IUser {
  firstName: string;
  lastName: string;
  username: string;
  avatarUrl: string;
  demographic: IUserDemographic;
  config: IUserConfig;
  partnerships: IPartnershipModel["_id"][];
  partnershipsNo: number;
}

export interface IUserModel extends IUser, IModalBase {}

export interface IUserConfig {
  isActive: boolean;
  isBlocked: boolean;
  isDeleted: boolean;
  isReported: boolean;
  completedSignUp: boolean;
  requestedPartnership: boolean;
  anonymityLevel: number;
  homePopup: string;
}

export enum Gender {
  Male = "male",
  Female = "female",
  Abstain = "abstain",
  Other = "other",
}

export interface IUserDemographic {
  gender: Gender;
  city: string;
  dateOfBirth: Date;
}

export interface IUserProfileBase {
  email: string;
  phone?: number;
  passwordHash: string;
  role: string[];
}

export interface IUserProfile extends IUserProfileBase {}

export interface IUserProfile_populated extends IUserProfileBase {
  userId: IUserModel;
}

export interface IUserProfileModel extends IUserProfile, IModalBase {}

export interface IUserProfileModel_populated extends IUserProfile_populated {}
