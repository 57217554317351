import { IAction } from "../../../config/common";
import {
  LEAVE_ROOM,
  LOAD_PARTNERSHIP_MESSAGES_START,
  LOAD_USER_PARTNERSHIPS_START,
  PARTNERSHIP_RESET_STATE,
  PROCESS_DELETE_PARTNERSHIP_START,
  PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START,
  PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START,
  PROCESS_FIND_ALL_PREFERENCES_START,
  PROCESS_LOAD_TALKING_POINT_START,
  PROCESS_NEW_MESSAGE_START,
  PROCESS_REQUEST_NEW_PARTNERSHIP_START,
  PROCESS_REQUEST_TALKING_POINT_START,
  PROCESS_SUBMIT_CHECK_IN_START,
  PROCESS_UPDATE_PARTNERSHIP_START,
  REPORT_PARTNERSHIP_START,
  SET_PARTNERSHIP_STATE,
  SET_SELECTED_MESSAGE,
  UPDATE_PARTNERSHIP_START,
  UPDATE_SELECTED_PARTNERSHIP,
  UPDATE_USER_PREFERENCE_ANSWER,
} from "./constants";

const loadPartnershipMessagesStart: any = (payload: string): IAction => {
  return { type: LOAD_PARTNERSHIP_MESSAGES_START, payload };
};
const loadPartnershipCheckInsStart: any = (payload: string): IAction => {
  return { type: PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START, payload };
};
const processSubmitPartnershipCheckIn: any = (payload: string): IAction => {
  return { type: PROCESS_SUBMIT_CHECK_IN_START, payload };
};
const loadUserPartnershipsStart: any = (payload: string): IAction => {
  return { type: LOAD_USER_PARTNERSHIPS_START, payload };
};
const processUpdatePartnershipStart: any = (payload: string): IAction => {
  return { type: PROCESS_UPDATE_PARTNERSHIP_START, payload };
};
const processDeletePartnershipStart: any = (payload: string): IAction => {
  return { type: PROCESS_DELETE_PARTNERSHIP_START, payload };
};
export const processNewMessageStart: any = (payload: any): IAction => {
  return { type: PROCESS_NEW_MESSAGE_START, payload };
};
export const processRequestNewPartnershipStart: any = (
  payload: any
): IAction => {
  return { type: PROCESS_REQUEST_NEW_PARTNERSHIP_START, payload };
};
export const setSelectedMessage: any = (payload: any): IAction => {
  return { type: SET_SELECTED_MESSAGE, payload };
};
export const leaveRoom: any = (payload: any): IAction => {
  return { type: LEAVE_ROOM, payload };
};
export const findAllPreferencesStart: any = (payload: any): IAction => {
  return { type: PROCESS_FIND_ALL_PREFERENCES_START, payload };
};
export const findAllPreferenceQuestionsStart: any = (payload: any): IAction => {
  return { type: PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START, payload };
};
export const updateUserPreferenceAnswer: any = (payload: any): IAction => {
  return { type: UPDATE_USER_PREFERENCE_ANSWER, payload };
};
export const partnershipReset: any = (payload: any): IAction => {
  return { type: PARTNERSHIP_RESET_STATE, payload };
};
export const loadTalkingPointStart: any = (payload: any): IAction => {
  return { type: PROCESS_LOAD_TALKING_POINT_START, payload };
};
export const requestTalkingPointStart: any = (payload: any): IAction => {
  return { type: PROCESS_REQUEST_TALKING_POINT_START, payload };
};
export const updateSelectedPartnership: any = (payload: any): IAction => {
  return { type: UPDATE_SELECTED_PARTNERSHIP, payload };
};
export const setPartnershipState: any = (payload: any): IAction => {
  return { type: SET_PARTNERSHIP_STATE, payload };
};
export const reportPartnershipStart: any = (payload: any): IAction => {
  return { type: REPORT_PARTNERSHIP_START, payload };
};
export const updatePartnershipStart: any = (payload: any): IAction => {
  return { type: UPDATE_PARTNERSHIP_START, payload };
};

export interface IPartnershipActions {
  loadPartnershipMessagesStart: Function;
  loadUserPartnershipsStart: Function;
  processNewMessageStart: Function;
  loadPartnershipUsersStart: Function;
  setSelectedMessage: Function;
  leaveRoom: Function;
  processDeletePartnershipStart: Function;
  processUpdatePartnershipStart: Function;
  processRequestNewPartnershipStart: Function;
  findAllPreferencesStart: Function;
  findAllPreferenceQuestionsStart: Function;
  updateUserPreferenceAnswer: Function;
  loadPartnershipCheckInsStart: Function;
  processSubmitPartnershipCheckIn: Function;
  partnershipReset: Function;
  loadTalkingPointStart: Function;
  requestTalkingPointStart: Function;
  updateSelectedPartnership: Function;
  setPartnershipState: Function;
  reportPartnershipStart: Function;
  updatePartnershipStart: Function;
}

export default {
  loadPartnershipMessagesStart,
  loadUserPartnershipsStart,
  processNewMessageStart,
  setSelectedMessage,
  leaveRoom,
  processDeletePartnershipStart,
  processUpdatePartnershipStart,
  processRequestNewPartnershipStart,
  findAllPreferencesStart,
  findAllPreferenceQuestionsStart,
  updateUserPreferenceAnswer,
  loadPartnershipCheckInsStart,
  processSubmitPartnershipCheckIn,
  partnershipReset,
  loadTalkingPointStart,
  requestTalkingPointStart,
  updateSelectedPartnership,
  setPartnershipState,
  reportPartnershipStart,
  updatePartnershipStart,
};
