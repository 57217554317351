import { IonPage } from "@ionic/react";
import React, { FC } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  OurBrainButton,
  OurBrainContent,
  OurBrainLogo,
} from "../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import partnershipActions, {
  IPartnershipActions,
} from "../../../redux/actions";
import { IPartnershipDefaultState } from "../../../redux/reducer";
import styles from "./index.module.scss";

const thankYouTitleInformation: IInformation = {
  title: "Thank you",
};
const thankYouParagraphInformation: IInformation = {
  paragraphs: [
    "You have completed your partnership request. We aim to come back to you within 48 hours. Please check your email for updates.",
  ],
};

type PartnershipThankYouProps = {
  actions: IPartnershipActions;
  partnership: IPartnershipDefaultState;
};
const PartnershipThankYou: FC<PartnershipThankYouProps> = (props) => {
  return (
    <IonPage>
      <OurBrainContent className={styles.partnershipBeginContainer}>
        <OurBrainInformation information={thankYouTitleInformation} />
        <OurBrainLogo />
        <OurBrainInformation information={thankYouParagraphInformation} />
        <OurBrainButton
          color="secondary"
          fill="clear"
          expand="block"
          link={`/app/partnership`}
        >
          Home
        </OurBrainButton>
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions }, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipThankYou);
