import { IonSpinner } from "@ionic/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { Provider, ReactReduxContext } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./config/env";
import { SENTRY_URL } from "./config/env";
import configureStore, { history } from "./rootRedux/store";
import * as serviceWorker from "./serviceWorker";
import "./theme/overrides.scss";

const { store, persistor } = configureStore();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <CookiesProvider>
        <PersistGate loading={<IonSpinner />} persistor={persistor}>
          <App isAuthenticated />
        </PersistGate>
      </CookiesProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
