import { IonPage } from "@ionic/react";
import { duplicate, eye, volumeLow } from "ionicons/icons";
import React, { FC } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  ActionMenuItem,
  IActionMenuItem,
  NavBar,
  OurBrainContent,
} from "../../../../components";
import { actions as authActions } from "../../../Auth/redux";

type DashboardProps = {
  actions: any;
  auth: any;
};

const adminSections: IActionMenuItem[] = [
  {
    admin: true,
    color: "primary",
    title: "Create partnerships",
    icon: duplicate,
    link: "/admin/create",
  },
  {
    admin: true,
    color: "secondary",
    title: "Moderate check-ins",
    icon: eye,
    link: "/admin/moderate",
  },
  {
    admin: true,
    color: "light",
    title: "Moderate feed posts",
    icon: volumeLow,
    link: "/admin/moderate-posts",
  },
  {
    admin: true,
    color: "tertiary",
    title: "View live partnerships",
    icon: eye,
    link: "/admin/partnerships",
  },
];

const AdminDashboard: FC<DashboardProps> = ({ actions, auth }) => {
  return (
    <IonPage>
      <NavBar backButton title="Admin" backLink="/app" actions={actions} />
      <OurBrainContent>
        {adminSections.map((section: any) => (
          <ActionMenuItem
            key={section.title}
            section={section}
            isAdmin={auth.isAdmin}
          />
        ))}
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    user: state.auth.user, // CHANGE
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
