import { IonAvatar, IonCardHeader, IonImg, IonItemDivider } from "@ionic/react";
import { IonCardSubtitle } from "@ionic/react/dist";
import React, { FC } from "react";
import { OurBrainCard } from "../../../../components";
import { IUserPartnershipModel } from "../../../../interfaces/interfaces";
import { IPartnershipActions } from "../../redux/actions";
import styles from "./index.module.scss";

type PartnershipCardProps = {
  userPartnership: IUserPartnershipModel;
  activeUser: any;
  actions: IPartnershipActions;
  index: number;
  isDormant: boolean;
};
const PartnershipCard: FC<PartnershipCardProps> = ({
  userPartnership,
  activeUser,
  index,
  isDormant,
}) => {
  const partnerInfo = userPartnership.partnershipId.usersInfo?.filter(
    (user: any) => user._id !== activeUser._id
  )[0];

  if (userPartnership.partnershipId.isDormant !== isDormant) {
    return <div></div>;
  }

  return (
    <OurBrainCard
      index={index}
      link={`/app/partnership/${userPartnership.partnershipId.class}?index=${index}`}
      style={{ pointer: "cursor" }}
      color={userPartnership.partnershipId.isDormant ? "light" : "primary"}
    >
      <IonCardHeader className="ion-align-items-center ion-justify-content-center">
        <div className={styles.partnershipCardContentContainer}>
          <IonAvatar className="ion-margin-end">
            <IonImg src={partnerInfo?.avatarUrl} alt="activeUser-avatar" />
          </IonAvatar>
          <IonCardSubtitle>
            <div className={styles.subtitle}>{partnerInfo?.username}</div>
          </IonCardSubtitle>
        </div>
      </IonCardHeader>
      <IonItemDivider color="secondary" />
    </OurBrainCard>
  );
};

export default PartnershipCard;
