export const LOAD_ALL_USERS_START: string = "PROCESS_LOAD_ALL_USERS_START";
export const LOAD_ALL_USERS_SUCCESS: string = "PROCESS_LOAD_ALL_USERS_SUCCESS";
export const LOAD_ALL_USERS_FAIL: string = "PROCESS_LOAD_ALL_USERS_FAIL";

export const CREATE_PARTNERSHIP_START: string =
  "PROCESS_CREATE_PARTNERSHIP_START";
export const CREATE_PARTNERSHIP_SUCCESS: string =
  "PROCESS_CREATE_PARTNERSHIP_SUCCESS";
export const CREATE_PARTNERSHIP_FAIL: string =
  "PROCESS_CREATE_PARTNERSHIP_FAIL";

export const GET_PARTNERSHIP_START: string = "PROCESS_GET_PARTNERSHIP_START";
export const GET_PARTNERSHIP_SUCCESS: string =
  "PROCESS_GET_PARTNERSHIP_SUCCESS";
export const GET_PARTNERSHIP_FAIL: string = "PROCESS_GET_PARTNERSHIP_FAIL";

export const LOAD_ALL_USER_PREFERENCES_START: string =
  "PROCESS_LOAD_ALL_USER_PREFERENCES_START";
export const LOAD_ALL_USER_PREFERENCES_SUCCESS: string =
  "PROCESS_LOAD_ALL_USER_PREFERENCES_SUCCESS";
export const LOAD_ALL_USER_PREFERENCES_FAIL: string =
  "PROCESS_LOAD_ALL_USER_PREFERENCES_FAIL";

export const LOAD_PARTNERSHIPS_START: string = "LOAD_PARTNERSHIPS_START";
export const LOAD_PARTNERSHIPS_SUCCESS: string = "LOAD_PARTNERSHIPS_SUCCESS";
export const LOAD_PARTNERSHIPS_FAIL: string = "LOAD_PARTNERSHIPS_FAIL";

export const LOAD_USER_PREFERENCE_ANSWERS_START: string =
  "PROCESS_LOAD_USER_PREFERENCE_ANSWERS_START";
export const LOAD_USER_PREFERENCE_ANSWERS_SUCCESS: string =
  "PROCESS_LOAD_USER_PREFERENCE_ANSWERS_SUCCESS";
export const LOAD_USER_PREFERENCE_ANSWERS_FAIL: string =
  "PROCESS_LOAD_USER_PREFERENCE_ANSWERS_FAIL";

export const MODERATE_CHECK_INS_START: string =
  "PROCESS_MODERATE_CHECK_INS_START";
export const MODERATE_CHECK_INS_SUCCESS: string =
  "PROCESS_MODERATE_CHECK_INS_SUCCESS";
export const MODERATE_CHECK_INS_FAIL: string =
  "PROCESS_MODERATE_CHECK_INS_FAIL";

export const GET_USER_EMAIL_START: string = "PROCESS_GET_USER_EMAIL_START";
export const GET_USER_EMAIL_SUCCESS: string = "PROCESS_GET_USER_EMAIL_SUCCESS";
export const GET_USER_EMAIL_FAIL: string = "PROCESS_GET_USER_EMAIL_FAIL";

export const UPDATE_PARTNERSHIP_CHECK_IN_START: string =
  "PROCESS_UPDATE_PARTNERSHIP_CHECK_IN_START";
export const UPDATE_PARTNERSHIP_CHECK_IN_SUCCESS: string =
  "PROCESS_UPDATE_PARTNERSHIP_CHECK_IN_SUCCESS";
export const UPDATE_PARTNERSHIP_CHECK_IN_FAIL: string =
  "PROCESS_UPDATE_PARTNERSHIP_CHECK_IN_FAIL";

export const MODERATE_POSTS_START: string = "MODERATE_POSTS_START";
export const MODERATE_POSTS_SUCCESS: string = "MODERATE_POSTS_SUCCESS";
export const MODERATE_POSTS_FAIL: string = "MODERATE_POSTS_FAIL";

export const PROCESS_SELECT_USER: string = "PROCESS_SELECT_USER";

export const SHOW_ADMIN_MODAL: string = "SHOW_ADMIN_MODAL";

export const CANCEL_ACTION: string = "CANCEL_ACTION";

export const ADD_PARTNERSHIP_CHECK_IN_TO_LIST: string =
  "ADD_PARTNERSHIP_CHECK_IN_TO_LIST";

export const ADMIN_RESET_STATE: string = "ADMIN_RESET_STATE";

export const SET_ADMIN_STATE: string = "SET_ADMIN_STATE";
