import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from "redux";
import { actions as authActions } from "../../modules/Auth/redux";
import { connect } from "react-redux";

export interface ProtectedRouteProps extends RouteProps {
    admin?: boolean
    noAuth?: boolean
    // restrictedPath: string;
}

const AdminRoute: FC<ProtectedRouteProps> = ({component, isAuthenticated, isAdmin = true, ...rest}: any) => {

    const adminComponent = (props: any) => (
        isAuthenticated && isAdmin
            ? React.createElement(component, props)
            : (<Redirect to={{pathname: '/app'}}/>)
    )


    return <Route {...rest} render={adminComponent}/>;
};

const mapStateToProps = (state: any) => {
    return {
        ...state,
        isAuthenticated: state.auth.isAuthenticated,
        isAdmin: state.auth.isAdmin,
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        actions: bindActionCreators({...authActions}, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminRoute);
