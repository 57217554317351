import { AnyAction } from "redux";
import { call, debounce, put, takeEvery } from "redux-saga/effects";
import ourBrainApi from "../../api/createApiService";
import { ForbiddenError, UnauthorizedError } from "../../api/errors";
import { createAction } from "../../rootRedux/rootActions";
import { PROCESS_LOGOUT_START } from "../Auth/redux/constants";
import {
  GTAG_EVENT,
  LOAD_FORM_FAIL,
  LOAD_FORM_START,
  LOAD_FORM_SUCCESS,
} from "./constants";

function* processLoadForm({ payload }: AnyAction): Generator {
  const { formKey } = payload;
  try {
    const { feedbackForm, feedbackFormQuestions }: any = yield call(
      [ourBrainApi, "get"],
      `/feedback/${formKey}/form`
    );
    yield put(
      createAction(LOAD_FORM_SUCCESS, { feedbackForm, feedbackFormQuestions })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, {}));
      return;
    }
    yield put(createAction(LOAD_FORM_FAIL, { showError: true }));
    return;
  }
  return;
}

function* gtagEvent({ payload }: AnyAction): Generator {
  gtag("event", payload.eventName, payload);
  return;
}

export default [
  takeEvery(LOAD_FORM_START, processLoadForm),
  debounce(500, GTAG_EVENT, gtagEvent),
];
