import React, { FC } from 'react'

import './index.scss'

const Divider: FC<any> = () => {
    return (
        <div id="or">OR</div>
    )
}

export default Divider
