import {
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRange,
  IonRow,
  useIonViewWillEnter,
} from "@ionic/react";
import classNames from "classnames";
import { cloudOutline, sunnyOutline } from "ionicons/icons";
import React, { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import { IUserModel } from "../../../../../interfaces/interfaces";
import { IAuthState } from "../../../redux/reducer";
import styles from "../index.module.scss";

const Profile: FC<any> = ({ name, src }) => {
  return (
    <div className={styles.profileContainer}>
      <IonCard className="ion-padding ion-text-center ion-justify-content-center">
        <IonCardHeader>
          <IonCardSubtitle>Your Profile</IonCardSubtitle>
          <h5>{name}</h5>
        </IonCardHeader>
        <IonAvatar
          className="ion-justify-content-center"
          style={{ display: "flex", margin: "0 auto" }}
        >
          <IonImg src={src} />
        </IonAvatar>
      </IonCard>
    </div>
  );
};
const anonymityInformation: IInformation = {
  title: "Your anonymity",
  paragraphs: [
    "The privacy and comfort of our users is very important to us, so your anonymity is completely controlled by you.",
    "Please choose a level of 1 or 2:",
  ],
};

const anonymityInformationMap: { [key: number]: string } = {
  1: "You would like to remain completely anonymous. Other users will only be able to see your username.",
  2: "You don't want to be anonymous. Other users can see your first name.",
};

type AnonymityProps = {
  activeUser: IUserModel;
  form: any;
  auth: IAuthState;
  hidden: boolean;
  currentSlideIndex: number;
};

const Anonymity: FC<AnonymityProps> = ({
  activeUser,
  form,
  auth,
  hidden,
  currentSlideIndex,
}) => {
  const { control, getValues } = form;
  const [anonymityLevel, setAnonymityLevel] = useState<any>(1);
  const [stateUsername, setStateUsername] = useState("");
  const [, setStateAvatarUrl] = useState(
    auth.currentPhotoSelect || activeUser.avatarUrl
  );
  useEffect(() => {
    setStateAvatarUrl(getValues()["avatarUrl"]);
    setStateUsername(getValues()["username"]);
  }, [currentSlideIndex]);

  useIonViewWillEnter(() => {
    setStateAvatarUrl(activeUser.avatarUrl);
  });

  return (
    <div
      hidden={hidden}
      className={classNames("ion-justify-content-between", styles.setHeight)}
    >
      <OurBrainInformation information={anonymityInformation} />
      <Controller
        name="config.anonymityLevel"
        control={control}
        as={({ onChange }: any) => (
          <IonRange
            min={1}
            max={2}
            step={1}
            value={anonymityLevel}
            snaps={true}
            ticks={true}
            onIonChange={(e) => {
              setAnonymityLevel(e.detail.value);
              onChange(e);
            }}
          >
            <IonIcon size="small" slot="start" icon={cloudOutline} />
            <IonIcon slot="end" icon={sunnyOutline} />
          </IonRange>
        )}
      />
      <IonGrid>
        <IonRow className="ion-justify-content-center ion-text-left">
          <IonCol sizeMd="2" size="2">
            <h3 style={{ margin: 0 }}>{anonymityLevel}</h3>
          </IonCol>
          <IonCol sizeMd="10" size="10" className="ion-justify-content-center">
            <OurBrainInformation
              information={{
                paragraphs: [anonymityInformationMap[anonymityLevel]],
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
      {anonymityLevel === 1 && (
        <Profile src={auth.currentPhotoSelect} name={stateUsername} />
      )}
      {anonymityLevel === 2 && (
        <Profile src={auth.currentPhotoSelect} name={activeUser.firstName} />
      )}
    </div>
  );
};

export default Anonymity;
