import { IonPage } from "@ionic/react";
import React, { FC } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { NavBar, OurBrainContent, OurBrainLogo } from "../../../../components";
import OurBrainForm from "../../../../components/OurBrainForm";
import OurBrainInformation, {
  IInformation,
} from "../../../../components/OurBrainInformation";
import TabBar from "../../../../components/TabBar";

type ServicesDashboardProps = {
  actions: any;
};

const servicesInformation: IInformation = {
  title: "Coming soon",
  paragraphs: [
    "We are working on bringing a range of leading mental health services to our users at ourBrain, as we grow.",
  ],
};
const ServicesDashboard: FC<ServicesDashboardProps> = ({ actions }) => {
  return (
    <IonPage>
      <NavBar title="Services" />
      <OurBrainContent>
        <OurBrainInformation information={servicesInformation} />
        <OurBrainLogo />
        <OurBrainForm
          noBack
          noTitle
          formKey={process.env.REACT_APP_BRAIN_FORM_SERVICES!}
        />
      </OurBrainContent>
      <TabBar />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicesDashboard);
