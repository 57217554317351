import { IFeedPostModel } from "../../../interfaces/interfaces/feedPost";
import {
  CREATE_POST_FAIL,
  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  GET_FEED_FAIL,
  GET_FEED_START,
  GET_FEED_SUCCESS,
  SAVE_POST_FAIL,
  SAVE_POST_START,
  SAVE_POST_SUCCESS,
  SET_HUB_STATE,
} from "./constants";

export interface IHubState {
  loading: boolean;
  feedPosts: IFeedPostModel[]; // TODO
  savedPosts: string[]; // TODO
  showSuccess: string;
}

const defaultState: IHubState = {
  loading: false,
  feedPosts: [],
  savedPosts: [],
  showSuccess: "",
};

function reducer(state = defaultState, action: any) {
  const { type, payload } = action as any;
  switch (type) {
    case GET_FEED_START:
      return {
        ...state,
        loading: true,
      };
    case GET_FEED_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case GET_FEED_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CREATE_POST_START:
      return {
        ...state,
        loading: true,
      };
    case CREATE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case CREATE_POST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SAVE_POST_START:
      return {
        ...state,
        loading: true,
      };
    case SAVE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...payload,
      };
    case SAVE_POST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case SET_HUB_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export default reducer;
