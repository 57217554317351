export * from "./email";
export * from "./feedbackForm";
export * from "./feedbackFormAnswer";
export * from "./feedbackFormQuestion";
export * from "./notification";
export * from "./partnership";
export * from "./preference";
export * from "./preferenceQuestion";
export * from "./question";
export * from "./referral";
export * from "./user";
export * from "./userpartnership";
export * from "./userPreference";
export * from "./userPreferenceAnswer";
export * from "./verificationCode";

export type Populate<T> = T extends string ? string : T;
