import {
  IFeedbackFormModel,
  IQuestionModel,
} from "../../interfaces/interfaces";
import {
  LOAD_FORM_FAIL,
  LOAD_FORM_START,
  LOAD_FORM_SUCCESS,
  SET_GLOBAL_STATE,
  SUBMIT_FORM_FAIL,
  SUBMIT_FORM_START,
  SUBMIT_FORM_SUCCESS,
} from "./constants";

export interface IGlobalState {
  loading: boolean;
  feedbackForm?: IFeedbackFormModel;
  feedbackFormQuestions?: IQuestionModel[];
  showSuccess?: boolean;
  showError?: boolean;
}

const defaultState: IGlobalState = {
  loading: false,
};

function reducer(state = defaultState, action: any) {
  const { payload, type } = action as any;
  switch (type) {
    case LOAD_FORM_START:
      return {
        ...state,
        ...payload,
        loading: true,
        feedbackForm: {},
        feedbackFormQuestions: [],
      };
    case LOAD_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        feedbackForm: payload.feedbackForm,
        feedbackFormQuestions: payload.feedbackFormQuestions,
      };
    case LOAD_FORM_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case SUBMIT_FORM_START:
      return {
        ...state,
        ...payload,
        loading: true,
        showSuccess: false,
      };
    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        showSuccess: true,
      };
    case SUBMIT_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case SET_GLOBAL_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export default reducer;
