import { IonButton, IonContent, IonModal } from "@ionic/react";
import React, { FC } from "react";
import { OurBrainButton, OurBrainLogo } from "../../../../../components";

const termsLink: string =
  "https://static.ourbrain.io/legal/documents/termsandconditions.pdf";
const privacyLink: string =
  "https://static.ourbrain.io/legal/documents/privacypolicy.pdf";
const communityLink: string =
  "https://static.ourbrain.io/legal/documents/communityrules.pdf";

const References: FC<any> = ({ isOpen, styles, actions }) => {
  return (
    <IonModal isOpen={isOpen}>
      <IonContent>
        <div className={styles.referencesContainer}>
          <OurBrainLogo width="100px" />
          <OurBrainButton fill="clear" expand="full" link={termsLink}>
            Terms & Conditions
          </OurBrainButton>
          <OurBrainButton fill="clear" expand="full" link={privacyLink}>
            Privacy Policy
          </OurBrainButton>
          <OurBrainButton fill="clear" expand="full" link={communityLink}>
            Community Rules
          </OurBrainButton>

          <IonButton
            expand="block"
            fill="clear"
            color="secondary"
            size="small"
            onClick={() => actions.showModal({ modal: "references" })}
          >
            Back
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default References;
