export const passwordRegExp: RegExp = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})`
);
export const nameRegExp: RegExp = /^([^0-9]*)$/;
export const emailRegExp: RegExp = /(.+)@(.+){2,}\.(.+){2,}/;

export interface IAction {
  type: string;
  payload: string;
}

interface IAssetPathPayload {
  name: string;
  fileType?: string;
  bucket?: string;
}

export function getAssetPath(options: IAssetPathPayload) {
  const { fileType, name, bucket } = options;
  return `https://${
    bucket || "ourbrain"
  }.s3.eu-central-1.amazonaws.com/${name}.${fileType || "png"}`;
}
