import { IonGrid, IonModal } from "@ionic/react";
import React, { FC } from "react";


const UserDetails: FC<any> = ({isOpen, actions}) => {
    return (
        <IonModal onDidDismiss={() => actions.showAdminModal()} isOpen={isOpen}>
            <IonGrid>
            </IonGrid>
        </IonModal>
    )
}

export default UserDetails
