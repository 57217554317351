import {
  IonApp,
  IonPage,
  isPlatform,
  mdTransitionAnimation,
  setupConfig,
} from "@ionic/react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { FC } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainContent, OurBrainLogo } from "./components";
import LoggedInRoute from "./components/LoggedInRoute";
import LoggedOutRoute from "./components/LoggedOutRoute";
import OurBrainInformation from "./components/OurBrainInformation";
import AdminDashboard from "./modules/Admin/pages/AdminDashboard";
import CreatePartnership from "./modules/Admin/pages/CreatePartnership";
import ModerateCheckIns from "./modules/Admin/pages/ModerateCheckIns";
import ModeratePosts from "./modules/Admin/pages/ModeratePosts";
import ViewLivePartnerships from "./modules/Admin/pages/ViewLivePartnerships";
// @ts-ignore
import Login from "./modules/Auth/pages/Login";
import Start from "./modules/Auth/pages/PasswordReset/Start";
import Success from "./modules/Auth/pages/PasswordReset/Success";
import Verify from "./modules/Auth/pages/PasswordReset/Verify";
import SignUp from "./modules/Auth/pages/SignUp";
import Welcome from "./modules/Auth/pages/Welcome";
import { actions as authActions } from "./modules/Auth/redux";
import { IAuthActions } from "./modules/Auth/redux/actions";
import { IAuthState } from "./modules/Auth/redux/reducer";
import CookieDeclaration from "./modules/Home/pages/CookieDeclaration";
import HelpPage from "./modules/Home/pages/HelpPage";
import HomeDashboard from "./modules/Home/pages/HomeDashboard";
import Referrals from "./modules/Home/pages/Referrals";
import CreatePost from "./modules/Hub/pages/CreatePost";
import Feed from "./modules/Hub/pages/Feed";
import SavedPosts from "./modules/Hub/pages/SavedPosts";
import Partnership from "./modules/Partnerships/pages/Partnership";
import PartnershipDashboard from "./modules/Partnerships/pages/PartnershipDashboard";
import PartnershipBegin from "./modules/Partnerships/pages/RequestPartnership/PartnershipBegin";
import PartnershipDisclaimer from "./modules/Partnerships/pages/RequestPartnership/PartnershipInformation";
import PartnershipPreference from "./modules/Partnerships/pages/RequestPartnership/PartnershipPreference";
import PartnershipThankYou from "./modules/Partnerships/pages/RequestPartnership/PartnershipThankYou";
import PartnershipType from "./modules/Partnerships/pages/RequestPartnership/PartnershipType";
import Settings from "./modules/Profile/pages/Settings";
import { actions as globalActions } from "./modules/reduxGlobal/";
import { IGlobalActions } from "./modules/reduxGlobal/actions";
import ServicesDashboard from "./modules/Services/pages/ServicesDashboard";
/* Theme variables */
import "./theme/colors.module.scss";
import "./theme/components.module.scss";
import "./theme/text.module.scss";

export const mode: "ios" | "md" | undefined =
  isPlatform("ios") || isPlatform("desktop") ? "ios" : "md";
setupConfig({
  mode,
  swipeBackEnabled: false,
  // @ts-ignore
  navAnimation: mdTransitionAnimation,
  animated: false,
  refreshingSpinner: "circles",
  // loadingSpinner: "dots",
  hideCaretOnScroll: true,
});

type AppProps = {
  isAuthenticated?: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
  token?: string;
  actions?: IAuthActions & IGlobalActions;
  props?: any;
  authLoading?: boolean;
  partnershipLoading?: boolean;
  profileLoading?: boolean;
  auth?: IAuthState;
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const App: FC<AppProps> = ({ actions, auth }) => {
  const location = useLocation();
  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200,
    },
  });

  return (
    <IonApp>
      <Route
        exact
        path="/"
        render={() => (
          <IonPage>
            <OurBrainContent sizeMd="3">
              <OurBrainLogo />
              <OurBrainInformation
                information={{
                  title: "We're really sorry..",
                  paragraphs: [
                    "Due to unforeseen circumstances, it is with great sadness that we have to announce that ourBrain will cease operations for 1-2 months.",
                    "Until we are in a position to resolve certain aspects of our offering, we are not able to effectively operate the platform at this time.",
                    "This was not a decision we took lightly. We watched in awe as people who had never met genuinely connected in ways that were not possible before. For this and so many other reasons we want to apologise personally to our loyal users.",
                    "Please rest assured that all your data will remain safely encrypted and backed-up in the event we resume operations.",
                    "For any questions relating to your data please don't hesitate to contact us.",
                  ],
                }}
              />
              <a href="mailto:robert@ourbrain.io">Contact us</a>
              <OurBrainInformation
                information={{
                  paragraphs: [
                    "",
                    "From everyone here at ourBrain, we want to thank you for your support and encouragement. The problem we are trying to tackle continues to worsen during these times and we encourage everyone to double check on all those around you.",
                    "Keep the conversation alive!",
                    "The ourBrain Team",
                  ],
                }}
              />
            </OurBrainContent>
          </IonPage>
        )}
      />
      <Route render={() => <Redirect to="/" />} />
    </IonApp>
  );

  return (
    <IonApp>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <LoggedOutRoute exact path="/sign-up" component={SignUp} />
            <LoggedOutRoute exact path="/log-in" component={Login} />
            <LoggedOutRoute exact path="/reset" component={Start} />
            <LoggedOutRoute
              exact
              path="/reset/verify/:verificationCode"
              component={Verify}
            />
            <LoggedOutRoute exact path="/reset/success" component={Success} />
            <LoggedOutRoute
              exact
              path="/cookie"
              component={CookieDeclaration}
            />

            <LoggedInRoute exact path="/app/welcome" component={Welcome} />
            <LoggedInRoute exact path="/app" component={HomeDashboard} />
            <LoggedInRoute exact path="/app/help" component={HelpPage} />
            <LoggedInRoute exact path="/app/referrals" component={Referrals} />
            <LoggedInRoute
              exact
              path="/app/partnership"
              component={PartnershipDashboard}
            />
            <LoggedInRoute
              exact
              path="/app/partnership/begin"
              component={PartnershipBegin}
            />
            <LoggedInRoute
              exact
              path="/app/partnership/type"
              component={PartnershipType}
            />
            <LoggedInRoute
              exact
              path="/app/partnership/confirm"
              component={PartnershipDisclaimer}
            />
            <LoggedInRoute
              exact
              path="/app/partnership/thank-you"
              component={PartnershipThankYou}
            />
            <LoggedInRoute
              exact
              path="/app/partnership/preference"
              component={PartnershipPreference}
            />
            <LoggedInRoute
              exact
              path="/app/partnership/:class"
              component={Partnership}
            />
            <LoggedInRoute exact path="/app/profile" component={Settings} />
            <LoggedInRoute
              exact
              path="/app/services"
              component={ServicesDashboard}
            />
            <LoggedInRoute exact path="/app/hub" component={Feed} />
            <LoggedInRoute
              exact
              path="/app/hub/create"
              component={CreatePost}
            />
            <LoggedInRoute exact path="/app/hub/saved" component={SavedPosts} />

            <LoggedInRoute
              exact
              path="/admin"
              component={AdminDashboard}
              admin
            />
            <LoggedInRoute
              exact
              path="/admin/create"
              component={CreatePartnership}
              admin
            />
            <LoggedInRoute
              exact
              path="/admin/moderate"
              component={ModerateCheckIns}
              admin
            />
            <LoggedInRoute
              exact
              path="/admin/moderate-posts"
              component={ModeratePosts}
              admin
            />
            <LoggedInRoute
              exact
              path="/admin/partnerships"
              component={ViewLivePartnerships}
              admin
            />

            <Route path="/" render={() => <Redirect to="/log-in" />} />
          </Switch>
        </animated.div>
      ))}
    </IonApp>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAdmin,
    isUser: state.auth.isUser,
    token: state.auth.token,
    authLoading: state.auth.loading,
    partnershipLoading: state.partnership.loading,
    profileLoading: state.profile.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...authActions, ...globalActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
