import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import ourBrainApi from "../../../api/createApiService";
import {
  BadRequestError,
  ForbiddenError,
  NotFound,
  UnauthorizedError,
} from "../../../api/errors";
import { PROCESS_UPDATE_USER_CONFIG } from "../../../config/constants";
import { createAction } from "../../../rootRedux/rootActions";
import { PROCESS_LOGOUT_START } from "../../Auth/redux/constants";
import { PROCESS_UPDATE_USER_SUCCESS } from "../../Profile/redux/constants";
import {
  LOAD_PARTNERSHIP_MESSAGES_FAIL,
  LOAD_PARTNERSHIP_MESSAGES_START,
  LOAD_PARTNERSHIP_MESSAGES_SUCCESS,
  LOAD_USER_PARTNERSHIPS_FAIL,
  LOAD_USER_PARTNERSHIPS_START,
  LOAD_USER_PARTNERSHIPS_SUCCESS,
  PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_FAIL,
  PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START,
  PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS,
  PROCESS_FIND_ALL_PREFERENCES_FAIL,
  PROCESS_FIND_ALL_PREFERENCES_START,
  PROCESS_FIND_ALL_PREFERENCES_SUCCESS,
  PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_FAIL,
  PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START,
  PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_SUCCESS,
  PROCESS_LOAD_TALKING_POINT_FAIL,
  PROCESS_LOAD_TALKING_POINT_START,
  PROCESS_LOAD_TALKING_POINT_SUCCESS,
  PROCESS_NEW_MESSAGE_START,
  PROCESS_REQUEST_NEW_PARTNERSHIP_FAIL,
  PROCESS_REQUEST_NEW_PARTNERSHIP_START,
  PROCESS_REQUEST_NEW_PARTNERSHIP_SUCCESS,
  PROCESS_REQUEST_TALKING_POINT_FAIL,
  PROCESS_REQUEST_TALKING_POINT_START,
  PROCESS_REQUEST_TALKING_POINT_SUCCESS,
  PROCESS_SUBMIT_CHECK_IN_FAIL,
  PROCESS_SUBMIT_CHECK_IN_START,
  PROCESS_SUBMIT_CHECK_IN_SUCCESS,
  REPORT_PARTNERSHIP_FAIL,
  REPORT_PARTNERSHIP_START,
  REPORT_PARTNERSHIP_SUCCESS,
  SCROLL_TO_BOTTOM,
  UPDATE_PARTNERSHIP_START,
  UPDATE_PARTNERSHIP_SUCCESS,
} from "./constants";

function* loadPartnershipMessages({ payload }: AnyAction): Generator {
  const { partnershipId, limit, scrollToBottom, setKeyboardFocus } = payload;
  if (typeof partnershipId === "undefined") {
    return;
  }
  let messages: any;
  try {
    messages = yield call(
      [ourBrainApi, "post"],
      `/partnership/${partnershipId}/messages`,
      {
        limit,
      }
    );
    yield put(createAction(LOAD_PARTNERSHIP_MESSAGES_SUCCESS, messages));
    if (limit === 30) {
      setKeyboardFocus();
      yield put(createAction(SCROLL_TO_BOTTOM, { scrollToBottom }));
    }
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(LOAD_PARTNERSHIP_MESSAGES_FAIL, error));
  }
  return;
}

function* loadPartnershipCheckIns({ payload }: AnyAction): Generator {
  const { partnershipId, limit, activeUserId, isDormant, seenInfo } = payload;
  let partnershipIdUrl: string = partnershipId;
  if (typeof partnershipId === "undefined") {
    partnershipIdUrl = "000000000000000000000000";
  }
  try {
    const { mappedPartnershipCheckIns }: any = yield call(
      [ourBrainApi, "post"],
      `/partnership/${partnershipIdUrl}/check-in`,
      { limit, activeUserId, isDormant, seenInfo }
    );
    yield put(
      createAction(PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS, {
        partnershipCheckIns: mappedPartnershipCheckIns,
      })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_FAIL, error));
  }
  return;
}

function* processSubmitCheckIn({ payload }: AnyAction): Generator {
  let partnershipCheckIns: any;
  const { partnershipId, partnership, partnershipCheckIn } = payload;
  try {
    partnershipCheckIns = yield call(
      [ourBrainApi, "post"],
      `/partnership/${partnershipId}/check-in/submit`,
      { partnership, partnershipCheckIn }
    );
    yield put(
      createAction(PROCESS_SUBMIT_CHECK_IN_SUCCESS, partnershipCheckIns)
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(PROCESS_SUBMIT_CHECK_IN_FAIL, error));
  }
  return;
}

function* loadUserPartnerships({ payload }: AnyAction): Generator {
  if (typeof payload === "undefined") {
    return;
  }
  const { userId } = payload;
  try {
    const { userPartnerships, config, partnershipsToAction }: any = yield call(
      [ourBrainApi, "get"],
      `/user/${userId}/partnerships`
    );
    yield put(
      createAction(LOAD_USER_PARTNERSHIPS_SUCCESS, {
        userPartnerships,
        partnershipsToAction,
      })
    );
    yield put(createAction(PROCESS_UPDATE_USER_CONFIG, config));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(LOAD_USER_PARTNERSHIPS_FAIL, error));
  }
  return;
}

function* requestNewPartnership({ payload }: AnyAction): Generator {
  if (typeof payload === "undefined") {
    return;
  }
  const { data, userId } = payload;
  try {
    const updatedUser = yield call(
      [ourBrainApi, "post"],
      `/user/${userId}/preference/create`,
      data
    );
    yield put(
      createAction(PROCESS_REQUEST_NEW_PARTNERSHIP_SUCCESS, updatedUser)
    );
    yield put(createAction(PROCESS_UPDATE_USER_SUCCESS, { user: updatedUser }));
    yield put(push("/app/partnership/thank-you"));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    if (error instanceof BadRequestError) {
      yield put(
        createAction(PROCESS_REQUEST_NEW_PARTNERSHIP_FAIL, {
          showError: error.message,
        })
      );
      yield put(push("/app/partnership"));
      return;
    } else {
      yield put(
        createAction(PROCESS_REQUEST_NEW_PARTNERSHIP_FAIL, {
          showError: "Error while creating partnership request",
        })
      );
    }
  }
}

function* processNewMessage({ payload }: AnyAction): Generator {
  const { scrollToBottom } = payload;
  yield scrollToBottom();
}

function* processFindAllPreferences({ payload }: AnyAction): Generator {
  let foundPreferences: any;
  try {
    foundPreferences = yield call([ourBrainApi, "get"], "/preference");
    yield put(
      createAction(PROCESS_FIND_ALL_PREFERENCES_SUCCESS, foundPreferences)
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(PROCESS_FIND_ALL_PREFERENCES_FAIL, error));
  }
}

function* processFindAllPreferenceQuestions({ payload }: AnyAction): Generator {
  let foundPreferenceQuestions: any;
  try {
    foundPreferenceQuestions = yield call(
      [ourBrainApi, "post"],
      `/preference/questions`,
      payload
    );
    yield put(
      createAction(
        PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_SUCCESS,
        foundPreferenceQuestions
      )
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_FAIL, error));
  }
}

function* processLoadTalkingPoint({ payload }: AnyAction): Generator {
  const { activeTalkingPoint, partnershipId } = payload;
  let foundTalkingPoint: any;
  try {
    foundTalkingPoint = yield call(
      [ourBrainApi, "get"],
      `/partnership/${partnershipId}/question/${activeTalkingPoint}`
    );
    yield put(
      createAction(PROCESS_LOAD_TALKING_POINT_SUCCESS, { foundTalkingPoint })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(PROCESS_LOAD_TALKING_POINT_FAIL, error));
  }
}

function* processRequestTalkingPoint({ payload }: AnyAction): Generator {
  try {
    const { partnershipId, talkingPoints } = payload;
    const {
      updatedPartnership,
      talkingPoint,
      userPartnerships,
    }: any = yield call(
      [ourBrainApi, "post"],
      `/partnership/${partnershipId}/question`,
      { talkingPoints }
    );
    yield put(
      createAction(PROCESS_REQUEST_TALKING_POINT_SUCCESS, {
        foundTalkingPoint: talkingPoint,
        selectedPartnership: updatedPartnership,
        userPartnerships,
      })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    if (error instanceof NotFound) {
      yield put(
        createAction(PROCESS_REQUEST_TALKING_POINT_FAIL, {
          maxTalkingPointsReached: true,
        })
      );
    }
    yield put(createAction(PROCESS_REQUEST_TALKING_POINT_FAIL, { error }));
  }
}

function* reportPartnershipSaga({ payload }: AnyAction): Generator {
  const { partnershipId, userReport } = payload;
  try {
    yield call([ourBrainApi, "post"], `/partnership/${partnershipId}/report`, {
      userReport,
    });
    yield put(createAction(REPORT_PARTNERSHIP_SUCCESS, {}));
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(REPORT_PARTNERSHIP_FAIL, { error }));
  }
}

function* updatePartnershipSaga({ payload }: AnyAction): Generator {
  const { data } = payload;
  const { partnershipId, seenInfo, selectedPartnership } = data;
  let response: any;
  try {
    response = yield call(
      [ourBrainApi, "post"],
      `/partnership/${partnershipId}/update`,
      { seenInfo, selectedPartnership }
    );
    yield put(
      createAction(UPDATE_PARTNERSHIP_SUCCESS, { ...response, showModal: "" })
    );
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(UPDATE_PARTNERSHIP_SUCCESS, { error }));
  }
}

export default [
  takeLatest(PROCESS_NEW_MESSAGE_START, processNewMessage),
  takeLeading(PROCESS_REQUEST_NEW_PARTNERSHIP_START, requestNewPartnership),
  takeLatest(LOAD_USER_PARTNERSHIPS_START, loadUserPartnerships),
  takeLatest(LOAD_PARTNERSHIP_MESSAGES_START, loadPartnershipMessages),
  takeLatest(SCROLL_TO_BOTTOM, processNewMessage),
  takeLatest(PROCESS_FIND_ALL_PREFERENCES_START, processFindAllPreferences),
  takeLeading(
    PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START,
    processFindAllPreferenceQuestions
  ),
  takeLeading(
    PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START,
    loadPartnershipCheckIns
  ),
  takeLatest(PROCESS_SUBMIT_CHECK_IN_START, processSubmitCheckIn),
  takeLeading(PROCESS_LOAD_TALKING_POINT_START, processLoadTalkingPoint),
  takeLatest(PROCESS_REQUEST_TALKING_POINT_START, processRequestTalkingPoint),
  takeLatest(REPORT_PARTNERSHIP_START, reportPartnershipSaga),
  takeLatest(UPDATE_PARTNERSHIP_START, updatePartnershipSaga),
];
