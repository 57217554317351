import {
  IonButton,
  IonCheckbox,
  IonInput,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import classNames from "classnames";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import zxcvbn from "zxcvbn";
import { useQuery } from "../../../../App";
import {
  Notification,
  OurBrainButton,
  OurBrainContent,
} from "../../../../components";
import Divider from "../../../../components/Divider";
import OurBrainHeader from "../../../../components/OurBrainHeader";
import StylishInput from "../../../../components/StylishInput";
import { emailRegExp, nameRegExp } from "../../../../config/common";
import { ISignUpData } from "../../../../interfaces/User";
import { actions as authActions } from "../../redux";
import { IAuthActions } from "../../redux/actions";
import { IAuthState } from "../../redux/reducer";
import "./index.module.scss";
import styles from "./index.module.scss";

type SignUpProps = {
  handleSignUp: Function;
  auth: IAuthState;
  actions: IAuthActions;
  loading: boolean;
};

const createSignUpForm: any = (watch: any, setValue: any, getValues: any) => {
  return [
    {
      name: "firstName",
      label: "First name",
      as: IonInput,
      rules: {
        required: true,
        pattern: { value: nameRegExp, message: "Invalid format" },
      },
    },
    {
      name: "lastName",
      label: "Last name",
      as: IonInput,
      rules: {
        required: false,
        pattern: { value: nameRegExp, message: "Invalid format" },
      },
    },
    {
      name: "email",
      label: "Email",
      as: IonInput,
      rules: {
        required: true,
        pattern: { value: emailRegExp, message: "Invalid format" },
      },
    },
    {
      name: "password",
      label: "Password",
      placeholder: "Shhhh!",
      as: IonInput,
      type: "password",
      tooltip:
        "Your password must be:<br/>- minimum 8 characters,<br/>- have one numeric character (0-9)<br/>- one upper case character <br>- one lowercase character<br>",
      rules: {
        required: true,
      },
    },
    {
      name: "repeatPassword",
      label: "Repeat password",
      placeholder: "Double shhhh!",
      as: IonInput,
      type: "password",
      rules: {
        required: true,
        validate: (value: string) => value === watch("password"),
      },
    },
    {
      name: "referralCode",
      label: "Referral code",
      as: IonInput,
      rules: {},
    },
  ];
};

const termsLink: string =
  "https://static.ourbrain.io/legal/documents/termsandconditions.pdf";
const privacyLink: string =
  "https://static.ourbrain.io/legal/documents/privacypolicy.pdf";

const SignUp: FC<SignUpProps> = ({ actions, loading, auth }) => {
  const { signUpFailed, errorMessage } = auth;
  const history = useHistory();
  const code = useQuery();
  let initialValues = {
    referralCode: code.get("ref") || "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
    acceptTerms: false,
  };
  if (process.env.NODE_ENV !== "production") {
    initialValues = {
      referralCode: code.get("ref") || "tom",
      firstName: "Tom",
      lastName: "Burton",
      email: `tom+${Date.now()}@ourbrain.io`,
      password: "p",
      repeatPassword: "p",
      acceptTerms: false,
    };
  }
  const { handleSubmit, errors, control, watch, setValue, getValues } = useForm<
    ISignUpData
  >({
    defaultValues: { ...initialValues },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    submitFocusError: false,
  });
  const handleSignUp = async (signUpData: ISignUpData) => {
    await actions.processSignUpStart({ signUpData, history });
  };

  const signUpForm: any[] = createSignUpForm(watch, setValue, getValues);
  return (
    <IonPage className={styles.sign__up__page}>
      <OurBrainHeader />
      <Notification
        position="top"
        color="danger"
        isOpen={signUpFailed}
        message={errorMessage || "Unexpected error"}
      />
      <OurBrainContent>
        <form onSubmit={handleSubmit(handleSignUp)}>
          <div className={styles.sign__up__form__title}>
            <h3>Start your journey</h3>
          </div>
          {signUpForm.map((el, i) => (
            <Controller
              key={i}
              name={el.name}
              control={control}
              rules={el.rules}
              as={({ onChange, value }) => (
                <div>
                  <StylishInput
                    name={el.name}
                    type={el.type}
                    label={el.label}
                    onChange={onChange}
                    value={value}
                    placeholder={el.placeholder}
                    errors={errors}
                    optional={!el.rules.required}
                  />
                  <div
                    hidden={el.name !== "password"}
                    className={styles.password__strength}
                  >
                    <div className={styles.password__strength__box__container}>
                      <div
                        className={classNames(
                          styles.password__strength__box,
                          zxcvbn(value).score >= 0 && value.length >= 1
                            ? styles.password__strength__low
                            : ""
                        )}
                      />
                      <div
                        className={classNames(
                          styles.password__strength__box,
                          zxcvbn(value).score >= 2 &&
                            styles.password__strength__med
                        )}
                      />
                      <div
                        className={classNames(
                          styles.password__strength__box,
                          zxcvbn(value).score === 4 &&
                            styles.password__strength__high
                        )}
                      />
                    </div>
                    <div>
                      <p>{zxcvbn(value).feedback.warning}</p>
                    </div>
                  </div>
                </div>
              )}
            />
          ))}
          <Controller
            name="acceptTerms"
            control={control}
            defaultValue={false}
            rules={{ validate: (value: boolean) => value }}
            as={({ onChange, checked }: any) => (
              <div className={styles.terms}>
                <div className={styles.terms__text}>
                  <small>
                    You have read and agree with our{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={termsLink}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={privacyLink}
                    >
                      Privacy Policy
                    </a>
                  </small>
                </div>
                <div>
                  <IonCheckbox
                    slot="end"
                    checked={checked}
                    onIonChange={onChange}
                  />
                </div>
              </div>
            )}
          />
          <IonButton
            shape="round"
            expand="full"
            size="large"
            type="submit"
            color={signUpFailed ? "danger" : "primary"}
            disabled={loading}
            className="ion-margin-top"
          >
            {loading ? <IonSpinner /> : "Start"}
          </IonButton>
          <Divider />
          <OurBrainButton
            shape="round"
            color="secondary"
            expand="block"
            link="/log-in"
          >
            Log In
          </OurBrainButton>
        </form>
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    user: state.auth.user,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
