import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonLoading,
  IonModal,
  IonRow,
} from "@ionic/react";
import cn from "classnames";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/Input";
import OurBrainInformation from "../../../../../components/OurBrainInformation";
import { emailRegExp } from "../../../../../config/common";
import { ISignUpData } from "../../../../../interfaces/User";
import componentStyles from "../../../../../theme/components.module.scss";

const createDetailsForm: any = (watch: any) => {
  return {
    password: [
      {
        name: "oldPassword",
        as: IonInput,
        rules: { required: true },
        type: "password",
      },
      {
        name: "password",
        as: IonInput,
        rules: { required: true },
        type: "password",
      },
      {
        name: "repeatPassword",
        as: IonInput,
        rules: {
          required: true,
          validate: (value: string) => value === watch("password"),
          // pattern: {value: passwordRegExp, message: 'Invalid format'}
        },
        type: "password",
      },
    ],
    email: [
      {
        name: "email",
        as: IonInput,
        rules: { required: true, pattern: { value: emailRegExp } },
      },
    ],
    username: [{ name: "username", as: IonInput, rules: { required: false } }],
    city: [{ name: "city", as: IonInput, rules: { required: true } }],
  };
};

const UserDetails: FC<any> = ({
  loading,
  isOpen,
  actions,
  formKey,
  activeUser,
  token,
}) => {
  const { handleSubmit, errors, control, formState, watch } = useForm<
    ISignUpData
  >({
    defaultValues: {
      firstName: activeUser.firstName,
      lastName: activeUser.lastName,
      username: activeUser.username,
    },
    mode: "onChange",
  });
  const detailsForm: any = createDetailsForm(watch);
  const submitDetailsForm = (data: any) => {
    const userDetails = {
      ...data,
      _id: activeUser._id,
    };
    actions.processUpdateUserStart({ userDetails });
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonLoading isOpen={loading} message={"Please wait..."} />
      <IonGrid
        className={cn("ion-justify-content-center", componentStyles.gridFlex)}
      >
        <OurBrainInformation information={{ title: `Change ${formKey}` }} />
        <form onSubmit={handleSubmit(submitDetailsForm)}>
          {detailsForm[formKey]?.map((el: any) => (
            <IonRow key={el.name}>
              <IonCol>
                <Input
                  errors={errors}
                  control={control}
                  labelPosition="stacked"
                  name={el.name}
                  type={el.type || "text"}
                  rules={el.rules}
                  as={el.as}
                />
              </IonCol>
            </IonRow>
          ))}
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                size="small"
                fill="outline"
                disabled={!formState.isValid}
                type="submit"
              >
                Submit
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                expand="block"
                fill="clear"
                color="secondary"
                size="small"
                onClick={() => actions.showModal({ modal: "details" })}
              >
                Back
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
    </IonModal>
  );
};

export default UserDetails;
