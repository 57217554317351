import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { call, put, select, takeLatest } from "redux-saga/effects";
import ourBrainApi from "../../../api/createApiService";
import { BadRequestError } from "../../../api/errors";
import { GA4_CODE } from "../../../config/env";
import { createAction } from "../../../rootRedux/rootActions";
import { SET_PARTNERSHIP_STATE } from "../../Partnerships/redux/constants";
import {
  PROCESS_LOGIN_FAIL,
  PROCESS_LOGIN_START,
  PROCESS_LOGIN_SUCCESS,
  PROCESS_LOGOUT_FAIL,
  PROCESS_LOGOUT_START,
  PROCESS_LOGOUT_SUCCESS,
  PROCESS_PASSWORD_RESET_START_FAIL,
  PROCESS_PASSWORD_RESET_START_START,
  PROCESS_PASSWORD_RESET_START_SUCCESS,
  PROCESS_PASSWORD_RESET_VERIFY_FAIL,
  PROCESS_PASSWORD_RESET_VERIFY_START,
  PROCESS_PASSWORD_RESET_VERIFY_SUCCESS,
  PROCESS_SIGNUP_FAIL,
  PROCESS_SIGNUP_START,
  PROCESS_SIGNUP_SUCCESS,
  SET_PERMISSIONS_FAIL,
  SET_PERMISSIONS_START,
  SET_PERMISSIONS_SUCCESS,
} from "./constants";

function* processLogin({ payload }: AnyAction): Generator {
  const { loginData } = payload;
  try {
    const {
      user,
      referralCode,
      userPartnerships,
      partnershipsToAction,
      notification,
    }: any = yield call([ourBrainApi, "post"], "/auth/authenticate", loginData);
    yield put(
      createAction(PROCESS_LOGIN_SUCCESS, { user, referralCode, notification })
    );
    yield put(
      createAction(SET_PARTNERSHIP_STATE, {
        userPartnerships,
        partnershipsToAction,
      })
    );
    gtag("config", GA4_CODE!, {
      user_id: user._id,
    });
    gtag("event", "login", {
      method: "ourbrain",
    });
    yield put(push("/app"));
  } catch (error) {
    yield put(createAction(PROCESS_LOGIN_FAIL, { showError: error.message }));
  }
}

function* setPermissions(): Generator {
  const user: any = yield select((state) => state.auth.user);
  try {
    const { role, token }: any = yield call([ourBrainApi, "post"], "/auth", {});
    yield put(createAction(SET_PERMISSIONS_SUCCESS, { role, token }));
    gtag("config", GA4_CODE!, {
      user_id: user._id,
    });
  } catch (error) {
    yield put(createAction(SET_PERMISSIONS_FAIL, {}));
    yield put(createAction(PROCESS_LOGOUT_START, {}));
  }
}

function* processLogout(): Generator {
  try {
    yield call([ourBrainApi, "post"], "/auth/logout", {});
    yield put(createAction(PROCESS_LOGOUT_SUCCESS, {}));
    yield put(push("/log-in"));
  } catch (error) {
    yield put(createAction(PROCESS_LOGOUT_FAIL, error.response));
  }
}

function* processSignUp({ payload }: AnyAction): Generator {
  const { signUpData } = payload;
  try {
    const { user, referralCode }: any = yield call(
      [ourBrainApi, "post"],
      "/user/create",
      signUpData
    );
    yield put(createAction(PROCESS_SIGNUP_SUCCESS, { user, referralCode }));
    gtag("config", GA4_CODE!, {
      user_id: user._id,
    });
    gtag("event", "sign_up", {
      method: signUpData.referralCode,
      id: user._id,
    });
    yield put(push("/app/welcome"));
  } catch (error) {
    if (error instanceof BadRequestError) {
      yield put(
        createAction(PROCESS_SIGNUP_FAIL, {
          signUpFailed: true,
          errorMessage: error.message,
        })
      );
      return;
    }
    yield put(
      createAction(PROCESS_SIGNUP_FAIL, {
        showSystemError: true,
        signUpFailed: true,
      })
    );
  }
}

function* processPasswordResetStart({ payload }: AnyAction): Generator {
  const { data } = payload;
  yield put(push("/reset/success"));
  try {
    yield call([ourBrainApi, "post"], "/user/pass/start", data);
    yield put(createAction(PROCESS_PASSWORD_RESET_START_SUCCESS, {}));
    gtag("event", "password_reset");
  } catch (error) {
    yield put(createAction(PROCESS_PASSWORD_RESET_START_FAIL, error.response));
  }
}

function* processPasswordResetVerify({ payload }: AnyAction): Generator {
  const { data } = payload;
  try {
    yield call([ourBrainApi, "post"], "/user/pass/verify", data);
    yield put(createAction(PROCESS_PASSWORD_RESET_VERIFY_SUCCESS, {}));
    yield put(push("/log-in"));
  } catch (error) {
    yield put(createAction(PROCESS_PASSWORD_RESET_VERIFY_FAIL, error.response));
    yield put(push("/log-in"));
  }
}

const watchProcessLogin = takeLatest(PROCESS_LOGIN_START, processLogin);
const watchProcessLogout = takeLatest(PROCESS_LOGOUT_START, processLogout);
const watchProcessSignUp = takeLatest(PROCESS_SIGNUP_START, processSignUp);
const watchProcessResetPasswordStart = takeLatest(
  PROCESS_PASSWORD_RESET_START_START,
  processPasswordResetStart
);
const watchProcessResetPasswordVerify = takeLatest(
  PROCESS_PASSWORD_RESET_VERIFY_START,
  processPasswordResetVerify
);
const watchRehydrate = takeLatest(SET_PERMISSIONS_START, setPermissions);
const watchLoginSuccess = takeLatest(PROCESS_LOGIN_SUCCESS, setPermissions);

export default [
  watchProcessLogout,
  watchProcessSignUp,
  watchProcessLogin,
  watchProcessResetPasswordStart,
  watchProcessResetPasswordVerify,
  watchRehydrate,
  watchLoginSuccess,
];
