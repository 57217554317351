import { IonPage } from "@ionic/react";
import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainContent } from "../../../../components";
import { actions as hubActions } from "../../../Hub/redux";
import { IHubActions } from "../../redux/actions";
import { IHubState } from "../../redux/reducer";

type SavedPostProps = {
  actions: IHubActions;
  hub: IHubState;
};
const SavedPosts: FC<SavedPostProps> = (props) => {
  const { hub, actions } = props;

  useEffect(() => {
    actions.getFeed({ query: "?type=saved" });
  }, []);

  return (
    <IonPage>
      <OurBrainContent />
    </IonPage>
  );
};
const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...hubActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SavedPosts);
