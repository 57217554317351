import { IonPage, IonSlide, IonSlides } from "@ionic/react";
import { push } from "connected-react-router";
import { checkboxOutline, pencil, people } from "ionicons/icons";
import React, { FC, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useQuery } from "../../../../../App";
import {
  Divider,
  Notification,
  OurBrainContent,
} from "../../../../../components";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import { QuestionType } from "../../../../../interfaces/interfaces";
import { IAuthState } from "../../../../Auth/redux/reducer";
import partnershipActions, {
  IPartnershipActions,
} from "../../../redux/actions";
import { IPartnershipDefaultState } from "../../../redux/reducer";
import { FooterButtons, SelectBox, SubmitButtons, TextBox } from "./components";
import styles from "./index.module.scss";

const partnershipDisclaimerInformation: IInformation = {
  paragraphs: [
    "We always aim to make sure your partnership is as accurate as possible. However, we cannot guarantee that all 3 category requests will always be available at this time.",
    "Once ourBrain has found your partner, we will notify you by email so you can begin your conversation. The platform uses 'daily check-ins' between users.",
    "At this early stage, we can only process one check-in per day. View this as a chance to unwind in a longer, more thoughtful message, growing your partnership day by day.",
  ],
  bullets: [0, 1, 2],
  icons: [checkboxOutline, people, pencil],
};

type PartnershipPreferenceProps = {
  partnership: IPartnershipDefaultState;
  actions: IPartnershipActions & { push: any };
  auth: IAuthState;
  router: any;
};

const PartnershipPreference: FC<PartnershipPreferenceProps> = ({
  partnership,
  auth,
  actions,
  router,
}) => {
  const {
    showError,
    preferenceQuestions,
    orQuestionSelected,
    answersReset,
  } = partnership;

  const query = useQuery();
  const preferenceId = query.get("preferenceId") as any;

  const { location } = router;

  const defaultValues = preferenceQuestions.map(({ _id }) => ({
    [_id]: undefined,
  }));
  const form = useForm({
    mode: "onChange",
    defaultValues,
  });
  const { handleSubmit, errors, control, reset } = form;

  const [initialQuery] = useState(location.search);
  const slidesRef: any = useRef();
  const contentRef: any = useRef();

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [renderedQuestions, setRenderedQuestions] = useState<{
    [key: number]: any[];
  }>();

  useEffect(() => {
    function createQuestions() {
      const slidesToElementMapping: { [key: number]: any[] } = {
        0: [],
        1: [],
        2: [],
      };
      for (const {
        inputType,
        slide,
        _id,
        options,
        range,
        question,
        or,
      } of preferenceQuestions) {
        if (inputType === QuestionType.Text) {
          const element: any = (
            <TextBox
              _id={_id}
              key={_id}
              question={question}
              control={control}
              errors={errors}
            />
          );
          slidesToElementMapping[slide!].push(element);
        }
        if (inputType === QuestionType.Select) {
          const element: any = (
            <SelectBox
              _id={_id}
              key={_id}
              control={control}
              errors={errors}
              options={options!}
              range={range!}
              question={question}
              or={or}
              orQuestionSelected={orQuestionSelected}
              actions={actions}
            />
          );
          slidesToElementMapping[slide!].push(element);
        }
        if (typeof slidesToElementMapping[0][1] === "undefined") {
          slidesToElementMapping[0].push(<Divider />);
        }
      }
      setRenderedQuestions(slidesToElementMapping);
    }

    createQuestions();
  }, [orQuestionSelected, reset, answersReset]);

  const handleSlideDidChange = async () => {
    const activeSlide: number = await slidesRef.current.getActiveIndex();
    setCurrentSlideIndex(activeSlide);
    actions.push({
      pathname: location.pathname,
      search: initialQuery.replace(/slide=.*/, `slide=${activeSlide}`),
    });
  };

  const handleSubmitPreference = (values: any) => {
    const userPreferenceAnswers: any[] = [];
    for (const [questionId, answer] of Object.entries(values)) {
      userPreferenceAnswers.push({
        questionId,
        answer: typeof answer !== "undefined" ? answer : "",
      });
    }
    const data = {
      userPreference: {
        preferenceId,
      },
      userPreferenceAnswers,
    };
    actions.processRequestNewPartnershipStart({
      data,
      push: actions.push,
      userId: auth.user!._id,
    });
  };

  return (
    <IonPage className={styles.preferenceTypeContainer}>
      <Notification
        position="top"
        color="warning"
        message={showError}
        isOpen={typeof showError !== "undefined"}
        onDidDismiss={() => actions.partnershipReset({ showError: undefined })}
      />
      <OurBrainContent ref={contentRef}>
        <OurBrainInformation information={{ title: "Partnerships" }} />
        <form onSubmit={handleSubmit(handleSubmitPreference)}>
          <IonSlides
            ref={slidesRef}
            onIonSlideDidChange={handleSlideDidChange}
            onIonSlideWillChange={async () =>
              await contentRef.current.scrollToTop()
            }
            pager={currentSlideIndex !== 3}
            className={styles.slides}
            options={{
              initialSlide: currentSlideIndex,
              allowTouchMove: false,
            }}
          >
            <IonSlide className={styles.slideContent}>
              {typeof renderedQuestions !== "undefined" &&
                renderedQuestions[0].map((element) => element)}
            </IonSlide>
            <IonSlide className={styles.slideContent}>
              {typeof renderedQuestions !== "undefined" &&
                renderedQuestions[1].map((element) => element)}
            </IonSlide>
            <IonSlide className={styles.slideContent}>
              {typeof renderedQuestions !== "undefined" &&
                renderedQuestions[2].map((element) => element)}
            </IonSlide>
            <IonSlide>
              <SubmitButtons
                partnershipDisclaimerInformation={
                  partnershipDisclaimerInformation
                }
                reset={reset}
                errors={errors}
                partnership={partnership}
                defaultValues={defaultValues}
                actions={actions}
                push={actions.push}
              />
            </IonSlide>
          </IonSlides>
        </form>
      </OurBrainContent>
      <FooterButtons slidesRef={slidesRef} />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    activeUser: state.auth.user,
    partnership: state.partnership,
    partnershipLoading: state.partnership.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...partnershipActions, push }, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipPreference);
