import {
  IonButton,
  IonButtons,
  IonItem,
  IonList,
  IonModal,
  IonNote,
} from "@ionic/react";
import React, { Dispatch, FC, SetStateAction } from "react";
import { ModerationResult } from "../../../../interfaces/interfaces";
import { IAdminActions } from "../../redux/actions";
import { IAdminState } from "../../redux/reducer";
import styles from "./index.module.scss";

type SubmitCheckInsModalProps = {
  openSummaryModal: boolean;
  setOpenSummaryModal: Dispatch<SetStateAction<boolean>>;
  admins: IAdminState;
  actions: IAdminActions;
};

interface IResultConfig {
  color: string;
  text: string;
}

const renderResult = (decision: ModerationResult): IResultConfig => {
  if (decision === ModerationResult.Pass) {
    return {
      color: "success",
      text: "Moderated",
    };
  }
  if (decision === ModerationResult.Warning) {
    return {
      color: "warning",
      text: "Flagged",
    };
  }
  if (decision === ModerationResult.Fail) {
    return {
      color: "danger",
      text: "Rejected",
    };
  }
  return {
    color: "light",
    text: "Needs moderation",
  };
};

const SubmitCheckInsModal: FC<SubmitCheckInsModalProps> = (props) => {
  const { setOpenSummaryModal, admins, openSummaryModal, actions } = props;

  const handleSubmitCheckIns = () => {
    actions.processModerateCheckInsStart({
      moderatedCheckIns: admins.moderatedCheckIns,
    });
    setOpenSummaryModal(false);
  };

  return (
    <IonModal isOpen={openSummaryModal}>
      <div className={styles.submitCheckInModalContainer}>
        <IonList className={styles.checkInList}>
          {admins.moderatedCheckIns.map(({ message, decision }, i) => {
            const result = renderResult(decision!);
            return (
              <IonItem className={styles.checkInItem} key={i}>
                <p>
                  {message!.length > 75
                    ? `${message?.substr(0, 75)}...`
                    : message}
                </p>
                <IonNote color={result.color} slot="end">
                  {result.text}
                </IonNote>
              </IonItem>
            );
          })}
        </IonList>
        <IonButtons className="ion-justify-content-evenly">
          <IonButton
            color="danger"
            expand="full"
            onClick={() => setOpenSummaryModal(false)}
            disabled={admins.moderatedCheckInIds?.length === 0}
          >
            Cancel
          </IonButton>
          <IonButton
            color="secondary"
            expand="full"
            onClick={handleSubmitCheckIns}
            disabled={admins.moderatedCheckInIds?.length === 0}
          >
            Submit
          </IonButton>
        </IonButtons>
      </div>
    </IonModal>
  );
};

export default SubmitCheckInsModal;
