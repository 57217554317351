import React, { FC } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { actions as authActions } from "../../modules/Auth/redux";

export interface ProtectedRouteProps extends RouteProps {
  admin?: boolean;
  noAuth?: boolean;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component,
  isAuthenticated,
  admin,
  noAuth,
  isAdmin,
  ...rest
}: any) => {
  let routeComponent = (props: any) =>
    isAuthenticated ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: "/log-in" }} />
    );
  if (noAuth) {
    routeComponent = (props: any) =>
      !isAuthenticated ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={{ pathname: "/app" }} />
      );
  }
  const adminComponent = (props: any) =>
    admin && isAdmin ? (
      React.createElement(component, props)
    ) : isAuthenticated ? (
      <Redirect to={{ pathname: "/app" }} />
    ) : (
      <Redirect to={{ pathname: "/log-in" }} />
    );
  return <Route {...rest} render={admin ? adminComponent : routeComponent} />;
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.auth.isAdmin,
    isUser: state.auth.isUser,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
