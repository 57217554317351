import { JSX } from "@ionic/core";
import { IonCard } from "@ionic/react";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

interface OurBrainCard extends JSX.IonCard, Pick<HTMLIonCardElement, any> {
  link?: string;
  index?: any;
  hidden?: boolean;
  cursor?: string;
}

const OurBrainCard: FC<OurBrainCard> = (props) => {
  const { push } = useHistory();

  const handleOnClick = (e: any) => {
    e.preventDefault();
    if (typeof props.link !== "undefined") {
      push(props.link);
    }
  };
  return (
    <IonCard
      key={props.index}
      {...props}
      style={{ cursor: props.cursor || "auto" }}
      onClick={(e) => handleOnClick(e)}
    >
      {props.children}
    </IonCard>
  );
};

export default OurBrainCard;
