export const PROCESS_LOGIN_START: string = 'PROCESS_LOGIN_START'
export const PROCESS_LOGIN_SUCCESS: string = 'PROCESS_LOGIN_SUCCESS'
export const PROCESS_LOGIN_FAIL: string = 'PROCESS_LOGIN_FAIL'

export const PROCESS_LOGOUT_START: string = 'PROCESS_LOGOUT_START'
export const PROCESS_LOGOUT_SUCCESS: string = 'PROCESS_LOGOUT_SUCCESS'
export const PROCESS_LOGOUT_FAIL: string = 'PROCESS_LOGOUT_FAIL'

export const PROCESS_SIGNUP_START: string = 'PROCESS_SIGNUP_START'
export const PROCESS_SIGNUP_SUCCESS: string = 'PROCESS_SIGNUP_SUCCESS'
export const PROCESS_SIGNUP_FAIL: string = 'PROCESS_SIGNUP_FAIL'

export const PROCESS_PASSWORD_RESET_START_START: string = 'PROCESS_PASSWORD_RESET_START_START'
export const PROCESS_PASSWORD_RESET_START_SUCCESS: string = 'PROCESS_PASSWORD_RESET_START_SUCCESS'
export const PROCESS_PASSWORD_RESET_START_FAIL: string = 'PROCESS_PASSWORD_RESET_START_FAIL'

export const PROCESS_PASSWORD_RESET_VERIFY_START: string = 'PROCESS_PASSWORD_RESET_VERIFY_START'
export const PROCESS_PASSWORD_RESET_VERIFY_SUCCESS: string = 'PROCESS_PASSWORD_RESET_VERIFY_SUCCESS'
export const PROCESS_PASSWORD_RESET_VERIFY_FAIL: string = 'PROCESS_PASSWORD_RESET_VERIFY_FAIL'

export const SET_PERMISSIONS_START: string = 'SET_PERMISSIONS_START'
export const SET_PERMISSIONS_SUCCESS: string = 'SET_PERMISSIONS_SUCCESS'
export const SET_PERMISSIONS_FAIL: string = 'SET_PERMISSIONS_FAIL'

export const UPDATE_USER: string = 'UPDATE_USER'

export const AUTH_SET_STATE: string = 'AUTH_SET_STATE'
