import MaterialTable from "material-table";
import React, { FC } from "react";
import { IAdminActions } from "../../redux/actions";

type OurBrainTableProps = {
  title: string;
  columns: any;
  data: any;
  onSelect?: any;
  actions?: IAdminActions;
  options?: any;
};
const OurBrainTable: FC<OurBrainTableProps> = ({
  title,
  columns,
  data,
  onSelect,
  actions,
  options,
}) => {
  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={columns}
        editable={{
          isEditable: (rowData: any) => rowData.name === "decision",
          onBulkUpdate: (changes) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                /* setData([...data, newData]); */

                const moderatedCheckIns: any = Object.values(changes).map(
                  ({ newData }) => newData
                );
                actions?.processModerateCheckInsStart({ moderatedCheckIns });

                resolve();
              }, 1000);
            }),
        }}
        options={{
          selection: typeof onSelect !== "undefined",
          grouping: true,
          filtering: true,
          ...options,
        }}
        onSelectionChange={onSelect}
        data={data}
        title={title}
      />
    </div>
  );
};

export default OurBrainTable;
