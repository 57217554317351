import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import moment from "moment";
import React, { FC, memo, useEffect, useState } from "react";
import {
  IPartnershipCheckInModel,
  IPartnershipModel,
} from "../../../../../../../interfaces/interfaces";
import styles from "./index.module.scss";

type PartnershipCheckInStatsProps = {
  partnership: IPartnershipModel;
  partnershipCheckins: IPartnershipCheckInModel[];
};

const PartnershipCheckInStats: FC<PartnershipCheckInStatsProps> = (props) => {
  const { partnership, partnershipCheckins } = props;

  const [partnersFor, setPartnersFor] = useState<number>();
  const { longestStreak, currentStreak } = partnership.streak;
  const [longestStreakCalculated, setLongestStreakCalculated] = useState(
    longestStreak
  );
  const [checkInsThisWeek, setCheckInsThisWeek] = useState(0);
  useEffect(() => {
    setPartnersFor(moment().diff(moment(partnership.createdAt), "days") + 1);

    currentStreak > longestStreak
      ? setLongestStreakCalculated(currentStreak)
      : setLongestStreakCalculated(longestStreak);

    setCheckInsThisWeek(
      partnershipCheckins.filter(({ createdAt }) =>
        moment(createdAt).isAfter(moment().subtract(7, "days"))
      ).length
    );
  }, [
    partnershipCheckins,
    partnersFor,
    partnership.createdAt,
    currentStreak,
    longestStreak,
  ]);

  const levelEmoji = () => {
    const { level } = partnership;
    if (level === 1) {
      return (
        <span role="img" aria-label="bronze">
          🥉
        </span>
      );
    }
    if (level === 2) {
      return (
        <span role="img" aria-label="bronze">
          🥈
        </span>
      );
    }
    if (level === 3) {
      return (
        <span role="img" aria-label="bronze">
          🥇
        </span>
      );
    }
  };

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardSubtitle>Your progression</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className="ion-no-padding ion-padding-bottom">
        <div className={styles.partnershipCheckInStatsContainer}>
          <IonList>
            <IonItem>
              <IonLabel>Current Streak</IonLabel>
              <IonNote className={styles.note}>{currentStreak}</IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Best streak</IonLabel>
              <IonNote className={styles.note}>
                {longestStreakCalculated}
              </IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Check-ins this week</IonLabel>
              <IonNote className={styles.note}>{checkInsThisWeek}</IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Partnered for</IonLabel>
              <IonNote className={styles.note}>
                {`${partnersFor} day${partnersFor === 1 ? "" : "s"}`}
              </IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Your level</IonLabel>
              <IonNote className={styles.note}>{levelEmoji()}</IonNote>
            </IonItem>
          </IonList>
        </div>
      </IonCardContent>
    </IonCard>
  );
};
export default memo(PartnershipCheckInStats);
