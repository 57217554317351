import {
  PROCESS_FORM_UPDATE,
  PROCESS_UPDATE_USER_CONFIG,
} from "../../../config/constants"; // CHANGE
import { INotificationModel, IUserModel } from "../../../interfaces/interfaces"; // CHANGE
import { PROCESS_UPDATE_USER_SUCCESS } from "../../Profile/redux/constants";
import {
  AUTH_SET_STATE,
  PROCESS_LOGIN_FAIL,
  PROCESS_LOGIN_START,
  PROCESS_LOGIN_SUCCESS,
  PROCESS_LOGOUT_FAIL,
  PROCESS_LOGOUT_START,
  PROCESS_LOGOUT_SUCCESS,
  PROCESS_PASSWORD_RESET_START_FAIL,
  PROCESS_PASSWORD_RESET_START_START,
  PROCESS_PASSWORD_RESET_START_SUCCESS,
  PROCESS_PASSWORD_RESET_VERIFY_FAIL,
  PROCESS_PASSWORD_RESET_VERIFY_START,
  PROCESS_PASSWORD_RESET_VERIFY_SUCCESS,
  PROCESS_SIGNUP_FAIL,
  PROCESS_SIGNUP_START,
  PROCESS_SIGNUP_SUCCESS,
  SET_PERMISSIONS_FAIL,
  SET_PERMISSIONS_START,
  SET_PERMISSIONS_SUCCESS,
  UPDATE_USER,
} from "./constants";

export interface IAuthState {
  loading: boolean;
  loginFailed: boolean;
  isAuthenticated: boolean;
  isAdmin?: boolean;
  isUser?: boolean;
  user?: IUserModel; // CHANGE
  currentPhotoSelect: string;
  signUpFailed: boolean;
  errorMessage?: string;
  notification?: INotificationModel;
  token?: string;
  role?: "admin" | "user" | "beta";
}

const defaultState: IAuthState = {
  loading: false,
  loginFailed: false,
  isAuthenticated: false,
  signUpFailed: false,
  currentPhotoSelect: "",
};

export interface IAuthData {
  role: string[];
  email: string;
  phone: string;
}

function reducer(state = defaultState, action: any) {
  const { payload, type } = action as any;
  switch (type) {
    // LOGIN
    case PROCESS_LOGIN_START:
      return {
        ...state,
        ...payload,
        loading: true,
        loginFailed: false,
      };
    case PROCESS_LOGIN_SUCCESS:
      return {
        ...state,
        ...payload, // CHANGE
        loading: false,
        isAuthenticated: true,
      };
    case PROCESS_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        loginFailed: true,
        error: payload,
      };

    // LOGOUT
    case PROCESS_LOGOUT_START:
      return {
        ...state,
        ...payload,
        loading: true,
      };
    case PROCESS_LOGOUT_SUCCESS:
      return {
        ...defaultState,
      };
    case PROCESS_LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    // SIGNUP
    case PROCESS_SIGNUP_START:
      return {
        ...state,
        ...payload,
        loading: true,
        signUpFailed: false,
        showError: false,
        showSuccess: false,
        showSystemError: false,
        showBadRequest: false,
      };
    case PROCESS_SIGNUP_SUCCESS:
      return {
        ...state,
        user: payload.user, // CHANGE
        referralCode: payload.referralCode,
        activeUser: payload.user,
        isAuthenticated: true,
        loading: false,
      };
    case PROCESS_SIGNUP_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    // PASSWORD RESET START
    case PROCESS_PASSWORD_RESET_START_START:
      return {
        ...state,
        ...payload,
      };
    case PROCESS_PASSWORD_RESET_START_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case PROCESS_PASSWORD_RESET_START_FAIL:
      return {
        ...state,
        error: payload,
      };

    // PASSWORD RESET VERIFY
    case PROCESS_PASSWORD_RESET_VERIFY_START:
      return {
        ...state,
        ...payload,
      };
    case PROCESS_PASSWORD_RESET_VERIFY_SUCCESS:
      return {
        ...state,
        ...payload,
        showSuccess: true,
      };
    case PROCESS_PASSWORD_RESET_VERIFY_FAIL:
      return {
        ...state,
        error: payload,
        showError: true,
      };

    // FROM OTHER SAGAS
    case PROCESS_UPDATE_USER_SUCCESS: // CHANGE
      return {
        ...state,
        ...payload,
      };
    // FROM OTHER SAGAS
    case PROCESS_UPDATE_USER_CONFIG: // CHANGE
      return {
        ...state,
        user: {
          // CHANGE
          ...state.user, // CHANGE
          config: payload,
        },
      };

    // SAGALESS
    case PROCESS_FORM_UPDATE:
      return {
        ...state,
        welcomeForm: {
          ...payload,
        },
      };
    case SET_PERMISSIONS_START:
      return {
        ...state,
        loading: true,
      };
    case SET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        isAdmin: payload.role.includes("admin"),
        isUser: payload.role.includes("admin"),
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_PERMISSIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case AUTH_SET_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}

export default reducer;
