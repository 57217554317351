import { IonImg } from "@ionic/react";
import React, { FC } from "react";
import "swiper/swiper.scss";
import OurBrainInformation, {
  IInformation,
} from "../../../../../components/OurBrainInformation";
import { getAssetPath } from "../../../../../config/common";

const ourBrainInformation: IInformation = {
  title: "Welcome to ourBrain",
  subtitle: "Thank you for joining us.",
};

const mentalHealthTogetherInformation: IInformation = {
  subtitle: "Let's tackle mental health, together.",
};

const OurBrain: FC<any> = (props) => {
  const { styles, hidden } = props;
  return (
    <div hidden={hidden}>
      <OurBrainInformation center information={ourBrainInformation} />
      <div className={styles.ourBrainLogo}>
        <IonImg
          src={getAssetPath({ name: "brain_tile" })}
          alt="ourBrain tile"
        />
      </div>
      <OurBrainInformation
        center
        information={mentalHealthTogetherInformation}
      />
    </div>
  );
};

export default OurBrain;
