import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import ourBrainApi from "../../../api/createApiService";
import { ForbiddenError, UnauthorizedError } from "../../../api/errors";
import { createAction } from "../../../rootRedux/rootActions";
import { PROCESS_LOGOUT_START, UPDATE_USER } from "../../Auth/redux/constants";
import {
  PROCESS_CHANGE_PASSWORD_FAIL,
  PROCESS_CHANGE_PASSWORD_START,
  PROCESS_CHANGE_PASSWORD_SUCCESS,
  PROCESS_UPDATE_USER_FAIL,
  PROCESS_UPDATE_USER_START,
  PROCESS_UPDATE_USER_SUCCESS,
  PROCESS_UPLOAD_PHOTO_FAIL,
  PROCESS_UPLOAD_PHOTO_START,
  PROCESS_UPLOAD_PHOTO_SUCCESS,
} from "./constants";

function* processChangePassword({ payload }: AnyAction): Generator {
  const { passwordData } = payload;
  try {
    const user = yield call(
      [ourBrainApi, "post"],
      "/auth/authenticate",
      passwordData
    );
    yield put(createAction(PROCESS_CHANGE_PASSWORD_SUCCESS, user));
  } catch (error) {
    yield put(createAction(PROCESS_CHANGE_PASSWORD_FAIL, error.response));
  }
}

function* processUploadPhoto({ payload }: AnyAction): Generator {
  const { _id, file } = payload;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("_id", _id);
  try {
    const user = yield call(
      [ourBrainApi, "post"],
      `/user/${_id}/photo/upload`,
      formData
    );
    yield put(createAction(PROCESS_UPLOAD_PHOTO_SUCCESS, user));
    yield put(createAction(UPDATE_USER, user));
  } catch (error) {
    yield put(createAction(PROCESS_UPLOAD_PHOTO_FAIL, error.response));
  }
}

function* processUserUpdate({ payload }: AnyAction): Generator {
  const { userDetails, push } = payload;
  try {
    const updatedUser = yield call(
      [ourBrainApi, "post"],
      `/user/${userDetails._id}/update`,
      userDetails
    );
    yield put(createAction(PROCESS_UPDATE_USER_SUCCESS, updatedUser));
    if (typeof push !== "undefined") {
      push("/app/partnership/begin");
    }
  } catch (error) {
    if (error instanceof UnauthorizedError || error instanceof ForbiddenError) {
      yield put(createAction(PROCESS_LOGOUT_START, payload));
      return;
    }
    yield put(createAction(PROCESS_UPDATE_USER_FAIL, error.response));
  }
}

export default [
  takeLatest(PROCESS_CHANGE_PASSWORD_START, processChangePassword),
  takeLatest(PROCESS_UPDATE_USER_START, processUserUpdate),
  takeLatest(PROCESS_UPLOAD_PHOTO_START, processUploadPhoto),
];
