import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonHeader,
  IonText,
} from "@ionic/react";
import React, { FC } from "react";

type PartnershipChatTalkingPoint = {
  showTalkingPoint: boolean;
};
const PartnershipChatTalkingPoint: FC<PartnershipChatTalkingPoint> = (
  props
) => {
  const { showTalkingPoint } = props;

  return (
    <IonHeader translucent hidden={!showTalkingPoint}>
      <IonCard className="ion-margin-vertical ion-padding-vertical">
        <IonCardTitle color="primary" className="ion-text-center">
          Talking point<IonText color="secondary">.</IonText>
        </IonCardTitle>
        <IonCardContent>
          <IonText className="ion-text-center">
            <i>"What do you both have in common?"</i>
          </IonText>
        </IonCardContent>
      </IonCard>
    </IonHeader>
  );
};
export default PartnershipChatTalkingPoint;
