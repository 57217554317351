import {
  IonButton,
  IonCard,
  IonCardContent,
  IonList,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { FormikValues, useFormik } from "formik";
import { isNil } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  IFeedbackForm,
  IFeedbackFormAnswer,
  IQuestionModel,
} from "../../interfaces/interfaces";
import { actions as profileActions } from "../../modules/Profile/redux";
import { actions as generalActions } from "../../modules/reduxGlobal";
import { IGlobalActions } from "../../modules/reduxGlobal/actions";
import { IGlobalState } from "../../modules/reduxGlobal/reducer";
import { Notification, OurBrainQuestionInput } from "../index";
import OurBrainInformation from "../OurBrainInformation";

type OurBrainFormProps = {
  general?: IGlobalState;
  loading?: boolean;
  formKey: string;
  feedbackForm?: IFeedbackForm;
  actions?: IGlobalActions;
  userId?: string;
  modal?: string;
  noTitle?: boolean;
  noBack?: boolean;
  showSuccess?: boolean;
  partnershipId?: string;
  backAction?: any;
  extraSubmitAction?: any;
};

const getInitialValues = (
  feedbackFormQuestions: IQuestionModel[] | undefined
): FormikValues => {
  let initialValues: any = {};
  if (typeof feedbackFormQuestions === "undefined") {
    return initialValues;
  }
  feedbackFormQuestions!.map(({ _id }) => {
    return (initialValues = { ...initialValues, [_id]: "" });
  });
  return initialValues;
};

const OurBrainForm: FC<OurBrainFormProps> = (props) => {
  const {
    general,
    formKey,
    actions,
    loading,
    showSuccess,
    noTitle,
    noBack,
    backAction,
    userId,
    partnershipId,
    extraSubmitAction,
    modal,
  } = props;
  const [hasSubmittedForm, setHasSubmittedForm] = useState(false);
  const { feedbackForm, feedbackFormQuestions, showError } = general!;
  const formik = useFormik({
    initialValues: getInitialValues(feedbackFormQuestions),
    onSubmit: (values) => handleSubmit(values),
  });
  useEffect(() => {
    if (typeof formKey !== "undefined") {
      actions!.processLoadFormStart({ formKey });
    }
    return () => {
      actions?.setGlobalState({
        showError: false,
        showSuccess: false,
        feedbackForm: undefined,
        feedbackFormQuestions: undefined,
      });
    };
  }, [actions, formKey]);

  const handleSubmit = (values: any) => {
    let userFeedbackFormAnswer: IFeedbackFormAnswer = {
      feedbackFormId: feedbackForm?._id!,
      answers: values,
      userId: userId!,
      partnershipId,
    };
    actions?.processSubmitFormStart({ userFeedbackFormAnswer, modal });
    setHasSubmittedForm(true);
    if (typeof extraSubmitAction !== "undefined") {
      extraSubmitAction();
    }
  };
  if (loading) {
    return (
      <IonCard>
        <IonCardContent>
          <IonSpinner name="crescent" />
        </IonCardContent>
      </IonCard>
    );
  }
  if (showError || isNil(feedbackForm)) {
    return (
      <div>
        <IonCard>
          <IonCardContent>
            <p>We're sorry, there was a problem loading this section.</p>
          </IonCardContent>
        </IonCard>
        <IonButton
          hidden={noBack}
          type="button"
          fill="clear"
          color="secondary"
          onClick={backAction}
        >
          Back
        </IonButton>
      </div>
    );
  }
  const { formTitle } = feedbackForm as IFeedbackForm;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Notification
        color="success"
        position="bottom"
        isOpen={showSuccess!}
        message="Response submitted, Thank you!"
      />
      <IonText>
        <h4>
          {!noTitle && formTitle}
          <IonText hidden={noTitle} color="secondary">
            .
          </IonText>
        </h4>
      </IonText>
      <IonList hidden={hasSubmittedForm}>
        {feedbackFormQuestions?.map((question) => (
          <div key={question._id}>
            <OurBrainQuestionInput
              feedbackFormQuestion={question}
              formik={formik}
            />
          </div>
        ))}
      </IonList>
      <IonCard hidden={!hasSubmittedForm}>
        <IonCardContent>
          <OurBrainInformation information={{ subtitle: "Thank you" }} center />
        </IonCardContent>
      </IonCard>
      <IonButton
        hidden={showError}
        fill="outline"
        disabled={loading || showSuccess}
        type="submit"
        expand="block"
      >
        {loading ? <IonSpinner /> : "Submit"}
      </IonButton>
      <IonButton
        hidden={noBack}
        type="button"
        fill="clear"
        color="secondary"
        onClick={backAction}
      >
        Back
      </IonButton>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    feedbackForm: state.general.feedbackForm,
    userId: state.auth.user._id, // CHANGE
    showSuccess: state.general.showSuccess,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...generalActions, ...profileActions },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OurBrainForm);
