import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonTextarea,
} from "@ionic/react";
import classNames from "classnames";
import { replace } from "connected-react-router";
import React, { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { OurBrainContent } from "../../../../components";
import NavBar from "../../../../components/NavBar";
import {
  IUserModel,
  IUserProfileModel,
} from "../../../../interfaces/interfaces/user";
import { actions as authActions } from "../../../Auth/redux";
import { IAuthState } from "../../../Auth/redux/reducer";
import { actions as partnershipActions } from "../../../Partnerships/redux";
import { actions as hubActions } from "../../redux";
import { IHubActions } from "../../redux/actions";
import styles from "./index.module.scss";

type CreatePostProps = {
  actions: IHubActions;
  user: IUserModel & IUserProfileModel;
  loading: boolean;
  auth: IAuthState;
};

type CreatePostModalProps = {
  isOpen: boolean;
  type: string | null;
  actions: IHubActions;
  user: IUserModel;
  loading: boolean;
  setOpenModal: any;
};

export const PostSubmitModal: FC<CreatePostModalProps> = (props) => {
  const {
    actions,
    isOpen,
    type,
    user: { avatarUrl, username },
    loading,
    setOpenModal,
  } = props;
  const form = useForm({ defaultValues: { post: "" } });
  const { handleSubmit, control } = form;
  const [checked, setChecked] = useState(true);

  function handleFormSubmit(values: any) {
    const data: any = {
      ...values,
      type,
      user: {
        url: avatarUrl,
        username: username,
      },
      isPublic: checked,
    };

    actions.createPost({ data, type });
  }

  function handleCancel() {
    setOpenModal(null);
  }
  return (
    <IonModal
      isOpen={isOpen}
      cssClass={styles.create__submit__modal}
      onDidDismiss={handleCancel}
    >
      <form
        className={styles.submit__form}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <h3>Share a {type}</h3>
        <Controller
          name="text"
          control={control}
          rules={{ required: true }}
          as={({ onChange, value }) => (
            <IonTextarea
              name="text"
              rows={10}
              placeholder="Type here..."
              debounce={200}
              onIonChange={onChange}
              value={value}
              autocapitalize="sentences"
            />
          )}
        />
        <IonItem />
        <IonItem
          className={styles.create__post__private__cb}
          hidden={type !== "thought"}
        >
          <IonLabel>
            <p>Do you want to share this post with the ourBrain community?</p>
          </IonLabel>
          <IonCheckbox
            checked={checked}
            onIonChange={(e) => setChecked(e.detail.checked)}
          />
        </IonItem>
        <div className={styles.post__buttons}>
          <IonButton disabled={loading} type="submit" expand="block">
            Post
          </IonButton>
          <IonButton
            expand="block"
            fill="clear"
            color="secondary"
            onClick={handleCancel}
          >
            Cancel
          </IonButton>
        </div>
      </form>
    </IonModal>
  );
};

const postOptionsInformation: any[] = [
  {
    title: "Thoughts Box",
    description:
      "A place to voice what's on your mind and how you're feeling. You can choose whether or not to share this to the Hub.",
    type: "thought",
    admin: false,
  },
  {
    title: "Tips & Tricks",
    description:
      "Do you have a helpful life hack or method that helps you with your mental health? You can help other users and share it here.",
    type: "tip",
    admin: false,
  },
  {
    title: "Short Stories",
    description:
      "Talk about a mental health related experience or event in your life for the community to read.",
    type: "story",
    admin: false,
  },
  {
    title: "Announcement",
    description: "Make a announcement to the community",
    type: "announcement",
    admin: true,
  },
];

const CreatePost: FC<CreatePostProps> = (props) => {
  const { actions, user, loading, auth } = props;

  const [openModal, setOpenModal] = useState<string | null>(null);
  function handleOpenModal(type: string) {
    setOpenModal(type);
  }
  return (
    <IonPage>
      <NavBar
        actions={actions}
        title="Hub"
        backButton
        backLink="/app/hub?type=all"
      />

      <OurBrainContent>
        {postOptionsInformation.map(({ title, description, type, admin }) => (
          <IonCard
            hidden={admin && !auth.role!.includes("admin")}
            key={type}
            onClick={() => handleOpenModal(type)}
            className={classNames(styles[`${type}__border`], styles.type__card)}
          >
            <IonCardHeader>
              <h5>{title}</h5>
            </IonCardHeader>
            <IonCardContent>
              <p>{description}</p>
            </IonCardContent>
          </IonCard>
        ))}
        <PostSubmitModal
          actions={actions}
          type={openModal}
          isOpen={openModal !== null}
          user={user}
          loading={loading}
          setOpenModal={setOpenModal}
        />
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    user: state.auth.user,
    loading: state.hub.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...partnershipActions, ...authActions, ...hubActions, replace },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);
