import { IonModal } from "@ionic/react";
import React, { FC } from "react";
import { OurBrainContent } from "../../../../../components";
import OurBrainForm from "../../../../../components/OurBrainForm";

const Feedback: FC<any> = ({ isOpen, actions }) => {
  return (
    <IonModal isOpen={isOpen}>
      <OurBrainContent sizeMd="11">
        <OurBrainForm
          backAction={() => actions.showModal({ modal: "feedback" })}
          modal={"feedback"}
          formKey={process.env.REACT_APP_BRAIN_FORM_FEEDBACK!}
        />
      </OurBrainContent>
    </IonModal>
  );
};

export default Feedback;
