import { ERROR } from "../../../config/constants";
import {
  IPartnershipCheckInModel,
  IPartnershipMessageModel,
  IPartnershipModel,
  IPartnershipUsersInfo,
  IPreferenceModel,
  IQuestionModel,
  IUserPartnershipModel,
} from "../../../interfaces/interfaces";
import { PROCESS_LOGOUT_SUCCESS } from "../../Auth/redux/constants";
import { PROCESS_UPDATE_USER_SUCCESS } from "../../Profile/redux/constants";
import {
  LEAVE_ROOM,
  LOAD_PARTNERSHIP_MESSAGES_FAIL,
  LOAD_PARTNERSHIP_MESSAGES_START,
  LOAD_PARTNERSHIP_MESSAGES_SUCCESS,
  LOAD_USER_PARTNERSHIPS_FAIL,
  LOAD_USER_PARTNERSHIPS_START,
  LOAD_USER_PARTNERSHIPS_SUCCESS,
  PARTNERSHIP_RESET_STATE,
  PROCESS_DELETE_PARTNERSHIP_FAIL,
  PROCESS_DELETE_PARTNERSHIP_START,
  PROCESS_DELETE_PARTNERSHIP_SUCCESS,
  PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_FAIL,
  PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START,
  PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS,
  PROCESS_FIND_ALL_PREFERENCES_FAIL,
  PROCESS_FIND_ALL_PREFERENCES_START,
  PROCESS_FIND_ALL_PREFERENCES_SUCCESS,
  PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_FAIL,
  PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START,
  PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_SUCCESS,
  PROCESS_LOAD_TALKING_POINT_FAIL,
  PROCESS_LOAD_TALKING_POINT_START,
  PROCESS_LOAD_TALKING_POINT_SUCCESS,
  PROCESS_NEW_MESSAGE_START,
  PROCESS_NEW_MESSAGE_SUCCESS,
  PROCESS_REQUEST_NEW_PARTNERSHIP_FAIL,
  PROCESS_REQUEST_NEW_PARTNERSHIP_START,
  PROCESS_REQUEST_NEW_PARTNERSHIP_SUCCESS,
  PROCESS_REQUEST_TALKING_POINT_FAIL,
  PROCESS_REQUEST_TALKING_POINT_START,
  PROCESS_REQUEST_TALKING_POINT_SUCCESS,
  PROCESS_SUBMIT_CHECK_IN_FAIL,
  PROCESS_SUBMIT_CHECK_IN_START,
  PROCESS_SUBMIT_CHECK_IN_SUCCESS,
  REPORT_PARTNERSHIP_FAIL,
  REPORT_PARTNERSHIP_START,
  REPORT_PARTNERSHIP_SUCCESS,
  SET_PARTNERSHIP_STATE,
  SET_SELECTED_MESSAGE,
  UPDATE_PARTNERSHIP_FAIL,
  UPDATE_PARTNERSHIP_START,
  UPDATE_PARTNERSHIP_SUCCESS,
  UPDATE_SELECTED_PARTNERSHIP,
  UPDATE_USER_PREFERENCE_ANSWER,
} from "./constants";

export interface IPartnershipDefaultState {
  loading?: boolean;
  disableScroll?: boolean;
  showAlert: boolean;
  maxTalkingPointsReached?: boolean;
  beganPartnershipRequest: boolean;
  messages: IPartnershipMessageModel[];
  userPartnerships?: IUserPartnershipModel[];
  partnershipId?: IPartnershipModel;
  selectedMessageId?: string;
  messageLimit: number;
  preferences: IPreferenceModel[];
  preferenceQuestions: IQuestionModel[];
  userPreferenceAnswers?: any;
  userPreferenceAnswersTemp?: any;
  partnershipCheckIns?: IPartnershipCheckInModel[];
  foundTalkingPoint?: IQuestionModel;
  selectedPartnership?: IPartnershipModel;
  showError?: string;
  partnershipsToAction: string[];
  activeUsersInfo?: IPartnershipUsersInfo;
  partnerUsersInfo?: IPartnershipUsersInfo;
  showModal: string;
  orQuestionSelected?: string;
  answersReset?: boolean;
  showSuccess?: string;
}

const defaultPartnershipState: IPartnershipDefaultState = {
  messages: [],
  loading: false,
  showAlert: false,
  userPartnerships: [],
  disableScroll: true,
  messageLimit: 30,
  preferences: [],
  preferenceQuestions: [],
  userPreferenceAnswersTemp: {},
  partnershipCheckIns: [],
  partnershipsToAction: [],
  beganPartnershipRequest: false,
  showModal: "",
};

function reducer(state = defaultPartnershipState, action: any) {
  const { payload, type } = action as any;
  switch (type) {
    case ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case LEAVE_ROOM:
      return {
        ...state,
        partnershipId: "",
        selectedMessageId: "",
        messageLimit: 30,
      };
    case PROCESS_LOGOUT_SUCCESS:
      return {
        ...defaultPartnershipState,
      };
    case SET_SELECTED_MESSAGE:
      return {
        ...state,
        selectedMessageId: payload,
      };
    case LOAD_PARTNERSHIP_MESSAGES_START:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PARTNERSHIP_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        disableScroll: state.messages.length === payload.length,
        messageLimit: state.messageLimit + 30,
        messages: payload.reverse(),
      };
    case LOAD_PARTNERSHIP_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case LOAD_USER_PARTNERSHIPS_START:
      return {
        ...state,
        loading: true,
        result: payload,
      };
    case LOAD_USER_PARTNERSHIPS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case LOAD_USER_PARTNERSHIPS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case PROCESS_DELETE_PARTNERSHIP_START:
      return {
        ...state,
        loading: true,
        result: payload,
      };
    case PROCESS_DELETE_PARTNERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROCESS_DELETE_PARTNERSHIP_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case PROCESS_REQUEST_NEW_PARTNERSHIP_START:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_REQUEST_NEW_PARTNERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        userPreferenceAnswersTemp: [],
        beganPartnershipRequest: false,
      };
    case PROCESS_REQUEST_NEW_PARTNERSHIP_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case PROCESS_NEW_MESSAGE_START:
      const messagesLength = state.messages.length;
      return {
        ...state,
        messages: [...state.messages, payload.storedMessage].slice(
          messagesLength - 30,
          messagesLength + 1
        ),
      };
    case PROCESS_NEW_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case PROCESS_UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        partnershipRequested: true,
      };

    // PROCESS_FIND_ALL_PREFERENCES
    case PROCESS_FIND_ALL_PREFERENCES_START:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_FIND_ALL_PREFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        preferences: payload,
      };
    case PROCESS_FIND_ALL_PREFERENCES_FAIL:
      return {
        ...state,
      };

    // PROCESS_FIND_ALL_PREFERENCE_QUESTIONS
    case PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_START:
      return {
        ...state,
        userPreferenceAnswers: {},
        loading: true,
      };
    case PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_SUCCESS:
      return {
        ...state,
        preferenceQuestions: payload,
        loading: false,
      };
    case PROCESS_FIND_ALL_PREFERENCE_QUESTIONS_FAIL:
      return {
        ...state,
      };

    // PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS
    case PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_START:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case PROCESS_FIND_ALL_PARTNERSHIP_CHECK_INS_FAIL:
      return {
        ...state,
      };

    // UPDATE_PARTNERSHIP
    case UPDATE_PARTNERSHIP_START:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PARTNERSHIP_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case UPDATE_PARTNERSHIP_FAIL:
      return {
        ...state,
      };

    // PROCESS_SUBMIT_CHECK_IN
    case PROCESS_SUBMIT_CHECK_IN_START:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_SUBMIT_CHECK_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        partnershipCheckIns: payload,
        showSuccess: "checkInSuccess",
      };
    case PROCESS_SUBMIT_CHECK_IN_FAIL:
      return {
        ...state,
        loading: false,
        partnershipCheckIns: [],
      };

    // PROCESS_LOAD_TALKING_POINT
    case PROCESS_LOAD_TALKING_POINT_START:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_LOAD_TALKING_POINT_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case PROCESS_LOAD_TALKING_POINT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // PROCESS_REQUEST_TALKING_POINT
    case PROCESS_REQUEST_TALKING_POINT_START:
      return {
        ...state,
        loading: true,
      };
    case PROCESS_REQUEST_TALKING_POINT_SUCCESS:
      return {
        ...state,
        ...payload,
        showAlert: true,
        loading: false,
      };
    case PROCESS_REQUEST_TALKING_POINT_FAIL:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    // REPORT_PARTNERSHIP
    case REPORT_PARTNERSHIP_START:
      return {
        ...state,
      };
    case REPORT_PARTNERSHIP_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case REPORT_PARTNERSHIP_FAIL:
      return {
        ...state,
        ...payload,
      };

    //  UPDATE_USER_PREFERENCE_ANSWER
    case UPDATE_USER_PREFERENCE_ANSWER:
      return {
        ...state,
        userPreferenceAnswersTemp: {
          ...state.userPreferenceAnswersTemp,
          ...payload,
        },
      };
    //  PARTNERSHIP_RESET_STATE
    case PARTNERSHIP_RESET_STATE:
      return {
        ...state,
        ...payload,
      };
    //  UPDATE_SELECTED_PARTNERSHIP
    case UPDATE_SELECTED_PARTNERSHIP:
      return {
        ...state,
        selectedPartnership: payload,
      };

    //  UPDATE_SELECTED_PARTNERSHIP
    case SET_PARTNERSHIP_STATE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}

export default reducer;
