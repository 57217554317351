import { IonButton, IonContent, IonModal } from "@ionic/react";
import React, { Dispatch, FC, SetStateAction } from "react";
import styles from "./index.module.scss";

type PartnershipCheckInMessageModalProps = {
  openAnswerModal: any;
  setOpenAnswerModal: Dispatch<SetStateAction<any>>;
};

const PartnershipCheckInMessageModal: FC<PartnershipCheckInMessageModalProps> = (
  props
) => {
  const { setOpenAnswerModal, openAnswerModal } = props;

  return (
    <IonModal
      isOpen={openAnswerModal.isOpen}
      onDidDismiss={() => setOpenAnswerModal({ isOpen: false })}
    >
      <IonContent className={styles.partnershipCheckInMessageModalContainer}>
        <div className={styles.partnershipCheckInMessageModalContainer}>
          <pre>{openAnswerModal.answer}</pre>
          <IonButton onClick={() => setOpenAnswerModal({ isOpen: false })}>
            Close
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default PartnershipCheckInMessageModal;
