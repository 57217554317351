import { IAction } from "../../../config/common";
import {
  ADD_PARTNERSHIP_CHECK_IN_TO_LIST,
  ADMIN_RESET_STATE,
  CREATE_PARTNERSHIP_START,
  GET_PARTNERSHIP_START,
  GET_USER_EMAIL_START,
  LOAD_ALL_USERS_START,
  LOAD_ALL_USER_PREFERENCES_START,
  LOAD_PARTNERSHIPS_START,
  LOAD_USER_PREFERENCE_ANSWERS_START,
  MODERATE_CHECK_INS_START,
  MODERATE_POSTS_START,
  PROCESS_SELECT_USER,
  SET_ADMIN_STATE,
  SHOW_ADMIN_MODAL,
  UPDATE_PARTNERSHIP_CHECK_IN_START,
} from "./constants";

const processLoadAllUsers: any = (payload: string): IAction => {
  return { type: LOAD_ALL_USERS_START, payload };
};
const processCreatePartnership: any = (payload: string): IAction => {
  return { type: CREATE_PARTNERSHIP_START, payload };
};
const processGetPartnership: any = (payload: string): IAction => {
  return { type: GET_PARTNERSHIP_START, payload };
};
const processSelectUser: any = (payload: string): IAction => {
  return { type: PROCESS_SELECT_USER, payload };
};
export const showAdminModal: any = (payload: string): IAction => {
  return { type: SHOW_ADMIN_MODAL, payload };
};
const processLoadAllUserPreferences: any = (payload: string): IAction => {
  return { type: LOAD_ALL_USER_PREFERENCES_START, payload };
};
export const processLoadUserPreferenceAnswersStart: any = (
  payload: string
): IAction => {
  return { type: LOAD_USER_PREFERENCE_ANSWERS_START, payload };
};

export const addPartnershipCheckInToList: any = (payload: string): IAction => {
  return { type: ADD_PARTNERSHIP_CHECK_IN_TO_LIST, payload };
};

export const processModerateCheckInsStart: any = (payload: string): IAction => {
  return { type: MODERATE_CHECK_INS_START, payload };
};
export const resetState: any = (payload: string): IAction => {
  return { type: ADMIN_RESET_STATE, payload };
};
export const getAllPartnershipsStart: any = (payload: string): IAction => {
  return { type: LOAD_PARTNERSHIPS_START, payload };
};

export const getUserEmailStart: any = (payload: string): IAction => {
  return { type: GET_USER_EMAIL_START, payload };
};

export const updatePartnershipCheckInStart: any = (
  payload: string
): IAction => {
  return { type: UPDATE_PARTNERSHIP_CHECK_IN_START, payload };
};

export const setAdminState: any = (payload: string): IAction => {
  return { type: SET_ADMIN_STATE, payload };
};

const moderatePosts: any = (payload: string): IAction => {
  return { type: MODERATE_POSTS_START, payload };
};

export interface IAdminActions {
  processLoadAllUsers: Function;
  processCreatePartnership: Function;
  processSelectUser: Function;
  showAdminModal: Function;
  processGetPartnership: Function;
  processLoadAllUserPreferences: Function;
  processLoadPreferenceStart: Function;
  processLoadUserPreferenceAnswersStart: Function;
  addPartnershipCheckInToList: Function;
  processModerateCheckInsStart: Function;
  resetState: Function;
  getAllPartnershipsStart: Function;
  getUserEmailStart: Function;
  updatePartnershipCheckInStart: Function;
  setAdminState: Function;
  moderatePosts: Function;
}

export default {
  processLoadAllUsers,
  processCreatePartnership,
  processSelectUser,
  showAdminModal,
  processGetPartnership,
  processLoadAllUserPreferences,
  processLoadUserPreferenceAnswersStart,
  addPartnershipCheckInToList,
  processModerateCheckInsStart,
  resetState,
  getAllPartnershipsStart,
  getUserEmailStart,
  updatePartnershipCheckInStart,
  setAdminState,
  moderatePosts,
};
