import { IonButton, IonPage, IonSpinner } from "@ionic/react";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import {
  Divider,
  Notification,
  OurBrainButton,
  OurBrainContent,
  OurBrainHeader,
} from "../../../../components";
import StylishInput from "../../../../components/StylishInput";
import { ILoginData } from "../../../../interfaces/User";
import { actions as loginActions } from "../../redux/";
import "./index.module.scss";
import styles from "./index.module.scss";

type LoginProps = {
  handleLogin: Function;
  actions: any;
  loading: boolean;
  auth: any;
};
let initialValues = {
  password: "",
  email: "",
};
const Login: FC<LoginProps> = ({ actions, loading, auth }) => {
  const { handleSubmit, errors, control } = useForm<ILoginData>({
    defaultValues: { ...initialValues },
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });
  const handleLogin = async (data: ILoginData) => {
    const { loginFailed, loginData } = auth;
    if (
      loginFailed &&
      loginData?.email === data.email &&
      loginData?.password === data.password
    ) {
      return;
    } else {
      actions.processLoginStart({ loginData: data });
    }
  };

  return (
    <IonPage className={styles.login__page}>
      <OurBrainHeader />
      <Notification
        isOpen={auth.showSuccess}
        position="top"
        color="success"
        message="Password successfully reset"
      />
      <Notification
        isOpen={auth.showError}
        position="top"
        color="danger"
        message="Password reset was unsuccessful"
      />
      <OurBrainContent>
        <form
          onSubmit={handleSubmit(handleLogin)}
          className={styles.login__form__container}
        >
          <div className={styles.login__form__title}>
            <h3>Welcome back</h3>
          </div>
          <div className={styles.login__form_fields}>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              as={({ onChange, value }) => (
                <StylishInput
                  name="email"
                  type="email"
                  label="Email"
                  value={value}
                  onChange={onChange}
                  errors={errors}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              as={({ onChange, value }) => (
                <StylishInput
                  name="password"
                  type="password"
                  label="Password"
                  value={value}
                  onChange={onChange}
                  errors={errors}
                />
              )}
            />
          </div>
          <div className={styles.login__form__buttons}>
            <IonButton
              shape="round"
              expand="full"
              size="large"
              type="submit"
              disabled={loading}
              color={auth.loginFailed ? "danger" : "primary"}
              className="ion-margin-top"
            >
              {loading ? <IonSpinner name="crescent" /> : "Log in"}
            </IonButton>
            <small>
              Forgot your password? <Link to="/reset">Click here</Link>
            </small>
            <Divider />
            <OurBrainButton expand="block" color="secondary" link="/sign-up">
              Sign up
            </OurBrainButton>
          </div>
        </form>
      </OurBrainContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    user: state.auth.user, // CHANGE
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators({ ...loginActions }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
