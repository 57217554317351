import {
  IonDatetime,
  IonItem,
  IonLabel,
  IonList,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import moment from "moment";
import React, { FC } from "react";
import { Controller } from "react-hook-form";
import OurBrainInformation from "../../../../../components/OurBrainInformation";
import cities from "../cities.json";

const PersonalInformation: FC<any> = ({ control, hidden }) => {
  return (
    <div hidden={hidden}>
      <IonList>
        <OurBrainInformation information={{ title: "Personal information" }} />
        <Controller
          name="demographic.gender"
          control={control}
          as={({ onChange, value }: any) => (
            <IonItem>
              <IonLabel position="stacked">Gender</IonLabel>
              <IonSelect
                onIonChange={onChange}
                value={value}
                placeholder="Click to select..."
                interface="action-sheet"
              >
                <IonSelectOption value="female">Female</IonSelectOption>
                <IonSelectOption value="male">Male</IonSelectOption>
                <IonSelectOption value="other">Other</IonSelectOption>
                <IonSelectOption value="abstain">
                  Rather not say
                </IonSelectOption>
              </IonSelect>
            </IonItem>
          )}
        />
        <Controller
          name="demographic.dateOfBirth"
          control={control}
          as={({ onChange, value }: any) => (
            <IonItem>
              <IonLabel position="stacked">Month of birth</IonLabel>
              <IonText color="secondary">
                <small>(You must be at least 13 to use ourBrain)</small>
              </IonText>
              <IonDatetime
                displayFormat="MMM YYYY"
                placeholder="Click to select..."
                defaultValue={moment().subtract(1, "year").toISOString()}
                value={value}
                onIonChange={onChange}
                max={moment()
                  .subtract(13, "years")
                  .subtract(1, "month")
                  .toISOString()}
              />
            </IonItem>
          )}
        />
        <Controller
          name="demographic.city"
          control={control}
          as={({ onChange, value }: any) => (
            <IonItem>
              <IonLabel position="stacked">
                What is your nearest major city?
              </IonLabel>
              <IonText color="secondary">
                <small>(You must be living in the U.K.)</small>
              </IonText>
              <IonSelect
                onIonChange={onChange}
                placeholder="Click to select..."
                value={value}
                interface="action-sheet"
              >
                {cities.sort().map((city: string) => (
                  <IonSelectOption key={city} value={city}>
                    {city}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          )}
        />
      </IonList>
    </div>
  );
};

export default PersonalInformation;
