import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonRow,
  IonSelect,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
} from "ionicons/icons";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import "swiper/swiper.scss";
import * as yup from "yup";
import { IUserModel } from "../../../../interfaces/interfaces";
import { actions as partnershipActions } from "../../../Partnerships/redux";
import { actions as profileActions } from "../../../Profile/redux";
import { IProfileActions } from "../../../Profile/redux/actions";
import { IProfileState } from "../../../Profile/redux/reducer";
import { actions as authActions } from "../../redux";
import { IAuthActions } from "../../redux/actions";
import { IAuthState } from "../../redux/reducer";
import "./index.module.scss";
import styles from "./index.module.scss";
import Anonymity from "./Slides/Anonymity";
import Disclaimer from "./Slides/Disclaimer";
import Goal from "./Slides/Goal";
import OurBrain from "./Slides/OurBrain";
import Overview from "./Slides/Overview";
import PersonalInformation from "./Slides/PersonalInformation";
import YourDisplay from "./Slides/YourDisplay";

type WelcomeProps = {
  actions: IAuthActions & IProfileActions;
  auth: IAuthState;
  activeUser: IUserModel;
  welcomeForm: any;
  profile: IProfileState;
  loading: boolean;
};
const validationSchema = yup.object().shape({
  demographic: yup.object().shape({
    city: yup.string().required("Please select your closest city."),
    gender: yup.string().required("Please specify your gender."),
    dateOfBirth: yup.string().required("Birth year and month is required."),
  }),
  config: yup.object().shape({
    anonymityLevel: yup.number().required("Please specify an anonymity level"),
    completedSignUp: yup.boolean().equals([true]),
  }),
  avatarUrl: yup.string().required("Please select an avatar."),
  username: yup.string().required("Please select a username."),
  acceptRules: yup.boolean().required().equals([true]),
});

const Welcome: FC<WelcomeProps> = ({
  actions,
  activeUser,
  auth,
  loading,
  profile,
}) => {
  const { push } = useHistory();
  const contentRef: any = useRef();
  const [validationError] = useState();
  useEffect(() => {
    if (activeUser?.config?.completedSignUp) {
      push("/app");
    }
  }, []);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const scrollToTop: any = useCallback(
    async () => await contentRef.current.scrollToTop(0),
    []
  );
  useEffect(() => {
    scrollToTop();
  }, [currentSlideIndex, scrollToTop]);
  const form = useForm({
    mode: "onSubmit",
    defaultValues: {
      _id: activeUser._id,
      demographic: {
        city: undefined,
        gender: undefined,
        dateOfBirth: undefined,
      },
      config: {
        anonymityLevel: 1,
        completedSignUp: true,
      },
      avatarUrl: activeUser.avatarUrl,
    },
    validationSchema,
  });

  const { handleSubmit, control, errors } = form;
  const onSubmit = async (values: any) => {
    values._id = activeUser._id;
    values.config.completedSignUp = true;
    actions.processUpdateUserStart({ userDetails: values, push });
  };

  IonText.defaultProps = {
    className: "ion-text-left",
  };
  IonInput.defaultProps = {
    className: "ion-text-left",
  };
  IonDatetime.defaultProps = {
    className: "ion-text-left",
  };
  IonSelect.defaultProps = {
    className: "ion-text-left",
  };

  const props = {
    actions,
    errors,
    control,
    activeUser,
    form,
    loading,
    profileLoading: profile.loading,
    styles,
    validationError,
    auth,
    key: 0,
    setCurrentSlideIndex,
    currentSlideIndex,
  };
  const slides = [
    { component: OurBrain, slideIndex: 0 },
    { component: Goal, slideIndex: 1 },
    { component: Overview, slideIndex: 2 },
    { component: PersonalInformation, slideIndex: 3 },
    { component: YourDisplay, slideIndex: 4 },
    { component: Anonymity, slideIndex: 5 },
    { component: Disclaimer, slideIndex: 6 },
  ];

  const renderSlides = useCallback((): any => {
    return slides.map(({ component, slideIndex }, index) => {
      props.key = index;
      if (currentSlideIndex === slideIndex) {
        return React.createElement(component, { ...props, hidden: false });
      }
      return React.createElement(component, { ...props, hidden: true });
    });
  }, [slides, props, currentSlideIndex]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Hello {activeUser.firstName}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={styles.welcomeContainer} ref={contentRef}>
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeMd="5" className="ion-justify-content-center">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.elementSpacing}>
                  {renderSlides()}
                  <div className={styles.footer}>
                    <IonButton
                      color="secondary"
                      size="default"
                      onClick={() =>
                        setCurrentSlideIndex(
                          currentSlideIndex > 0 ? currentSlideIndex - 1 : 0
                        )
                      }
                      disabled={currentSlideIndex === 0}
                    >
                      <IonIcon icon={arrowBackCircleOutline} size="large" />
                    </IonButton>
                    <IonButton
                      color="primary"
                      size="default"
                      onClick={() =>
                        setCurrentSlideIndex(
                          currentSlideIndex !== 6 ? currentSlideIndex + 1 : 6
                        )
                      }
                      disabled={currentSlideIndex === 6}
                    >
                      <IonIcon icon={arrowForwardCircleOutline} size="large" />
                    </IonButton>
                  </div>
                </div>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    activeUser: state.auth.user,
    loading: state.auth.loading,
    welcomeForm: state.auth.welcomeForm,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      { ...authActions, ...partnershipActions, ...profileActions },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
