import React, { FC } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

const OurBrainContainer: FC<any> = ({ children }) => {
  return (
    <IonGrid>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeMd="5" className="ion-justify-content-center">
          {children}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default OurBrainContainer;
